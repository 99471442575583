import * as React from 'react';
import { StepLabelProps } from '@material-ui/core/StepLabel';
import { Theme } from '@material-ui/core/styles/createTheme';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { StepIcon } from '../StepIcon';
import { Typography } from '../../Typography';
import { Link } from '../../Link';

type EditedStepLabelProps = Omit<StepLabelProps, 'children'>;
export interface IStepLabelProps extends EditedStepLabelProps {
  activeLabel?: string;
  completedLabel?: string;
  disabled: boolean;
  errorLabel?: string;
  iconId: string;
  inCompleteLabel?: string;
  onClick: (event?: React.MouseEvent<HTMLElement>) => void;
  stepDescription: string;
  stepNumber: string;
  active?: boolean;
  completed?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      lineHeight: 0,
    },
    icon: {
      marginRight: '8px',
    },
    button: {
      lineHeight: 0,
      '&[disabled]': {
        color: theme.palette.primary.main,
      },
      '$error &[disabled]': {
        color: theme.palette.error.main,
      },
    },
    stepNumber: {
      fontWeight: theme.typography.fontWeightBold,
    },
    error: {},
  }),
);

export const StepLabel = React.forwardRef(
  (props: IStepLabelProps, ref: React.Ref<HTMLDivElement>) => {
    const {
      active,
      activeLabel,
      completed,
      completedLabel,
      disabled,
      error,
      errorLabel,
      iconId,
      inCompleteLabel,
      onClick,
      stepDescription,
      stepNumber,
    } = props;
    const ariaCurrent = active ? 'step' : undefined;
    const ariaExpanded = !!active;
    const classes = useStyles(props);

    return (
      <div
        ref={ref}
        className={classnames(classes.root, { [classes.error]: error })}
        data-testid="step-label"
      >
        <StepIcon
          classes={{ root: classes.icon }}
          active={active}
          activeLabel={activeLabel}
          completed={completed}
          completedLabel={completedLabel}
          error={error}
          errorLabel={errorLabel}
          iconId={iconId}
          incompleteLabel={inCompleteLabel}
        />
        <Link
          classes={{ root: classes.button }}
          component="button"
          color={error ? 'error' : 'secondary'}
          disabled={disabled}
          onClick={onClick}
          aria-current={ariaCurrent}
          aria-expanded={ariaExpanded}
          aria-describedby={iconId}
        >
          <Typography component="span" variant="body2" className={classes.stepNumber}>
            {stepNumber}
          </Typography>
          <Typography component="span" variant="body2">
            {stepDescription}
          </Typography>
        </Link>
      </div>
    );
  },
);

export default StepLabel;
