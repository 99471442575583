import * as React from 'react';
import { FeatureFlagDefinitionData } from "hooks/feature-flag-definition/use-feature-flag-definitions.types";
import { getBundle, ILocaleProps } from "../../lib/locales";
import { FeatureFlagInformationItem } from './feature-flag-definition-detail-item';

export interface IFeatureDetailViewProps extends ILocaleProps {
  featureFlagData: FeatureFlagDefinitionData;
}

export const FeatureFlagDefinitionDetailPanel: React.FC<IFeatureDetailViewProps> = (props) => {
  const { featureFlagData, locale } = props;
  const bundle = getBundle(locale);

  return (
    <>
      <FeatureFlagInformationItem
        testId="flag-key"
        title={bundle.flagKey}
        value={featureFlagData.flagKey}
      />
      <FeatureFlagInformationItem
        testId="products"
        title={bundle.products}
        value={`[ ${featureFlagData.products} ]`}
      />
      <FeatureFlagInformationItem
        testId="scope-types"
        title={bundle.scopeTypes}
        value={`[ ${featureFlagData.scopeTypes.join(', ')} ]`}
        tooltip={true}
        tooltipTitle={bundle.flagDefinitionInfoScopeDescription}
      />
      <FeatureFlagInformationItem
        testId="internal-description"
        title={bundle.internalDescription}
        value={featureFlagData.internalDescription}
      />
      <FeatureFlagInformationItem
        testId="client-description"
        title={bundle.clientDescription}
        value={featureFlagData.clientDescription?.find((element: any) => element.locale.toLowerCase() === "en_us")?.label}
      />
      <FeatureFlagInformationItem
        testId="internal-doc-url"
        title={bundle.internalDocUrl}
        value={featureFlagData.internalUrl}
      />
      <FeatureFlagInformationItem
        testId="external-doc-url"
        title={bundle.externalDocUrl}
        value={featureFlagData.documentationUrl}
      />
    </>
  );
};

export default FeatureFlagDefinitionDetailPanel;
