import * as React from 'react';
import { createSvgIcon, IconDirectionality } from '..';

export const Search = createSvgIcon(
  {
    [IconDirectionality.All]: (
      <>
        <path
          fill="currentColor"
          stroke="transparent"
          d="M11 6.5C11 8.9853 8.9853 11 6.5 11v2c3.5899 0 6.5-2.9101 6.5-6.5h-2zM6.5 11C4.0147 11 2 8.9853 2 6.5H0C0 10.0899 2.9101 13 6.5 13v-2zM2 6.5C2 4.0147 4.0147 2 6.5 2V0C2.9101 0 0 2.9101 0 6.5h2zM6.5 2C8.9853 2 11 4.0147 11 6.5h2C13 2.9101 10.0899 0 6.5 0v2z"
        />
        <path
          fill="currentColor"
          stroke="transparent"
          d="M11.2071 9.7929c-.3905-.3905-1.0237-.3905-1.4142 0-.3905.3905-.3905 1.0237 0 1.4142l1.4142-1.4142zm3.0858 5.9142c.3905.3905 1.0237.3905 1.4142 0 .3905-.3905.3905-1.0237 0-1.4142l-1.4142 1.4142zm-4.5-4.5l4.5 4.5 1.4142-1.4142-4.5-4.5-1.4142 1.4142z"
        />
      </>
    ),
  },
  'small',
);
