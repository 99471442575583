import * as React from 'react';
import { MenuItem as BbUiMenuItem } from '../../MenuItem';
import { useStyles } from './Listbox.Option.styles';
import { ListboxOptionProps } from './Listbox.Option.types';

export const ListboxOption = React.forwardRef<HTMLLIElement, ListboxOptionProps>((props, ref) => {
  const { children, selected, isMulti, ...other } = props;
  const classes = useStyles(props);

  return (
    <BbUiMenuItem
      ref={ref}
      button
      role="option"
      classes={{
        root: classes.menuItem,
        selected: isMulti ? classes.multiSelected : classes.selected,
      }}
      aria-selected={selected}
      selected={selected}
      {...other}
    >
      {children}
    </BbUiMenuItem>
  );
});

export default ListboxOption;
