import * as React from 'react';
import { Button } from '../Button';
import { IButtonProps } from '../Button.types';

export const OutlineButton = React.forwardRef(
  (props: IButtonProps, ref: React.Ref<HTMLButtonElement>) => (
    <Button variant="outlined" {...props} ref={ref} />
  ),
);

export default OutlineButton;
