import * as React from 'react';
import { ComboboxProps } from './Combobox.types';
import {
  CreatableOption,
  CreatableComboboxProps,
  CreatableOnChangeData,
  CreatableGroup,
  CreatableOptionsOrGroups,
  CreatableComboboxMultiProps,
  CreatableComboboxSingleProps,
} from './CreatableCombobox.types';

function validateNewOptionDefault<
  Option extends CreatableOption,
  Group extends CreatableGroup<Option>,
>(
  searchValue: string,
  newOption: Option,
  value?: Option[] | null,
  options?: CreatableOptionsOrGroups<Option, Group>,
) {
  if (!searchValue) {
    return null;
  }

  function hasOption({ label, value }: Option) {
    return label === newOption.label || value === newOption.value;
  }

  // Verify option doesn't exist in selected options
  if (value?.some(hasOption)) {
    return null;
  }

  if (
    options?.some((option) =>
      'options' in option ? option.options.some(hasOption) : hasOption(option),
    )
  ) {
    return null;
  }

  return newOption;
}

function createOption<Option extends CreatableOption>(searchValue: string): Option {
  return {
    value: `created-${searchValue}`,
    label: searchValue,
    __isNew__: true,
  } as Option;
}

export function useCreatable<Option extends CreatableOption, Group extends CreatableGroup<Option>>(
  props: CreatableComboboxProps<Option, Group>,
): ComboboxProps<Option, Group> {
  const {
    strings,
    options: optionsProp = [],
    onSearch,
    onChange,
    onCreateOption,
    validateNewOption = validateNewOptionDefault,
    value: controlledValue,
    defaultValue,
    ...comboboxProps
  } = props;
  const { isMulti } = comboboxProps as CreatableComboboxMultiProps<Option>;
  const { createOptionLabel, ...comboboxStrings } = strings;
  const initialValue = controlledValue ?? defaultValue ?? [];
  const formatValue = React.useCallback(
    (value: Option | Option[]) => (Array.isArray(value) ? value : [value]),
    [],
  );
  const [selectedOptions, setSelectedOptions] = React.useState<Option[] | undefined>(
    formatValue(initialValue),
  );
  const [searchValue, setSearchValue] = React.useState('');

  const newOption = React.useMemo(
    () =>
      validateNewOption(
        searchValue,
        createOption(searchValue),
        selectedOptions,
        optionsProp as CreatableOptionsOrGroups<any, any>,
      ),
    [searchValue, selectedOptions, optionsProp, validateNewOption],
  );

  const createdOptions = React.useMemo(
    () => selectedOptions?.filter(({ __isNew__ }) => __isNew__) ?? [],
    [selectedOptions],
  );

  const options = React.useMemo(
    () =>
      newOption
        ? [...createdOptions, ...optionsProp, newOption]
        : [...createdOptions, ...optionsProp],
    [newOption, optionsProp, createdOptions],
  );

  const handleOnSearch = React.useCallback(
    (searchString: string) => {
      setSearchValue(searchString);

      if (onSearch) {
        onSearch(searchString);
      }
    },
    [onSearch],
  );

  const handleOnChange = React.useCallback(
    (value: Option | Option[], data: CreatableOnChangeData<Option>) => {
      const isCreatedOption = data.option && '__isNew__' in data.option;
      const valueArray = formatValue(value);

      if (onCreateOption && isCreatedOption) {
        onCreateOption(data.option!.label);
      } else {
        setSelectedOptions(valueArray);

        if (onChange) {
          const action =
            data.action === 'select-option' && isCreatedOption ? 'create-value' : data.action;

          if (isMulti) {
            const onChangeMulti =
              onChange as unknown as CreatableComboboxMultiProps<Option>['onChange'];

            onChangeMulti!(valueArray, { ...data, action });
          } else {
            const onChangeSingle =
              onChange as unknown as CreatableComboboxSingleProps<Option>['onChange'];

            onChangeSingle!(valueArray[0], { ...data, action });
          }
        }
      }
    },
    [onChange, onCreateOption, isMulti, formatValue],
  );

  return {
    options,
    strings: comboboxStrings,
    onChange: handleOnChange,
    onSearch: handleOnSearch,
    value: selectedOptions,
    defaultValue,
    ...comboboxProps,
  };
}

export default useCreatable;
