import * as React from 'react';
import classnames from 'classnames';
import { createStyles } from '@material-ui/core/styles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import { default as MuiPaper, PaperProps as MuiPaperProps } from '@material-ui/core/Paper';
import { getColor, rgba } from '../styles';

const styles = (theme: Theme) => {
  const dropdownBorderColor =
    theme.palette.type === 'light'
      ? getColor('border.main', theme)
      : getColor('background.b2', theme);

  return createStyles({
    dropdown: {
      border: `1px solid ${dropdownBorderColor}`,
      boxShadow: `${rgba(getColor('background.b9', theme), 0.075)} 0px 0px 0px 4px`,
    },
  });
};

export interface PaperProps extends Omit<MuiPaperProps, 'variant'> {
  variant?: 'elevation' | 'outlined' | 'dropdown';
}

export const Paper = React.forwardRef(
  (props: PaperProps & WithStyles<typeof styles>, ref: React.Ref<unknown>) => {
    const { classes, className: classNameProp, variant, elevation, ...other } = props;
    const className = classnames(
      {
        [classes.dropdown]: variant === 'dropdown',
      },
      classNameProp,
    );

    return <MuiPaper className={className} elevation={elevation || 0} ref={ref} {...other} />;
  },
);

export default withStyles(styles)(Paper);
