import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createTheme';
import { rgba, getColor, focusOutline } from '../styles';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      // TODO: revisit after next release - https://github.com/JedWatson/react-select/labels/3.x%20release
      // hide provided aria-live region until localized strings can be passed in
      '& > [aria-live]': {
        display: 'none',
      },
    },
    input: {
      display: 'flex',
      height: 'auto',
      padding: '0 0 0 10px',
      overflow: 'hidden',
    },
    valueContainer: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '2px 0',
      overflow: 'hidden',
    },
    valueContainerMulti: {
      flexWrap: 'wrap',
    },
    menuItemSelected: {
      backgroundColor: rgba(getColor('primary.main', theme), 1.0),
      color: rgba(getColor('primary.contrastText', theme), 1.0),
    },
    menuItemFocus: {
      backgroundColor: rgba(getColor('primary.main', theme), 0.06),
      '$focusVisible &': {
        ...focusOutline(theme, { position: 2 }),
      },
    },
    menuItemHighlight: {
      fontWeight: theme.typography.fontWeightBold,
    },
    chip: {
      position: 'relative',
      margin: '2px 4px 2px 0',
    },
    chipFocused: {
      ...focusOutline(theme),
    },
    noOptionsMessage: {
      padding: '8px 12px',
    },
    singleValue: {
      fontSize: theme.typography.fontSizeDefault,
    },
    placeholder: {
      position: 'absolute',
      left: '12px',
      fontSize: theme.typography.fontSizeDefault,
      color: getColor('text.secondary', 'light'),
      '&$floatingLabel': {
        opacity: 0,
      },
      '&$inputFocused': {
        opacity: 1,
      },
    },
    paper: {
      zIndex: 10,
      margin: '4px 0',
      width: '100%',
    },
    divider: {
      height: '16px',
    },
    dropdownIndicator: {
      margin: '0 5px',
    },
    indicatorContainer: {
      alignItems: 'center',
      alignSelf: 'stretch',
      display: 'flex',
      flexShrink: 0,
      cursor: 'pointer',
    },
    indicatorSeparator: {
      alignSelf: 'stretch',
      backgroundColor: getColor('border.main', theme),
      margin: '8px 0',
      width: '1px',
    },
    clearIndicator: {
      alignSelf: 'flex-end',
      // inset focus outline
      '&::before': {
        top: 2,
        left: 2,
        right: 2,
        bottom: 2,
      },
    },
    floatingLabel: {},
    inputFocused: {},
    focusVisible: {},
  });
