import * as React from 'react';
import { createSvgIcon, IconDirectionality } from '..';

export const Add = createSvgIcon(
  {
    [IconDirectionality.LeftToRight]: (
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1 8c0-3.866 3.134-7 7-7v0c3.866 0 7 3.134 7 7v0c0 3.866-3.134 7-7 7v0c-3.866 0-7-3.134-7-7v0zM11 8H5M8 5v6"
      />
    ),
  },
  'small',
);
