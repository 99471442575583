import * as React from 'react';
import classnames from 'classnames';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { default as MuiCardContent, CardContentProps } from '@material-ui/core/CardContent';

export interface ICardContentProps extends CardContentProps {
  collapse?: 'top' | 'bottom' | 'vertical' | 'left' | 'right' | 'horizontal' | 'all';
}

export const useStyles = makeStyles(() =>
  createStyles({
    collapseTop: {
      paddingTop: 0,
    },
    collapseBottom: {
      paddingBottom: 0,
    },
    collapseVertical: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    collapseLeft: {
      paddingLeft: 0,
    },
    collapseRight: {
      paddingRight: 0,
    },
    collapseHorizontal: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    collapseAll: {
      padding: 0,
    },
  }),
);

export const CardContent = React.forwardRef(
  (props: ICardContentProps, ref: React.Ref<HTMLDivElement>) => {
    const { className, collapse, ...other } = props;
    const classes = useStyles(props);

    return (
      <MuiCardContent
        ref={ref}
        className={classnames(className, {
          [classes.collapseTop]: collapse === 'top',
          [classes.collapseBottom]: collapse === 'bottom',
          [classes.collapseVertical]: collapse === 'vertical',
          [classes.collapseLeft]: collapse === 'left',
          [classes.collapseRight]: collapse === 'right',
          [classes.collapseHorizontal]: collapse === 'horizontal',
          [classes.collapseAll]: collapse === 'all',
        })}
        {...other}
      />
    );
  },
);

export default CardContent;
