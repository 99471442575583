import { JobServiceException } from "../JobServiceException";

export class JobNotFoundException extends JobServiceException {
  public constructor(jobId: string, additionalData?: object) {
    super(404, "JobNotFoundException", `Job with id ${jobId} not found!`, {
      ...additionalData,
      jobId,
    });
  }
}
