import React, {
  PropsWithChildren,
  ForwardRefExoticComponent,
  RefAttributes,
  KeyboardEvent,
} from 'react';
import { Key } from 'ts-key-enum';
import { useTheme } from '@material-ui/core/styles';
import { useForkRef } from '@material-ui/core/utils';

import { Table } from '../Table';
import { OffScreenAnnouncement } from '../OffScreenAnnouncement';
import { SortableTableContext, SortableTableProps, SortDirection } from './SortableTable.types';

const useSingleColumnSort = (
  props: Pick<
    SortableTableProps,
    'disableUnsortedState' | 'initialSortColumnId' | 'initialSortDirection' | 'onSortChanged'
  >,
) => {
  const { disableUnsortedState, initialSortColumnId, initialSortDirection, onSortChanged } = props;

  const [sortColumn, setSortColumn] = React.useState(initialSortColumnId);
  const [sortDirection, setSortDirection] = React.useState<SortDirection | undefined>(
    initialSortDirection,
  );

  const onColumnClicked = React.useCallback(
    (columnClicked: string) => {
      let newSortColumn: string | undefined = columnClicked;
      let newSortDirection: SortDirection | undefined;
      if (newSortColumn === sortColumn && !disableUnsortedState) {
        if (sortDirection === 'asc') {
          newSortDirection = 'desc';
        } else if (sortDirection === 'desc') {
          newSortDirection = undefined;
        } else {
          newSortDirection = 'asc';
        }
      } else if (newSortColumn === sortColumn && disableUnsortedState) {
        newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        newSortDirection = 'asc';
      }

      if (newSortDirection === undefined) {
        newSortColumn = undefined;
      }

      const sortChangeResult =
        onSortChanged &&
        onSortChanged({
          sortDirection: newSortDirection,
          sortColumnId: newSortColumn,
          columnIdClicked: columnClicked,
        });
      if (sortChangeResult === false) {
        return;
      }

      if (sortChangeResult && sortChangeResult !== true) {
        newSortColumn = sortChangeResult.columnId;
        newSortDirection = sortChangeResult.sortDirection;
      }

      setSortColumn(newSortColumn);
      setSortDirection(newSortDirection);
    },
    [disableUnsortedState, onSortChanged, sortColumn, sortDirection],
  );
  return { sortColumn, sortDirection, onColumnClicked };
};

const useGridKeyboardNavigation = () => {
  const { direction } = useTheme();
  const ref: React.RefObject<HTMLTableElement> = React.useRef(null);
  const onKeyDown = React.useCallback(
    (event: KeyboardEvent<HTMLTableElement>) => {
      const table = ref.current;
      const currentElement =
        table && table.ownerDocument ? table.ownerDocument.activeElement : document.activeElement;
      if (!table || !currentElement) {
        return;
      }

      const currentCell = currentElement.closest('td') || currentElement.closest('th');
      if (!currentCell) {
        return;
      }

      const currentColumnIdx = currentCell.cellIndex;
      const currentRow = currentCell.closest('tr');
      if (!currentRow) {
        return;
      }
      const currentRowIdx = currentRow.rowIndex;

      const navigateToCell = (nextRowIdx: number, nextColIdx: number) => {
        const nextRow = table.rows[nextRowIdx];
        if (!nextRow) {
          return;
        }

        const nextCell = nextRow.cells[nextColIdx];
        if (!nextCell) {
          return;
        }

        // If the cell contains a focusable element, focus the first focusable element within it. Otherwise, focus the cell (and hope it has tabindex set properly)
        const focusableElement = nextCell.querySelector<HTMLElement>(
          'button, [href], input, select, [tabindex]:not([tabindex="-1"])',
        );
        if (focusableElement) {
          focusableElement.focus();
        } else {
          nextCell.focus();
        }
      };

      switch (event.key) {
        case Key.ArrowUp:
          event.preventDefault();
          return navigateToCell(currentRowIdx - 1, currentColumnIdx);
        case Key.ArrowDown:
          event.preventDefault();
          return navigateToCell(currentRowIdx + 1, currentColumnIdx);
        case Key.ArrowLeft:
          event.preventDefault();
          return navigateToCell(
            currentRowIdx,
            direction === 'ltr' ? currentColumnIdx - 1 : currentColumnIdx + 1,
          );
        case Key.ArrowRight:
          event.preventDefault();
          return navigateToCell(
            currentRowIdx,
            direction === 'ltr' ? currentColumnIdx + 1 : currentColumnIdx - 1,
          );
        case Key.Home:
          event.preventDefault();
          if (event.ctrlKey) {
            return navigateToCell(0, 0);
          }
          return navigateToCell(currentRowIdx, 0);
        case Key.End:
          event.preventDefault();
          if (event.ctrlKey) {
            return navigateToCell(
              table.rows.length - 1,
              table.rows[table.rows.length - 1].cells.length - 1,
            );
          }
          return navigateToCell(currentRowIdx, currentRow.cells.length - 1);
      }
    },
    [direction],
  );

  return {
    ref,
    onKeyDown,
  };
};

export const SortableTable: ForwardRefExoticComponent<
  PropsWithChildren<SortableTableProps> & RefAttributes<HTMLTableElement>
> = React.forwardRef((props, ref) => {
  const {
    disableUnsortedState,
    id,
    onSortChanged,
    initialSortColumnId,
    initialSortDirection,
    children,
    getAriaSortMessage,
    tableProps,
  } = props;
  const { sortColumn, sortDirection, onColumnClicked } = useSingleColumnSort({
    disableUnsortedState,
    initialSortColumnId,
    initialSortDirection,
    onSortChanged,
  });

  const contextValue = React.useMemo(
    () => ({
      currentSortDirection: sortDirection,
      currentSortColumn: sortColumn,
      onColumnClicked,
    }),
    [sortDirection, sortColumn, onColumnClicked],
  );

  const { onKeyDown, ref: navigationRef } = useGridKeyboardNavigation();
  const handleRef = useForkRef(ref, navigationRef);

  const ariaMessage = (sortColumn && getAriaSortMessage(sortColumn, sortDirection)) || '';

  return (
    <>
      <SortableTableContext.Provider value={contextValue}>
        <Table
          aria-readonly="true"
          {...tableProps}
          id={id}
          ref={handleRef}
          role="grid"
          onKeyDown={onKeyDown}
        >
          {children}
        </Table>
      </SortableTableContext.Provider>
      <OffScreenAnnouncement message={ariaMessage} />
    </>
  );
});
SortableTable.displayName = 'SortableTable';
export default SortableTable;
