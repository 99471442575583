import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { UploadedFileProps } from '../FileUploader.types';
import { Trash } from '../../../internal/icons/small/Trash';
import { IconButton } from '../../IconButton';
import { Theme } from '../../styles';
import { Typography } from '../../Typography';
import { Clear } from '../../../internal/icons/small/Clear';

export const useStyles = makeStyles((theme: Theme) => {
  const defaultBackgroundColor =
    theme.palette.type === 'light' ? theme.palette.background.paper : theme.palette.background.b1;

  return createStyles({
    fileRoot: {
      alignItems: 'stretch',
      backgroundColor: defaultBackgroundColor,
      display: 'flex',
      minHeight: '38px',
    },
    fileContent: {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
      flexGrow: 1,
      gridGap: '8px',
      padding: '8px 12px',
    },
    fileIcon: {
      alignItems: 'center',
      display: 'flex',
      flexShrink: 0,
      height: '24px',
      marginRight: '8px',
    },
    fileLabel: {
      flexGrow: 1,
    },
  });
});

export const FileUploaded = (props: UploadedFileProps) => {
  const {
    file,
    fileIcon,
    fileLabel,
    fileSetter,
    removeIcon,
    removeLabel,
    fileInputRef,
    error,
    errorTitle,
  } = props;
  const classes = useStyles(props);

  function handleRemove() {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }

    fileSetter?.(undefined);
  }

  const initialIcon = () => {
    if (error) {
      return <Clear color="error" />;
    }
    return fileIcon && <div className={classes.fileIcon}>{fileIcon(file)}</div>;
  };

  return (
    <div className={classes.fileRoot}>
      <div className={classes.fileContent}>
        {initialIcon()}
        <div className={classes.fileLabel}>
          <Typography variant="subtitle2" component="span">
            {fileLabel(file)}
          </Typography>
          {error && (
            <>
              <br />
              <Typography color="error" variant="subtitle2" component="span">
                {errorTitle}
              </Typography>
            </>
          )}
        </div>
      </div>
      <IconButton aria-label={removeLabel} onClick={handleRemove}>
        {removeIcon ?? <Trash />}
      </IconButton>
    </div>
  );
};
