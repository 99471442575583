import * as React from 'react';
import { default as MuiFormLabel, FormLabelProps } from '@material-ui/core/FormLabel';

export const FormLabel = React.forwardRef(
  (props: FormLabelProps, ref: React.Ref<HTMLLabelElement>) => (
    <MuiFormLabel ref={ref} {...props} />
  ),
);

export default FormLabel;
