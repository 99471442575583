import * as React from 'react';
import { default as MuiTableBody, TableBodyProps } from '@material-ui/core/TableBody';

export const TableBody = React.forwardRef(
  (props: TableBodyProps, ref: React.Ref<HTMLTableSectionElement>) => (
    <MuiTableBody ref={ref} {...props} />
  ),
);

export default TableBody;
