import { default as React, FunctionComponent, useState, useRef } from 'react';
import { Key } from 'ts-key-enum';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { createStyles } from '@material-ui/core/styles';

import { TextField } from '../TextField';
import { InputAdornment } from '../InputAdornment';
import { Search } from '../../internal/icons/small/Search';
import { Clear } from '../../internal/icons/small/Clear';
import { IconButton } from '../IconButton';
import { IBaseSearchBoxProps, IPredictiveSearchBoxProps, SearchBoxProps } from './SearchBox.types';
import { ListboxDropdown } from '../ListboxDropdown';

function isPredictiveVariant<Option>(props: unknown): props is IPredictiveSearchBoxProps<Option> {
  return (
    typeof props === 'object' &&
    props !== null &&
    'variant' in props &&
    (props as Record<string, unknown>).variant === 'predictive-search'
  );
}

export const styles = () =>
  createStyles({
    label: {
      transform: 'translate(40px, 9px) scale(0.875)',
    },
    clearButton: {
      // Inset focus outline
      '&::before': {
        // !important needed to override specificity from focusOutline
        top: '4px !important',
        left: '4px !important',
        right: '4px !important',
        bottom: '4px !important',
      },
    },
  });

const BaseSearchBox: FunctionComponent<IBaseSearchBoxProps & WithStyles<typeof styles>> = (
  props,
) => {
  const {
    clearButtonAriaLabel,
    classes,
    className,
    disabled,
    inputId,
    inputRef,
    InputLabelProps,
    InputProps,
    floatingLabel = true,
    label,
    placeholder,
    onChange,
    onClear,
    onEscape,
    onKeyDown,
    onSearch,
    onFocus,
    onBlur,
    TextFieldProps,
    value,
    hideIconOnFocus = true,
  } = props;

  // For an uncontrolled component, we will store the value here
  const [inputValue, setInputValue] = useState('');

  const getValue = () => (value === undefined ? inputValue : value);

  const [isInputFocused, setIsInputFocused] = useState(false);

  const ownRef = useRef(null);

  const inputEl: React.MutableRefObject<HTMLInputElement | null> = inputRef || ownRef;

  const isClearButtonVisible = !!getValue()?.length;

  // Use a floating (adaptive) label by default if only a label is passed.
  // If a placeholder is passed, a static label will be used (the floatingLabel property is ignored).
  // Use a static label if only a label is passed and floatingLabel is false.
  const isFloatingLabel = placeholder && placeholder?.length > 0 ? false : floatingLabel;

  const onChangeInternal = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // Only need to update uncontrolled value if a controlled value is not passed in
    if (value === undefined) {
      setInputValue(event.target.value);
    }
    if (onChange) {
      onChange(event.target.value);
    }
  };

  const onClearInternal = (
    event:
      | React.KeyboardEvent<HTMLInputElement>
      | React.MouseEvent<HTMLElement>
      | React.KeyboardEvent<HTMLButtonElement>,
  ) => {
    if (onClear) {
      onClear(event as React.KeyboardEvent<HTMLInputElement> | React.MouseEvent<HTMLElement>);
    }
    if (!event.defaultPrevented) {
      if (inputEl.current) {
        inputEl.current.focus();
      }
      if (onChange) {
        onChange('');
      }
      if (value === undefined) {
        setInputValue('');
      }
    }
  };

  const onKeyDownInternal = (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case Key.Escape:
        if (onEscape) {
          onEscape(event);
        }
        if (!event.defaultPrevented) {
          onClearInternal(event);
        }
        event.stopPropagation();
        break;
      case Key.Enter:
        if (onSearch) {
          onSearch(getValue());
        }
        break;
      default:
        if (onKeyDown) {
          onKeyDown(event);
        }
        break;
    }
  };

  const onTextFieldFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    onFocus?.(event);
    setIsInputFocused(true);
  };

  const onTextFieldBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement | HTMLButtonElement>,
  ) => {
    if (!inputEl.current?.contains(document.activeElement)) {
      onBlur?.(event);
    }

    setIsInputFocused(false);
  };

  const isLabelShrunk = !!(isInputFocused || getValue());

  return (
    <TextField
      className={className}
      disabled={disabled}
      floatingLabel={isFloatingLabel}
      fullWidth
      label={label || 'Search'}
      placeholder={placeholder}
      id={inputId}
      inputRef={inputEl}
      onBlur={onTextFieldBlur}
      onFocus={onTextFieldFocus}
      onChange={onChangeInternal}
      onKeyDown={onKeyDownInternal}
      value={getValue()}
      InputLabelProps={{
        ...(isFloatingLabel && {
          classes: {
            root: classes.label,
          },
          shrink: isLabelShrunk,
        }),
        ...InputLabelProps,
      }}
      InputProps={{
        startAdornment:
          isLabelShrunk && hideIconOnFocus ? null : (
            <InputAdornment position="start">
              <Search data-testid="search-icon" />
            </InputAdornment>
          ),
        endAdornment: isClearButtonVisible ? (
          <InputAdornment position="end">
            <IconButton
              aria-label={clearButtonAriaLabel || 'Clear search input'}
              onClick={onClearInternal}
              onBlur={onTextFieldBlur}
              onKeyDown={(event: React.KeyboardEvent<HTMLButtonElement>) => {
                if (event.key === Key.Enter) {
                  event.stopPropagation();
                  onClearInternal(event);
                }
              }}
              className={classes.clearButton}
            >
              <Clear />
            </IconButton>
          </InputAdornment>
        ) : null,
        ...InputProps,
      }}
      {...TextFieldProps}
    />
  );
};

export const BbDefaultSearchBox = withStyles(styles)(BaseSearchBox);

export const BbSearchBox = <Option,>(props: SearchBoxProps<Option>) => {
  if (isPredictiveVariant<Option>(props)) {
    const { listboxProps, ...searchboxProps } = props;
    return (
      <ListboxDropdown
        {...listboxProps}
        id={`${searchboxProps.inputId}-dropdown`}
        inputRef={searchboxProps.inputRef}
        renderInput={({ onTextChange, onFocus, onBlur, onKeyDown, ariaProps, inputRef }) => (
          <BbDefaultSearchBox
            {...searchboxProps}
            inputRef={inputRef}
            onChange={(text: string) => {
              onTextChange(text);
              searchboxProps.onChange?.(text);
            }}
            onKeyDown={(event) => {
              onKeyDown(event as React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>);
              searchboxProps.onKeyDown?.(event);
            }}
            onFocus={(event) => {
              onFocus(event as React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>);
              searchboxProps.onFocus?.(event);
            }}
            onBlur={(event) => {
              onBlur(event as React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>);
              searchboxProps.onBlur?.(event);
            }}
            onEscape={(event) => {
              onKeyDown(event as React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>);
              searchboxProps.onEscape?.(event);
            }}
            TextFieldProps={{
              autoComplete: 'off',
              ...searchboxProps.TextFieldProps,
            }}
            InputProps={{
              ...searchboxProps.InputProps,
              inputProps: {
                ...searchboxProps.InputProps?.inputProps,
                ...ariaProps,
              },
            }}
          />
        )}
      />
    );
  }

  return <BbDefaultSearchBox {...props} />;
};

export default BbSearchBox;
