import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme } from '../styles';

export const useStyles = makeStyles((theme: Theme) => {
  const defaultBackgroundColor =
    theme.palette.type === 'light' ? theme.palette.background.paper : theme.palette.background.b1;

  return createStyles({
    container: {
      textAlign: 'center',
      backgroundColor: defaultBackgroundColor,
    },
    inputContainer: {
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'center',
      border: `2px dashed ${theme.palette.background.b5}`,
    },
    inputContainerDragged: {
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'center',
      border: `2px solid ${theme.palette.brand.main}`,
    },
    input: {
      // Hide the native file input.
      opacity: 0,
      position: 'absolute',
      height: '100%',
      width: '100%',
      left: '0%',
    },
    uploadFromFileButton: {
      marginBottom: theme.spacing(3),
    },
    dragFilesTitle: {
      margin: '0',
    },
    helpText: {
      color: theme.palette.background.b8,
    },
    noHelpText: {
      display: 'none',
      margin: 0,
    },
    or: {
      marginBottom: theme.spacing(1),
      marginTop: 0,
    },
    inputContainerError: {
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'center',
      border: `2px solid ${theme.palette.error.main}`,
    },
    attentionIcon: {
      margin: '20px 0 0 0',
    },
    noAttentionIcon: {
      display: 'none',
    },
  });
});
