import * as React from 'react';
import { default as MuiButtonBase, ButtonBaseProps } from '@material-ui/core/ButtonBase';

export const ButtonBase = React.forwardRef(
  (props: ButtonBaseProps, ref: React.Ref<HTMLButtonElement>) => (
    <MuiButtonBase ref={ref} {...props} />
  ),
);

export default ButtonBase;
