import * as React from 'react';
import { Button } from '../Button';
import { IButtonProps } from '../Button.types';

export type PrimaryButtonProps =
  | Omit<IButtonProps, 'variant'>
  | {
      /**
       * The primary button variant. Specifying "outlined" will render this button identical to
       * the default `<OutlineButton />` component.
       *
       * Using the variant is useful to change the style of the button in situations where you
       * want to change the style to a standard style without remounting the button, which would
       * remove it from the DOM and cause the button to lose focus.
       *
       * @default "contained"
       */
      variant?: 'contained' | 'outlined';
    };

export const PrimaryButton = React.forwardRef(
  (props: IButtonProps, ref: React.Ref<HTMLButtonElement>) => (
    <Button variant="contained" color="primary" {...props} ref={ref} />
  ),
);

export default PrimaryButton;
