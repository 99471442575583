import { Theme } from '@material-ui/core/styles/createTheme';
import { Overrides } from '@material-ui/core/styles/overrides';

export const ExpansionPanelSummaryOverrides = (theme: Theme): Overrides => ({
  MuiExpansionPanelSummary: {
    // Vertically center items by default.
    content: {
      alignItems: 'center',
      '&$expanded': {
        margin: '12px 0',
      },
    },
    root: {
      paddingLeft: 0,
      paddingRight: 0,
      '&$focused': {
        backgroundColor: undefined,
      },
      minHeight: 'auto',
      '&$expanded': {
        minHeight: 'auto',
      },
    },
    expandIcon: {
      color: theme.palette.background.b7,
      '&:hover': {
        color:
          theme.palette.type === 'light'
            ? theme.palette.background.b9
            : theme.palette.background.b10,
      },
    },
  },
});
