import { createStyles, makeStyles } from '../styles';

export const useStyles = makeStyles(() =>
  createStyles({
    list: {
      margin: 0,
      padding: 0,
    },
    listbox: {
      maxHeight: '50vh',
      overflowY: 'auto',
      '& > li:last-child': {
        // Round bottom corners
        borderRadius: '0 0 1px 1px',
      },
    },
  }),
);
