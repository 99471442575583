import * as React from 'react';
import { createSvgIcon, IconDirectionality } from '..';

export const Close = createSvgIcon(
  {
    [IconDirectionality.LeftToRight]: (
      <>
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M15 1L1 15M1 1l14 14"
        />
      </>
    ),
  },
  'small',
);
