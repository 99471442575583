import * as React from 'react';
import { default as MuiRadio, RadioProps } from '@material-ui/core/Radio';
import { RadioIcon } from './RadioIcon';

interface InputPropsWithCustomProps extends React.InputHTMLAttributes<HTMLInputElement> {
  // Accommodate any additional custom props coming from the `inputProps` prop
  [customProp: string]: unknown;
}

export interface RadioPropsModified extends Omit<RadioProps, 'inputProps'> {
  inputProps?: InputPropsWithCustomProps;
}

export const Radio = React.forwardRef(
  (props: RadioPropsModified, ref: React.Ref<HTMLButtonElement>) => {
    const { disabled, ...other } = props;

    return (
      <MuiRadio
        aria-disabled={undefined}
        icon={<RadioIcon checked={false} disabled={disabled} />}
        checkedIcon={<RadioIcon checked={true} disabled={disabled} />}
        disabled={disabled}
        ref={ref}
        {...other}
      />
    );
  },
);

export default Radio;
