import * as React from 'react';
import { default as MuiSwitch, SwitchProps } from '@material-ui/core/Switch';
import { SwitchThumb } from './SwitchThumb';

export interface InputPropsWithCustomProps extends React.InputHTMLAttributes<HTMLInputElement> {
  // Accommodate any additional custom props coming from the `inputProps` prop
  [customProp: string]: unknown;
}
export interface SwitchPropsModified extends Omit<SwitchProps, 'inputProps'> {
  inputProps?: InputPropsWithCustomProps;
}

export const Switch = React.forwardRef(
  (props: SwitchPropsModified, ref: React.Ref<HTMLButtonElement>) => (
    <MuiSwitch
      checkedIcon={<SwitchThumb checked={true} {...props} />}
      icon={<SwitchThumb checked={false} {...props} />}
      ref={ref}
      disableRipple={false}
      {...props}
    />
  ),
);

export default Switch;
