import * as React from 'react';
import { createSvgIcon, IconDirectionality } from '..';

export const Add = createSvgIcon({
  [IconDirectionality.LeftToRight]: (
    <>
      <circle cx="12" cy="12" r="11" stroke="currentColor" strokeWidth="2" />
      <path
        fill="currentColor"
        stroke="transparent"
        d="M13 7c0-.5523-.4477-1-1-1s-1 .4477-1 1h2zm-2 10c0 .5523.4477 1 1 1s1-.4477 1-1h-2zm0-10v10h2V7h-2z"
      />
      <path
        fill="currentColor"
        stroke="transparent"
        d="M17 13c.5523 0 1-.4477 1-1s-.4477-1-1-1v2zM7 11c-.5523 0-1 .4477-1 1s.4477 1 1 1v-2zm10 0H7v2h10v-2z"
      />
    </>
  ),
});
