import * as React from 'react';
import { Card, CardContent, CardHeader, Theme, IconButton, Tooltip, createStyles, makeStyles } from '@bb-ui/react-library';
import { Edit, Information } from '@bb-ui/icons/dist/small';
import { ExpansionPanel } from "@bb-ui/react-library/dist/components/ExpansionPanel";
import { ExpansionPanelSummary } from "@bb-ui/react-library/dist/components/ExpansionPanelSummary";
import { ExpansionPanelDetails } from "@bb-ui/react-library/dist/components/ExpansionPanelDetails";
import { useState } from "react";
import { getBundle, ILocaleProps } from "../../lib/locales";

export interface FeatureFlagInformationItemProps extends ILocaleProps {
  title: string;
  value?: string | JSX.Element;
  editable?: boolean;
  onEdit?: any;
  expandable?: boolean;
  tooltip?: boolean;
  tooltipTitle?: string;
  testId?: string;
}

export const useStyles = makeStyles((theme: Theme) => createStyles({
  card: {
    border: 'none',
    display: 'grid',
    gridSpacing: theme.spacing(2),
    gridTemplateColumns: '1fr 1fr',
    marginBottom: theme.spacing((1 / 8)),
  },
  cardContent: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.fontSizeSmall,
    textAlign: 'right',
  },
  cardHeader: {
    paddingRight: theme.spacing(0),
    fontSize: theme.typography.fontSizeSmall,
    fontWeight: theme.typography.fontWeightBold,
  },
  metadataInfoIcon: {
    position: 'relative',
    top: theme.spacing(2),
    left: theme.spacing(1),
  },
  unsetValue: {
    fontStyle: 'italic',
  },
  summaryContent: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.fontSizeSmall,
    textAlign: 'right',
    fontStyle: 'italic',
    display: 'block',
  },
  summaryButton: {
    marginRight: theme.spacing(1),
  },
  summaryExpanded: {
    '&$summaryContent': {
      borderBottom: `1px solid ${theme.palette.border.main}`,
    },
  },
  details: {
    flexDirection: 'column',
    color: theme.palette.text.secondary,
    fontSize: theme.typography.fontSizeSmall,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  valueLabels: {
    display: 'inline-flex',
  },
}));

export const FeatureFlagInformationItem: React.FC<FeatureFlagInformationItemProps> = (
  props,
) => {
  const { title, value, editable, onEdit, expandable, locale, tooltip, tooltipTitle, testId } = props;
  const classes = useStyles(props);
  const [enabled, setEnabled] = useState(false);
  const bundle = getBundle(locale);

  const cardContent = (
    <CardContent className={classes.cardContent} data-testid="flag-info-item-content">
      {editable &&
        <IconButton aria-label={bundle.edit} onClick={onEdit}>
          <Edit />
        </IconButton>}
      {value ?? <span className={classes.unsetValue}>Unset</span>}
    </CardContent>
  );

  const tooltipLabel = (
    <Tooltip
      title={tooltipTitle ?? ""}
      placement="right"
      data-testid="flag-info-item-tooltip"
    >
      <Information className={classes.metadataInfoIcon} />
    </Tooltip>
  );

  const expandablePanelContent = (
    <ExpansionPanel
      defaultExpanded={false}
      expanded={enabled}
      onClick={() => setEnabled(!enabled)}
      data-testid="flag-info-item-expansion"
    >
      <ExpansionPanelSummary
        id={`${title}-panel-summary`}
        aria-controls="details"
        classes={ { content: classes.summaryContent, expanded: classes.summaryExpanded, expandIcon: classes.summaryButton } }
      >
        {enabled ? bundle.seeLess : bundle.seeMore }
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={ classes.details }>
        {value}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );

  return (
    <Card className={classes.card} data-testid={`flag-info-item-row-${testId}`}>
      <div id="defaultValueLabel" className={classes.valueLabels}>
        <CardHeader title={title} data-testid="flag-info-item-title" className={classes.cardHeader} />
        {tooltip && tooltipLabel}
      </div>
      {expandable ? expandablePanelContent : cardContent}
    </Card>
  );
};
