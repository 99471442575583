import * as React from 'react';
import { makeStyles, createStyles } from '../../styles';
import { Typography } from '../../Typography';
import type { SingleValueProps, ComboboxOption } from '../Combobox.types';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      pointerEvents: 'none',
    },
  }),
);

export const SingleValue = <Option extends ComboboxOption>(props: SingleValueProps<Option>) => {
  const { option, ...other } = props;
  const classes = useStyles();

  return (
    <Typography component="span" className={classes.root} {...other}>
      {option.label}
    </Typography>
  );
};

export default SingleValue;
