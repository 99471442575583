import { ExecutionException } from "../execution/ExecutionException";
import { Status } from "../execution/Status";

export type ExecutionConcludedMessage = {
  messageType: "ExecutionConcluded";
  tenantId: string;
  executionId: string;
  claimId: string;
  status: Status;
  output?: object;
  exception?: ExecutionException;
};

export function isExecutionConcludedMessage(
  message: any
): message is ExecutionConcludedMessage {
  return message.messageType === "ExecutionConcluded";
}
