import * as React from 'react';
import { default as MuiTextField } from '@material-ui/core/TextField';
import { ITextFieldProps } from './TextField.types';
import { defaultMenuPlacement } from '../Menu/Menu';

export const TextField = React.forwardRef<HTMLDivElement, ITextFieldProps>((props, ref) => {
  // We pull out variant but don't use it because we will be overriding it below
  // and don't want it to be spread.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    error,
    floatingLabel,
    InputProps: ClientInputProps,
    onChange,
    onKeyDown,
    onKeyUp,
    onBlur,
    onFocus,
    variant,
    SelectProps,
    ...other
  } = props;

  // MUI passes the following props to InputProps.
  // We destructure above to prevent passing into `...other`, then include them along with any client provided InputProps

  const InputProps = {
    onChange,
    onKeyDown,
    onKeyUp,
    onBlur,
    onFocus,
    ...ClientInputProps,
  };

  // destructure to only modify specific sub-component properties within the <MuiTextField />
  const { SelectDisplayProps, MenuProps, ...otherSelectProps } = SelectProps ?? {};

  // We don't destructure this above to prevent breaking the component, since the id prop is deeply nested in several places
  const labelId = props.id ? `${props.id}-label` : undefined;

  if (floatingLabel) {
    return (
      <MuiTextField
        error={error}
        variant="outlined"
        InputProps={InputProps}
        SelectProps={{
          SelectDisplayProps: {
            'aria-labelledby': labelId,
            ...SelectDisplayProps,
          },
          MenuProps: {
            'aria-labelledby': labelId,
            ...defaultMenuPlacement,
          },
          ...otherSelectProps,
        }}
        FormHelperTextProps={{
          role: error ? 'alert' : undefined,
        }}
        ref={ref}
        {...other}
      />
    );
  }

  return (
    <MuiTextField
      error={error}
      variant="standard"
      InputProps={{ disableUnderline: true, ...InputProps }}
      SelectProps={{
        SelectDisplayProps: {
          'aria-labelledby': labelId,
          ...SelectDisplayProps,
        },
        MenuProps: {
          'aria-labelledby': labelId,
          ...defaultMenuPlacement,
        },
        ...otherSelectProps,
      }}
      FormHelperTextProps={{
        role: error ? 'alert' : undefined,
      }}
      ref={ref}
      {...other}
    />
  );
});

export default TextField;
