import { Theme } from '@material-ui/core/styles/createTheme';
import { Overrides } from '@material-ui/core/styles/overrides';

export const IconButtonOverrides = (theme: Theme): Overrides => ({
  MuiIconButton: {
    root: {
      minWidth: '38px',
      minHeight: '38px',
      padding: undefined,
      borderRadius: 0,
      color: theme.palette.action.active,
      fontWeight: theme.typography.fontWeightSemiBold,
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.button.fontSize,
      lineHeight: 0,
      '&:hover': {
        backgroundColor: undefined,
        color: theme.palette.action.hover,
        textDecoration: 'underline',
      },
    },
    colorSecondary: {
      '&:hover': {
        backgroundColor: undefined,
      },
    },
    colorInherit: {
      '&:active, &:hover, &:active:hover': {
        opacity: 0.9,
      },
    },
    label: {
      '& span': {
        margin: '0 4px',
      },
    },
  },
});
