import { default as MuiSkeleton } from '@material-ui/lab/Skeleton';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { useEffect } from 'react';
import { Theme } from '../styles';
import { SkeletonProps } from './Skeleton.types';

const useStyles = makeStyles((theme: Theme) => {
  const gradientColor =
    theme.palette.type === 'light' ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4 )';
  return createStyles({
    wave: {
      '&::after': {
        background: `linear-gradient(90deg, transparent, ${gradientColor}, transparent)`,
      },
    },
  });
});

export const Skeleton = React.forwardRef((props: SkeletonProps, ref: React.Ref<HTMLDivElement>) => {
  const { animation = 'timedWave' } = props;
  const styles = useStyles(props);
  const [dynamicAnimation, setDynamicAnimation] = React.useState<'wave' | false>('wave');

  useEffect(() => {
    setDynamicAnimation('wave');

    if (animation !== 'timedWave') {
      return undefined;
    }

    const timeoutId = setTimeout(() => {
      setDynamicAnimation(false);
    }, 3000);

    return () => {
      // Clean up the timeout in case the component is removed from the UI.
      clearTimeout(timeoutId);
    };
  }, [animation]);

  return (
    <MuiSkeleton
      ref={ref}
      classes={{
        wave: styles.wave,
      }}
      {...props}
      animation={dynamicAnimation}
    />
  );
});

export default Skeleton;
