import * as React from 'react';
import { FeatureFlagDefinitionData } from 'hooks/feature-flag-definition/use-feature-flag-definitions.types';
import { getBundle, ILocaleProps } from "../../lib/locales";
import { FeatureFlagInformationItem } from './feature-flag-definition-detail-item';

export interface IFeatureDetailViewProps extends ILocaleProps {
  featureFlagData: FeatureFlagDefinitionData;
}

export const FeatureFlagDefinitionGlobalSettingsPanel: React.FC<IFeatureDetailViewProps> = (props) => {
  const { featureFlagData, locale } = props;
  const bundle = getBundle(locale);

  let defaultValue = "";
  if (featureFlagData.possibleValues.type.toLowerCase() === "list") {
    defaultValue = featureFlagData.defaultValue;
  } else {
    defaultValue = featureFlagData.defaultValue === "true" ? bundle.on : bundle.off;
  }

  let releaseVersion = bundle.none;
  if (featureFlagData.releaseVersion) {
    const v = featureFlagData.releaseVersion.version;
    releaseVersion = `${featureFlagData.releaseVersion.productType} ${v.major}.${v.minor}.${v.patch}`;
  }
  const releaseVersionItem = featureFlagData.possibleValues.type.toLowerCase() === "boolean" && (
    <FeatureFlagInformationItem
      title={bundle.releaseVersion}
      value={releaseVersion}
      tooltip={true}
      tooltipTitle={bundle.flagDefinitionReleaseVersionDescription}
      testId="global-release-version"
    />
  );

  return (
    <>
      <FeatureFlagInformationItem
        title={bundle.globalStatus}
        value={defaultValue}
        tooltip={true}
        tooltipTitle={bundle.flagDefinitionGlobalStatusDescription}
        testId="global-settings-status"
      />
      <FeatureFlagInformationItem
        title={bundle.globalPermissions}
        value={featureFlagData.visibility.visible ? bundle.canEdit : bundle.none}
        tooltip={true}
        tooltipTitle={bundle.flagDefinitionClientPermissionsDescription}
        testId="global-permissions"
      />
      {releaseVersionItem}
    </>
  );
};

export default FeatureFlagDefinitionGlobalSettingsPanel;
