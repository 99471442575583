export const rgba = (hexValue: string, alpha: number) => {
  let hex = hexValue;
  if (hex.substring(0, 1) === '#') {
    hex = hex.substring(1);
  }
  const rChannel = parseInt(hex.substring(0, 2), 16);
  const gChannel = parseInt(hex.substring(2, 4), 16);
  const bChannel = parseInt(hex.substring(4), 16);

  return `rgba(${rChannel}, ${gChannel}, ${bChannel}, ${alpha})`;
};

export const rtlNoFlip = (styleValue: string) => `${styleValue} /* @noflip */`;
