import { Theme } from '@material-ui/core/styles/createTheme';
import { Overrides } from '@material-ui/core/styles/overrides';
import { rgba } from '../styles/utilities';

export const DialogOverrides = (theme: Theme): Overrides => {
  const dialogBorderColor =
    theme.palette.type === 'light'
      ? theme.palette.border.main
      : rgba(theme.palette.background.b2, 0.6);
  const actionsBackgroundColor =
    theme.palette.type === 'light'
      ? theme.palette.background.paper
      : rgba(theme.palette.background.b3, 0.2);

  return {
    MuiDialog: {
      root: {
        overflowY: 'auto',
      },
      container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: undefined,
        minHeight: '100%',
      },
      paper: {
        width: '100%',
        border: `1px solid ${dialogBorderColor}`,
        boxShadow: `${rgba(theme.palette.background.b9, 0.075)} 0px 0px 0px 4px`,
      },
      paperWidthSm: {
        maxWidth: '300px',
        '&$paperScrollBody': {
          [theme.breakpoints.up('xs')]: {
            maxWidth: '300px',
            margin: '30px 12px',
          },
        },
        '& .bb-dialog-content': {
          minHeight: '50px',
        },
      },
      paperWidthMd: {
        maxWidth: '450px',
        '&$paperScrollBody': {
          [theme.breakpoints.up('xs')]: {
            maxWidth: '450px',
            margin: '30px 12px',
          },
        },
        '& .bb-dialog-content': {
          minHeight: '100px',
        },
      },
      paperWidthLg: {
        maxWidth: '800px',
        '&$paperScrollBody': {
          [theme.breakpoints.up('xs')]: {
            maxWidth: '800px',
            margin: '30px 12px',
          },
        },
        '& .bb-dialog-title': {
          paddingBottom: '12px',
          borderBottom: `1px solid ${dialogBorderColor}`,
        },
        '& .bb-dialog-content': {
          minHeight: '200px',
        },
      },
    },
    MuiDialogTitle: {
      root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px 16px 0',
        '& .bb-h1-element:focus': {
          outline: 'none',
        },
      },
    },
    MuiDialogContent: {
      root: {
        padding: '16px',
        '&:focus': {
          outline: 'none',
        },
      },
    },
    MuiDialogActions: {
      root: {
        flexWrap: 'wrap',
        margin: undefined,
        padding: '16px',
        backgroundColor: actionsBackgroundColor,
        borderTop: `solid 1px ${dialogBorderColor}`,
      },
    },
  };
};
