import { Theme } from '@material-ui/core/styles/createTheme';
import { Overrides } from '@material-ui/core/styles/overrides';

export const ToolbarOverrides = (theme: Theme): Overrides => ({
  MuiToolbar: {
    regular: {
      minHeight: '48px',
      [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
        minHeight: undefined,
      },
      [theme.breakpoints.up('sm')]: {
        minHeight: undefined,
      },
      [theme.breakpoints.up('md')]: {
        minHeight: '64px',
      },
    },
    gutters: {
      paddingLeft: '12px',
      paddingRight: '12px',
      [theme.breakpoints.up('sm')]: {
        paddingLeft: '20px',
        paddingRight: '20px',
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: '30px',
        paddingRight: '30px',
      },
    },
  },
});
