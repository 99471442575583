import React from 'react';
import AsyncSelect from 'react-select/async';
import AsyncCreatable from 'react-select/async-creatable';
import { withTheme, withStyles } from '@material-ui/core/styles';
import { OptionType } from '../Picker/Picker.types';
import { BasePicker, basePickerDefaultProps } from '../Picker/BasePicker';
import { styles } from '../Picker/Picker.styles';
import { AsyncPickerProps } from './AsyncPicker.types';

const AsyncSelectComponent = React.forwardRef(
  (
    props: AsyncPickerProps,
    ref: React.Ref<AsyncSelect<OptionType> | AsyncCreatable<OptionType, boolean>>,
  ) =>
    props.isCreatable ? (
      <AsyncCreatable {...props} ref={ref as React.Ref<AsyncCreatable<OptionType, boolean>>} />
    ) : (
      <AsyncSelect {...props} ref={ref as React.Ref<AsyncSelect<OptionType>>} />
    ),
);

export const AsyncPicker: React.FunctionComponent<AsyncPickerProps> = (props) => (
  <BasePicker {...props} ChosenSelectComponent={AsyncSelectComponent} />
);

AsyncPicker.defaultProps = basePickerDefaultProps;

export const AsyncPickerStyled = withStyles(styles)(AsyncPicker);
export default withTheme(AsyncPickerStyled);
