import * as React from 'react';
import { SvgIcon, SvgIconProps } from '../../components/SvgIcon';
import { useTheme } from '../../components/styles';

export enum IconDirectionality {
  /**
   * The specified icon is left-to-right specific.
   */
  LeftToRight = 'LeftToRight',

  /**
   * The specified icon is right-to-left specific.
   */
  RightToLeft = 'RightToLeft',

  /**
   * The specified icon is applicable to left-to-right and right-to-left locales.
   */
  All = 'All',
}

type IconPathMap = { [k in IconDirectionality]?: JSX.Element };

export default function createSvgIcon(
  icons: IconPathMap,
  size?: 'small' | 'inherit' | 'default' | 'large' | undefined,
) {
  return React.forwardRef((props: SvgIconProps, ref?: React.Ref<SVGSVGElement>) => {
    // exclude ref from props being passed to component to avoid collision with the ref type from forwardRef
    const { ref: refProp, ...other } = props;
    const { direction } = useTheme();

    let path = icons.LeftToRight;
    let isDirectional = true;

    if (icons.All) {
      path = icons.All;
      isDirectional = false;
    } else if (icons.RightToLeft && direction === 'rtl') {
      path = icons.RightToLeft;
      isDirectional = false;
    }

    return (
      <SvgIcon fontSize={size} ref={ref} isDirectional={isDirectional} {...other}>
        {path}
      </SvgIcon>
    );
  });
}
