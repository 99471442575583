import { Theme } from '@material-ui/core/styles/createTheme';
import { Overrides } from '@material-ui/core/styles/overrides';

const placeholder = {
  color: 'currentColor',
  opacity: 0.6,
};

export const InputBaseOverrides = (theme: Theme): Overrides => ({
  MuiInputBase: {
    root: {
      fontSize: theme.typography.fontSizeDefault,
      '&$adornedStart': {
        paddingLeft: '12px',
        '& > div': {
          color: theme.palette.text.secondary,
        },
      },
      '&$adornedEnd': {
        paddingRight: '12px',
        '& > div': {
          color: theme.palette.text.secondary,
        },
      },
    },
    input: {
      boxSizing: 'border-box',
      padding: '1px 12px 0',
      minHeight: '38px',
      '&::-webkit-input-placeholder': placeholder,
      '&::-moz-placeholder': placeholder, // Firefox 19+
      '&::-ms-input-placeholder': placeholder, // Edge
      'label[data-shrink=false] + $formControl &': {
        '&::-webkit-input-placeholder': { opacity: undefined },
        '&::-moz-placeholder': { opacity: undefined }, // Firefox 19+
        '&::-ms-input-placeholder': { opacity: undefined }, // Edge
        '&:focus::-webkit-input-placeholder': { opacity: undefined },
        '&:focus::-moz-placeholder': { opacity: undefined }, // Firefox 19+
        '&:focus::-ms-input-placeholder': { opacity: undefined }, // Edge
      },
      '&$inputAdornedStart': {
        paddingLeft: 0,
      },
      '&$inputAdornedEnd': {
        paddingRight: 0,
      },
    },
    multiline: {
      padding: '0 0 10px 0',
    },
    inputMultiline: {
      // Input has minHeight of 38px, but we're adding a bottom-padding of 10px (multiline class)
      // to the container, so reduce min-height by that padding to avoid the container being
      // too tall by 10px
      minHeight: '28px',
      padding: '10px 10px 0',
      lineHeight: '17px',
    },
  },
});
