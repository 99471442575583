import * as React from 'react';
import classnames from 'classnames';
import { createStyles } from '@material-ui/core/styles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { default as MuiDialogTitle } from '@material-ui/core/DialogTitle';
import { Close } from '../../internal/icons/medium/Close';
import { Typography } from '../Typography';
import { IconButton } from '../IconButton';
import { DialogTitleProps } from './DialogTitle.types';

export const styles = () =>
  createStyles({
    closeButton: {
      position: 'relative',
      right: '-6px',
    },
  });

export const DialogTitle = React.forwardRef(
  (props: DialogTitleProps & WithStyles<typeof styles>, ref: React.Ref<unknown>) => {
    const {
      ariaDescribedby,
      classes,
      disableTypography,
      children,
      hideCloseButton,
      id,
      isAlert,
      closeLabel,
      closeButtonProps,
      onClose,
      className,
      ...other
    } = props;
    const combinedClassName = classnames(className, 'bb-dialog-title');

    return (
      <MuiDialogTitle className={combinedClassName} disableTypography ref={ref} {...other}>
        {disableTypography && children}
        {!disableTypography && (
          <Typography
            variant="h1"
            id={id}
            tabIndex={-1}
            className="bb-h1-element"
            aria-describedby={isAlert ? ariaDescribedby : undefined}
          >
            {children}
          </Typography>
        )}
        {!hideCloseButton && (
          <IconButton
            className={classes.closeButton}
            aria-label={closeLabel}
            onClick={onClose}
            {...closeButtonProps}
          >
            <Close />
          </IconButton>
        )}
      </MuiDialogTitle>
    );
  },
);

DialogTitle.defaultProps = {
  closeLabel: 'Close',
};

export default withStyles(styles)(DialogTitle);
