// This is a generated file. Any changes made directly in this file will be overwritten.
import * as React from 'react';
import { createSvgIcon } from '..';

export const UploadFile = createSvgIcon(
  {
    LeftToRight: (
      <>
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M18 21h2V6l-5-5H4v20h2M12 12v11"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M14 1v6h6M8 15l4-4 4 4"
        />
      </>
    ),
  },
  'default',
);
