// This is *not* a context. This instead provides a component that coordinates
// an Auth0Context that is initialized in an UAS-aware way.
//
// You should *not* use <Auth0Provider> if you use this component. It will
// conflict with setup done here.

import * as React from 'react';
import useAxios from 'axios-hooks';
import { Auth0Provider, Auth0ProviderProps } from './Auth0Context';

export type Auth0RegionalClients = Record<
  string,
  {
    client_id: Auth0ProviderProps['client_id'];
    domain: Auth0ProviderProps['domain'];
  }
>;

// Audience, client_id, and domain may not be directly provided by a consumer because in
// UAS, they're derived from the Foundations tenant.

export interface UASProviderProps extends Omit<Auth0ProviderProps, 'audience'> {
  /**
   * Base URL for requests to Foundations APIs. This *must not* have a trailing
   * slash. If you are reverse-proxying an API gateway in your application, this
   * most likely is `/sso/api/v1`.
   */
  apiBaseURL: string;

  /**
   * Regional config for Auth0, keyed by AWS region. If there is no config
   * available for the tenant's region, then this component will throw an error.
   */
  auth0Clients: Auth0RegionalClients;

  /**
   * URI component of the Foundations tenant the user belongs to. If you don't
   * know this, you probably want to direct the user to
   * bb-sign-in-entrypoint-ui. This is only optional to allow deferring
   * initializing loading the context. Until one is specified, authentication
   * info will stay in a loading state.
   */
  tenantUriComponent?: string;
}

interface AudienceResponse {
  /**
   * JWT audience for this tenant.
   */
  audience: string;
  /**
   * AWS region of this tenant.
   */
  region: string;
  /**
   * UUID of this tenant.
   */
  tenantId: string;
}

export const UASProvider: React.FunctionComponent<UASProviderProps> = (props) => {
  const {
    apiBaseURL,
    auth0Clients,
    onError,
    tenantUriComponent,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    redirect_uri,
    ...auth0ConsumerProps
  } = props;
  const [fetchedAudience, setFetchedAudience] = React.useState(false);

  // Retrieve tenant ID and region when we have a URI component.

  const [{ data: audienceData, error: audienceError }, fetchAudience] = useAxios<AudienceResponse>(
    `${apiBaseURL}/audience?uriComponent=${tenantUriComponent}`,
    { manual: true }
  );

  React.useEffect(() => {
    if (tenantUriComponent && !fetchedAudience) {
      setFetchedAudience(true);
      fetchAudience().catch(() => {}); // We'll throw below so an error boundary can catch it.
    }
  }, [fetchAudience, fetchedAudience, onError, tenantUriComponent]);

  const bbLoginParams = React.useMemo(
    () => window.btoa(JSON.stringify({ tenantId: audienceData?.tenantId })),
    [audienceData?.tenantId]
  );

  if (audienceError) {
    throw audienceError;
  }

  if (!auth0Clients) {
    throw new Error('Auth0 client configuration is missing');
  }

  if (audienceData?.region && !auth0Clients[audienceData?.region]) {
    throw new Error(`Auth0 client configuration is missing for region ${audienceData.region}`);
  }

  // Thread together our child contexts. We both feed audience into
  // <Auth0Provider> and make use of its auth token to retrieve tenant
  // information. We use bb_login_params during the login call to indicate to
  // bb-login-ui embedded into Auth0 which Foundations tenant the user is
  // logging into.
  //
  // See https://github.com/blackboard-foundations/bb-sign-in-ui
  //
  // Note also that <Auth0Provider> will not try to initialize its Auth0 client
  // until we pass an audience prop--it may be undefined if we are loading, for
  // example.

  return (
    <Auth0Provider
      audience={audienceData?.audience}
      bb_login_params={bbLoginParams}
      client_id={auth0Clients[audienceData?.region]?.client_id}
      domain={auth0Clients[audienceData?.region]?.domain}
      redirect_uri={redirect_uri}
      {...auth0ConsumerProps}
    >
      {props.children}
    </Auth0Provider>
  );
};

UASProvider.displayName = 'UASProvider';
