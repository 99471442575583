import * as React from 'react';
import { createSvgIcon } from '..';

export const Menu = createSvgIcon(
  {
    LeftToRight: (
      <>
        <circle cx="2" cy="8" r="1" stroke="currentColor" strokeWidth="2" />
        <circle cx="8" cy="8" r="1" stroke="currentColor" strokeWidth="2" />
        <circle cx="14" cy="8" r="1" stroke="currentColor" strokeWidth="2" />
      </>
    ),
  },
  'small',
);
