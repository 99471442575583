import * as React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { TooltipProps } from './Tooltip.types';

export const BbTooltip: React.SFC<TooltipProps> = (props) => {
  const { disableArrow, isCustomTitle, PopperProps, ...other } = props;

  if (isCustomTitle) {
    return (
      <Tooltip
        aria-describedby={undefined}
        enterTouchDelay={0}
        arrow={!disableArrow}
        PopperProps={{
          'aria-hidden': true,
          ...PopperProps,
        }}
        {...other}
      />
    );
  }
  return <Tooltip enterTouchDelay={0} arrow={!disableArrow} PopperProps={PopperProps} {...other} />;
};

export default BbTooltip;
