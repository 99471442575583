import * as React from 'react';
import { BreadcrumbsItemProps } from './Breadcrumbs.types';

export const BreadcrumbsItem: React.FC<BreadcrumbsItemProps> = ({
  children,
  isCurrentLinkActive,
  isCurrentPage,
}) => {
  const renderChildren = () =>
    React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        const elementChild: React.ReactElement<any> = child;
        return React.cloneElement(elementChild, {
          'aria-current': isCurrentPage ? 'page' : undefined,
          role: !isCurrentLinkActive && isCurrentPage ? 'presentation' : undefined,
          tabIndex: !isCurrentLinkActive && isCurrentPage ? '-1' : undefined,
          href: !isCurrentLinkActive && isCurrentPage ? undefined : child.props.href,
        });
      }
      return children;
    });

  return <>{renderChildren()}</>;
};

BreadcrumbsItem.propTypes = {
  children(props, propName, componentName) {
    const prop = props[propName];
    let error = null;
    React.Children.forEach(prop, (child) => {
      if (child.type !== 'a') {
        error = new Error(
          `For accessibility, ${componentName} active children must be an anchor element.`,
        );
      }
    });
    return error;
  },
};

export const isABreadcrumbItem = (
  child: React.ReactNode,
): child is React.ReactElement<BreadcrumbsItemProps> => {
  if (React.isValidElement(child)) {
    // Check non-optional properties of BreadcrumbsItemProps
    return 'label' in child.props && 'isCurrentPage' in child.props;
  }
  return false;
};

export default BreadcrumbsItem;
