import { Theme } from '@material-ui/core/styles/createTheme';
import { Overrides } from '@material-ui/core/styles/overrides';
import { rgba } from '../styles/utilities';

export const TableOverrides = (theme: Theme): Overrides => {
  const tableHeadBackground =
    theme.palette.type === 'light'
      ? theme.palette.background.b4
      : rgba(theme.palette.background.b3, 0.25);
  const tableHeadBorderColor =
    theme.palette.type === 'light'
      ? theme.palette.border.main
      : rgba(theme.palette.border.main, 0.4);

  return {
    MuiTable: {
      root: {
        backgroundColor: theme.palette.background.default,
      },
    },
    MuiTableRow: {
      root: {
        height: '53px',

        // MUI component uses this selector to set background color,
        // not {hover: {'&:hover': ...}}

        '&$hover:hover': {
          backgroundColor: theme.palette.background.b3,
        },
      },
      head: {
        height: '53px',
      },
    },
    MuiTableCell: {
      root: {
        fontSize: undefined,
        borderBottom: `1px solid ${rgba(theme.palette.border.main, 0.5)}`,
        paddingTop: '12px',
        paddingRight: '16px',
        paddingBottom: '12px',
        paddingLeft: '12px',
      },
      head: {
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightSemiBold,
        backgroundColor: tableHeadBackground,
        borderTop: `1px solid ${tableHeadBorderColor}`,
        borderBottom: `1px solid ${tableHeadBorderColor}`,
      },
      body: {
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    MuiTableSortLabel: {
      root: {
        '&:not($active) $icon': {
          transform: 'none',
        },
      },
      icon: {
        opacity: undefined,
      },
      active: {
        '& $icon': {
          opacity: undefined,
          color: theme.palette.brand.main,
        },
      },
    },
  };
};
