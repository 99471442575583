import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme } from '../styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      outline: 0,
    },
    message: {
      outline: 0,
    },
    action: {
      [theme.breakpoints.up('sm')]: {
        marginTop: 0,
      },
      marginTop: 10,
      marginLeft: 12,
      color: theme.palette.text.primary,
      backgroundColor: 'transparent',
    },
    dismissAction: {
      [theme.breakpoints.up('sm')]: {
        marginTop: 0,
        marginRight: 0,
      },
      marginLeft: 0,
      // Since the dismiss icon is centered within the icon, but the design has it aligned with the beginning of the text,
      // we move the icon up in small breakpoint.
      marginTop: -8,
      marginRight: -8,
    },
  }),
);
