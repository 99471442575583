import * as React from 'react';
import { default as MuiInputLabel, InputLabelProps } from '@material-ui/core/InputLabel';

export const InputLabel = React.forwardRef(
  (props: InputLabelProps, ref: React.Ref<HTMLLabelElement>) => (
    <MuiInputLabel ref={ref} {...props} />
  ),
);

export default InputLabel;
