import * as React from 'react';
import { Button } from '../Button';
import { IButtonProps } from '../Button.types';

export const DefaultButton = React.forwardRef(
  (props: IButtonProps, ref: React.Ref<HTMLButtonElement>) => (
    <Button variant="contained" color="secondary" {...props} ref={ref} />
  ),
);

export default DefaultButton;
