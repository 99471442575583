import { Theme } from '@material-ui/core/styles/createTheme';
import { Overrides } from '@material-ui/core/styles/overrides';

export const OutlinedInputOverrides = (theme: Theme): Overrides => {
  const disabledBackgroundColor =
    theme.palette.type === 'light' ? theme.palette.background.b3 : theme.palette.background.b2;
  const defaultBorderColor =
    theme.palette.type === 'light' ? theme.palette.background.b8 : theme.palette.border.main;
  const selectedHoverBorderColor =
    theme.palette.type === 'light' ? theme.palette.action.active : theme.palette.border.dark;
  const disabledTextColor =
    theme.palette.type === 'light' ? theme.palette.text.disabled : theme.palette.background.b7;

  return {
    MuiOutlinedInput: {
      root: {
        backgroundColor: theme.palette.background.default,
        marginTop: '-30px',
        '&:not($disabled):not($error) $notchedOutline': {
          borderColor: defaultBorderColor,
        },
        '&:not($disabled) $notchedOutline': {
          borderColor: theme.palette.error.main,
        },
        '&$focused:not($disabled) $notchedOutline': {
          borderColor: theme.palette.focus.main,
          boxShadow: `inset 0 0 0 1px ${theme.palette.error.main}`,
        },
        '&$focused': {
          // Needed for high contrast mode
          outline: `2px solid ${theme.palette.highContrastOnly.main}`,
        },
        '&$focused:not($error) $notchedOutline': {
          boxShadow: `inset 0 0 0 1px ${theme.palette.focus.light}`,
          borderColor: theme.palette.focus.main,
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: selectedHoverBorderColor,
        },
        '& $notchedOutline': {
          borderColor: undefined,
          // This provides the same visual alignment as the default border variant
          top: '-6px',
          left: '-1px',
          right: '-1px',
          bottom: '-1px',
        },
        '& $notchedOutline > legend': {
          // match default value from MUI and bypass rem conversion
          maxWidth: '0.01keep-px',
        },
        '&$focused $notchedOutline': {
          borderWidth: undefined,
          borderColor: undefined,
        },
        '&$disabled': {
          backgroundColor: disabledBackgroundColor,
          color: disabledTextColor,
          '& $notchedOutline': {
            borderColor: theme.palette.border.main,
          },
        },
        '&$adornedStart': {
          paddingLeft: undefined,
          '& > div': {
            color: theme.palette.text.secondary,
          },
        },
        '&$adornedEnd': {
          paddingRight: undefined,
          '& > div': {
            color: theme.palette.text.secondary,
          },
        },
        '&$error': {
          borderColor: theme.palette.error.main,
        },
      },
      input: {
        padding: undefined,
      },
      inputMultiline: {
        padding: undefined,
      },
    },
  };
};
