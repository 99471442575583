import * as React from 'react';
import {
  createStyles,
  DefaultButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  PrimaryButton,
  Radio,
  RadioGroup,
  TextField,
  Theme,
} from '@bb-ui/react-library';
import { FieldsetProps, LegendProps } from "@bb-ui/react-library/dist/components/RadioGroup/RadioGroup.types";
import { FeatureFlagVisibility } from "hooks/common/common.types";
import {
  FeatureFlagDefinitionData,
  FeatureFlagPossibleValuesOption,
  FeatureFlagProductVersion,
  FeatureFlagReleaseVersion,
} from "hooks/feature-flag-definition/use-feature-flag-definitions.types";
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { MenuItem } from '@bb-ui/react-library/dist/components/MenuItem';
import { getBundle, ILocaleProps } from "../../lib/locales";

export const useStyles = makeStyles((theme: Theme) => createStyles({
  dialogTitle: {
    alignItems: 'start',
  },
  section: {
    marginTop: theme.spacing(2),
  },
  sectionDescription: {
    marginLeft: theme.spacing(3.25),
  },
  metadataInfoIcon: {
    position: 'relative',
    top: theme.spacing(2),
    left: theme.spacing(1),
  },
  sectionWarning: {
    marginBottom: theme.spacing(1),
    color: '#e86826',
  },
  radioButton: {
    marginLeft: theme.spacing(-1),
  },
  releaseVersionNote: {
    marginTop: theme.spacing(0.75),
    marginLeft: theme.spacing(3.25),
  },
  releaseProductTypeInput: {
    marginTop: theme.spacing(1),
  },
  releaseVersionNumberInput: {
    marginTop: theme.spacing(1),
  },
}));

export interface FeatureFlagEditDialogProps extends ILocaleProps {
  isDialogOpen: boolean;
  dialogToggle: (value: boolean) => void;
  flagDefinition: FeatureFlagDefinitionData;
  updateFeatureFlagDefinition: (flagKey: string, defaultValue?: string, clearFlagValues?: boolean, visibility?: FeatureFlagVisibility, releaseVersion?: FeatureFlagReleaseVersion | null) => void;
}

export const FeatureFlagEditDialog: React.FC<FeatureFlagEditDialogProps> = (props) => {
  const classes = useStyles(props);
  const Fieldset = FormControl as React.ForwardRefExoticComponent<FieldsetProps>;
  const Legend = FormLabel as React.ForwardRefExoticComponent<LegendProps>;
  const { flagDefinition, isDialogOpen, dialogToggle, updateFeatureFlagDefinition, locale } = props;
  const bundle = getBundle(locale);

  const NO_PRODUCT_TYPE = 'None';
  const NO_PRODUCT_VERSION = '';
  const NO_ERROR = '';

  function formatReleaseVersionNumber(flagDefinition: FeatureFlagDefinitionData) {
    if (!flagDefinition.releaseVersion) {
      return NO_PRODUCT_VERSION;
    }
    const v = flagDefinition.releaseVersion.version;
    return `${v.major}.${v.minor}.${v.patch}`;
  }

  function parseReleaseVersionNumber(version?: string): FeatureFlagProductVersion | undefined {
    if (!version) {
      return;
    }

    const match = version.match(/^(\d+)\.(\d+)\.(\d+)$/);
    if (!match) {
      throw new Error("Invalid version number");
    }

    const [, major, minor, patch] = match.map(Number);
    return { major, minor, patch };
  }

  function validateReleaseVersion(productType: string, productVersion: string): [string, FeatureFlagReleaseVersion | null] {
    let error = NO_ERROR;
    let releaseVersion: FeatureFlagReleaseVersion | null = null;

    if (productType !== NO_PRODUCT_TYPE) {
      try {
        const versionNumber = parseReleaseVersionNumber(productVersion);
        if (!versionNumber) {
          error = bundle.flagDefinitionEditDialogueProductVersionRequired;
        } else {
          releaseVersion = {
            productType,
            version: versionNumber,
          };
        }
      } catch {
        error = bundle.flagDefinitionEditDialogueProductVersionInvalid;
      }
    }

    return [error, releaseVersion];
  }

  const initialDefaultValue = flagDefinition.defaultValue;
  const initialClientAdminPermission = flagDefinition.visibility.visible ? "edit" : "none";
  const initialClearFlagValues = "disabled";
  const initialReleaseProductType = flagDefinition.releaseVersion?.productType || NO_PRODUCT_TYPE;
  const initialReleaseProductVersion = formatReleaseVersionNumber(flagDefinition);
  const [initialReleaseProductVersionError] = validateReleaseVersion(initialReleaseProductType, initialReleaseProductVersion);

  const [flagNewDefaultValue, setFlagNewDefaultValue] = React.useState<string>(initialDefaultValue);
  const [clientAdminPermission, setClientAdminPermission] = React.useState<string>(initialClientAdminPermission);
  const [clearFlagValues, setClearFlagValues] = React.useState<string>(initialClearFlagValues);
  const [releaseProductType, setReleaseProductType] = React.useState<string>(initialReleaseProductType);
  const [releaseProductVersion, setReleaseProductVersion] = React.useState<string>(initialReleaseProductVersion);
  const [releaseProductVersionError, setReleaseProductVersionError] = React.useState<string>(initialReleaseProductVersionError);

  const { possibleValues } = flagDefinition;

  function handleOnClose() {
    dialogToggle(false);

    // Reset defaults in case dialog is opened again
    setFlagNewDefaultValue(initialDefaultValue);
    setClientAdminPermission(initialClientAdminPermission);
    setClearFlagValues(initialClearFlagValues);
    setReleaseProductType(initialReleaseProductType);
    setReleaseProductVersion(initialReleaseProductVersion);
    setReleaseProductVersionError(initialReleaseProductVersionError);
  }

  function handleDefaultValueChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFlagNewDefaultValue(event.target.value);
  }

  function handleClientAdminPermissionChange(event: React.ChangeEvent<HTMLInputElement>) {
    setClientAdminPermission(event.target.value);
  }

  function handleClearFlagValuesChange(event: React.ChangeEvent<HTMLInputElement>) {
    setClearFlagValues(event.target.value);
  }

  function handleReleaseProductTypeChange(event: any) {
    const updatedProductType = event.target.value;
    if (!updatedProductType) {
      // Clicks outside the dropdown will send an undefined value; ignore these
      return;
    }
    setReleaseProductType(updatedProductType);
  }

  function handleReleaseProductVersionChange(event: any) {
    setReleaseProductVersion(event.target.value);
  }

  function handleOnSubmit() {
    const clearValues = clearFlagValues === "enabled";
    const newVisibleSetting = clientAdminPermission === "edit";
    const visibilityPayload: FeatureFlagVisibility = {
      visible: newVisibleSetting,
    };
    // If global visibility isn't changing, preserve criteria.
    if (flagDefinition.visibility.criteria && flagDefinition.visibility.visible === newVisibleSetting) {
      visibilityPayload.criteria = flagDefinition.visibility.criteria;
    }

    // Validate release version before allowing submit
    const [releaseVersionError, releaseVersion] = validateReleaseVersion(releaseProductType, releaseProductVersion);
    setReleaseProductVersionError(releaseVersionError);
    if (releaseVersionError) {
      return;
    }

    updateFeatureFlagDefinition(flagDefinition.flagKey, flagNewDefaultValue, clearValues, visibilityPayload, releaseVersion);
    dialogToggle(false);
  }

  const defaultValueOptions = (
    <Fieldset>
      <Legend />
      <RadioGroup
        hasCustomLegend
        onChange={handleDefaultValueChange}
        value={flagNewDefaultValue}
        aria-label={bundle.flagDefinitionDefaultValueAriaLabel}
        name="default-value-selection"
      >
        {possibleValues.type.toLowerCase() === "list" &&
          possibleValues.options?.map((option: FeatureFlagPossibleValuesOption) => {
            const value = option.value.toLowerCase();
            return (
              <FormControlLabel className={classes.radioButton} key={value} value={value} control={<Radio />} label={value} />
            );
          })
        }
        {possibleValues.type.toLowerCase() !== "list" &&
          <>
            <FormControlLabel className={classes.radioButton} value="false" control={<Radio />} label={bundle.off} />
            <FormControlLabel className={classes.radioButton} value="true" control={<Radio />} label={bundle.on} />
          </>
        }
      </RadioGroup>
    </Fieldset>
  );

  const overrideValuesOptions = (
    <Fieldset>
      <Legend />
      <RadioGroup
        hasCustomLegend
        onChange={handleClearFlagValuesChange}
        value={clearFlagValues}
        aria-label={bundle.flagDefinitionOverrideAriaLabel}
        name="override-values-selection"
      >
        <FormControlLabel className={classes.radioButton} value="disabled" control={<Radio />} label={bundle.no} data-testid="flag-definition-edit-dialog-override-disable" />
        <FormControlLabel className={classes.radioButton} value="enabled" control={<Radio />} label={bundle.yes} data-testid="flag-definition-edit-dialog-override-enable" />
      </RadioGroup>
    </Fieldset>
  );

  const clientPermissionOptions = (
    <Fieldset>
      <Legend />
      <RadioGroup
        hasCustomLegend
        onChange={handleClientAdminPermissionChange}
        value={clientAdminPermission}
        aria-label={bundle.flagDefinitionClientPermissionsAriaLabel}
        name="client-permission-selection"
      >
        <FormControlLabel className={classes.radioButton} value="none" control={<Radio />} label={bundle.none} />
        <FormControlLabel className={classes.radioButton} value="edit" control={<Radio />} label={bundle.canEdit} />
      </RadioGroup>
    </Fieldset>
  );

  const releaseVersionOptions = (
    <>
      <Typography variant="h4" className={classes.section}> {bundle.releaseVersion} </Typography>
      <Typography variant="body2" color="textSecondary" className={classes.releaseVersionNote}>
        {bundle.flagDefinitionEditDialogueReleaseVersionNote}
      </Typography>

      <TextField
        id="release-version-product-type-input"
        className={classes.releaseProductTypeInput}
        select
        onClick={handleReleaseProductTypeChange}
        label={bundle.flagDefinitionEditDialogueProductType}
        value={releaseProductType}
        fullWidth
      >
        <MenuItem button key={NO_PRODUCT_TYPE} value={NO_PRODUCT_TYPE}>
          {bundle.flagDefinitionEditDialogueProductTypeNone}
        </MenuItem>
        <MenuItem button key="Learn" value="Learn">
          {bundle.flagDefinitionEditDialogueProductTypeLearn}
        </MenuItem>
        <MenuItem button key="Student" value="Student">
          {bundle.flagDefinitionEditDialogueProductTypeStudent}
        </MenuItem>
        <MenuItem button key="Portal" value="Portal">
          {bundle.flagDefinitionEditDialogueProductTypePortal}
        </MenuItem>
      </TextField>

      {releaseProductType !== NO_PRODUCT_TYPE &&
        <TextField
          id="release-version-number-input"
          className={classes.releaseVersionNumberInput}
          error={releaseProductVersionError !== NO_ERROR}
          helperText={releaseProductVersionError}
          onChange={handleReleaseProductVersionChange}
          label={bundle.flagDefinitionEditDialogueProductVersion}
          value={releaseProductVersion}
          placeholder="3900.50.1"
          fullWidth
        />}
    </>
  );

  const flagDefinitionLabel = flagDefinition.labels.find((element: any) => element.locale.toLowerCase() === "en_us")?.label;

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleOnClose}
      aria-label={bundle.featureFlagDefEditDialogueAriaLabel}
      data-testid="flag-definition-edit-dialog"
    >
      <DialogTitle onClose={handleOnClose} className={classes.dialogTitle} aria-label={bundle.featureFlagDefEditDialogueTitleAriaLabel}>
        {bundle.featureFlagDefEditDialogueTitle.replace('{flagName}', flagDefinitionLabel ?? '')}
      </DialogTitle>
      <DialogContent>
        <Typography variant="h4" className={classes.section}> {bundle.globalStatus} </Typography>
        <div id="defaultValueControl" data-testid="flag-definition-default-value-control">
          {defaultValueOptions}
        </div>

        <Typography variant="h4" className={classes.section}> {bundle.flagDefinitionEditDialogueOverrideLabel} </Typography>
        <div id="overrideValuesControl" data-testid="flag-definition-override-values-control">
          {overrideValuesOptions}
        </div>
        {clearFlagValues === "enabled" &&
          <Typography data-testid="flag-definition-value-warning" variant="body2" className={classes.sectionWarning}>
            {bundle.featureFlagDefEditDialogueDefaultValueWarning.replace('{flagName}', flagDefinitionLabel ?? '')}
          </Typography>
        }

        <hr className={classes.section} />
        <div id="visibilityLabel">
          <Typography variant="h4" className={classes.section}> {bundle.globalPermissions} </Typography>
        </div>
        <div id="permissionControl" data-testid="flag-definition-permission-control">
          {clientPermissionOptions}
        </div>
        <Typography variant="body2" color="textSecondary" className={classes.sectionDescription}>
          {bundle.flagDefinitionEditDialogueClientPermNote}
        </Typography>

        {possibleValues.type.toLowerCase() === "boolean" &&
        <>
          <hr className={classes.section} />
          <div id="releaseVersionControl" data-testid="flag-definition-release-version-control">
            {releaseVersionOptions}
          </div>
        </>
        }
      </DialogContent>

      <DialogActions data-testid="flag-definition-buttons">
        <DefaultButton id="cancel-flag-definition-edit-btn" onClick={handleOnClose}>{bundle.cancel}</DefaultButton>
        <PrimaryButton id="submit-flag-definition-edit-btn" onClick={handleOnSubmit}>{bundle.save}</PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};
