import * as React from 'react';
import {
  default as MuiDialogContentText,
  DialogContentTextProps,
} from '@material-ui/core/DialogContentText';

export const DialogContentText = React.forwardRef(
  (props: DialogContentTextProps, ref: React.Ref<HTMLElement>) => (
    <MuiDialogContentText color="inherit" variant="body1" ref={ref} {...props} />
  ),
);

export default DialogContentText;
