import * as React from 'react';
import {
  default as MuiClickAwayListener,
  ClickAwayListenerProps,
} from '@material-ui/core/ClickAwayListener';

export const ClickAwayListener: React.FunctionComponent<ClickAwayListenerProps> = (props) => (
  <MuiClickAwayListener {...props} />
);

export default ClickAwayListener;
