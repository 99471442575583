import * as React from 'react';
import { Key } from 'ts-key-enum';
import {
  ComboboxProps,
  ComboboxOption,
  ComboboxContextProps,
  Components,
  ComboboxGroup,
  OptionsOrGroups,
  ComboboxMultiProps,
  OnChangeData,
  ComboboxSingleProps,
} from './Combobox.types';

export const ComboboxContext = React.createContext<ComboboxContextProps<any, any> | null>(null);

export function useComboboxContext<
  Option extends ComboboxOption,
  Group extends ComboboxGroup<Option>,
>() {
  const context = React.useContext<ComboboxContextProps<Option, Group>>(
    ComboboxContext as unknown as React.Context<ComboboxContextProps<Option, Group>>,
  );
  if (!context) {
    throw new Error('useComboboxContext must be used within ComboboxContextProvider.');
  }
  return context;
}

const defaultComboboxProps = {
  isSearchable: true,
  closeOnSelect: true,
  showSelectedOptions: true,
};

function defaultSearchFilter<Option extends ComboboxOption>(option: Option, searchString: string) {
  if (typeof option.label === 'string')
    return option.label.toLowerCase().includes(searchString.toLowerCase());
  return option.searchLabel!.toLowerCase().includes(searchString.toLowerCase());
}

export function ComboboxContextProvider<
  Option extends ComboboxOption,
  Group extends ComboboxGroup<Option>,
>(props: React.PropsWithChildren<ComboboxProps<Option, Group>>) {
  const comboboxProps = { ...defaultComboboxProps, ...props };
  const {
    options,
    value: controlledValue,
    children,
    closeOnSelect,
    components,
    defaultValue,
    inputValue,
    disabled,
    isSearchable,
    onBlur,
    onChange: onChangeProp,
    onClick,
    onFocus,
    onKeyDown,
    onSearch,
    searchFilter: providedSearchFilter,
    strings,
    autoFocus,
    showSelectedOptions,
    showOnlyFilteredResults,
  } = comboboxProps;
  const { isMulti } = comboboxProps as ComboboxMultiProps<Option>;
  const {
    announceOptionSelected,
    announceOptionDeselected,
    announceValueCleared,
    announceSearchResults,
  } = strings;
  const containerRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const searchInputRef = React.useRef<HTMLInputElement>(null);
  const valueRefs = React.useRef([]);
  const [filteredOptions, setFilteredOptions] = React.useState<OptionsOrGroups<Option, Group>>(
    options || [],
  );
  const [listboxHasFocus, setListboxHasFocus] = React.useState(false);
  const [searchString, setSearchString] = React.useState<string | undefined>();
  const [value, setValue] = React.useState<Option[]>([]);
  const [inputHasFocus, setInputHasFocus] = React.useState(autoFocus ?? false);
  const [menuIsOpen, setMenuIsOpen] = React.useState<boolean | undefined>();
  const [offScreenAnnouncement, setOffScreenAnnouncement] = React.useState('');
  const [hasFocusWithin, setHasFocusWithin] = React.useState(false);
  const searchFilter = providedSearchFilter ?? defaultSearchFilter;
  const prevSearchString = React.useRef(searchString);
  const isOptionSelected = React.useCallback(
    (option: Option) => Boolean(value.find(({ value }) => value === option.value)),
    [value],
  );
  const formatValue = React.useCallback(
    (value: Option | Option[]) => (Array.isArray(value) ? value : [value]),
    [],
  );

  const filterOptionOrGroup = React.useCallback(
    (optionOrGroup: Option | Group, searchString: string) => {
      if ('options' in optionOrGroup) {
        const groupOptions = optionOrGroup.options.filter(
          (option) =>
            (showSelectedOptions || (!showSelectedOptions && !isOptionSelected(option))) &&
            searchFilter(option, searchString),
        );

        return groupOptions.length ? { ...optionOrGroup, options: groupOptions } : [];
      }

      return searchFilter(optionOrGroup, searchString) ? optionOrGroup : [];
    },
    [searchFilter, showSelectedOptions, isOptionSelected],
  );

  const handleSearch = React.useCallback(() => {
    if (searchString?.length) {
      const filtered = options?.flatMap((optionOrGroup) =>
        filterOptionOrGroup(optionOrGroup, searchString),
      );

      prevSearchString.current = searchString;

      setFilteredOptions(filtered ?? []);

      if (filtered) {
        setOffScreenAnnouncement(announceSearchResults(filtered.length, searchString));
      }
    } else if (prevSearchString.current && prevSearchString.current !== searchString) {
      setFilteredOptions(options ?? []);
      setOffScreenAnnouncement('');
      prevSearchString.current = '';
    }
  }, [searchString, options, announceSearchResults, filterOptionOrGroup]);

  function clearSearchValue() {
    if (searchInputRef.current) {
      searchInputRef.current.value = '';
    }
    setSearchString('');
  }

  function focusInput() {
    setInputHasFocus(true);
    setListboxHasFocus(false);
    if (inputRef.current) {
      inputRef.current?.focus();
    }
  }

  function focusSearch() {
    setListboxHasFocus(false);
    searchInputRef.current?.focus();
  }

  function focusListbox() {
    setListboxHasFocus(true);
  }

  function handleOnChange(value: Option[], data: OnChangeData<Option>) {
    if (onChangeProp) {
      /**
       * If the onChange prop exists, redefine with proper type casting
       * and provide corresponding value format.
       */
      if (isMulti) {
        const onChange = onChangeProp as ComboboxMultiProps<Option>['onChange'];

        onChange!(value, data);
      } else {
        const onChange = onChangeProp as ComboboxSingleProps<Option>['onChange'];

        onChange!(value[0] ?? null, data);
      }
    }
  }

  function selectOption(selectOption: Option, preventMenuClose?: boolean) {
    const newValue = isMulti ? [...value, selectOption] : [selectOption];

    setValue(newValue);
    setOffScreenAnnouncement(announceOptionSelected(selectOption));

    handleOnChange(newValue, { action: 'select-option', option: selectOption });

    if (isSearchable) {
      clearSearchValue();
    }

    if (closeOnSelect && !preventMenuClose) {
      setMenuIsOpen(false);
    }
  }

  function deselectOption(deselectOption: Option, preventMenuClose?: boolean) {
    const newValue = isMulti ? value.filter((value) => value.value !== deselectOption.value) : [];

    setValue(newValue);
    setOffScreenAnnouncement(announceOptionDeselected(deselectOption));

    handleOnChange(newValue, { action: 'deselect-option', option: deselectOption });

    if (isSearchable) {
      clearSearchValue();
    }

    if (closeOnSelect && !preventMenuClose) {
      setMenuIsOpen(false);
    }
  }

  function clearValue() {
    const newValue: Option[] = [];

    setValue(newValue);
    setOffScreenAnnouncement(announceValueCleared);
    focusInput();

    if (isSearchable) {
      clearSearchValue();
    }

    handleOnChange(newValue, { action: 'clear' });
  }

  function handleOnClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (inputHasFocus) {
      setMenuIsOpen((isOpen) => !isOpen);
    }

    if (onClick) {
      onClick(event);
    }
  }

  function handleOnKeyDown(event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) {
    switch (event.key) {
      case Key.ArrowDown:
      case Key.Enter:
      case ' ': // Spacebar
        if (!menuIsOpen) {
          setMenuIsOpen(true);

          if (isSearchable) {
            focusSearch();
          } else {
            setListboxHasFocus(true);
          }
        }
        event.preventDefault(); // prevent page scroll
        break;
    }

    if (onKeyDown) {
      onKeyDown(event);
    }
  }

  function handleOnFocus(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (!disabled) {
      setInputHasFocus(true);
    }

    if (onFocus) {
      onFocus(event);
    }
  }

  function handleOnBlur(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setInputHasFocus(false);

    if (onBlur) {
      onBlur(event);
    }
  }

  function handleOnSearch(searchValue: string) {
    if (isSearchable) {
      setSearchString(searchValue);

      if (!menuIsOpen) {
        setMenuIsOpen(true);
      }
    }
  }

  function handleSearchOnKeyDown(
    event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) {
    switch (event.key) {
      case Key.ArrowDown:
        event.preventDefault(); // prevent page scroll
        setListboxHasFocus(true);
        break;
      case Key.Escape:
        setMenuIsOpen(false);
        clearSearchValue();
        focusInput();
        event.preventDefault();
        break;
      case Key.Tab:
        if (event.shiftKey) {
          setMenuIsOpen(false);
          focusInput();
        }
        break;
    }
  }

  const handleMenuToggle = React.useCallback(() => {
    if (menuIsOpen && !isSearchable) {
      // Focus menu item when not searchable
      focusListbox();
    }

    if (!menuIsOpen && hasFocusWithin && !inputHasFocus && !disabled) {
      focusInput();
    }
  }, [menuIsOpen, isSearchable, hasFocusWithin, inputHasFocus, disabled]);

  React.useEffect(
    () => {
      handleMenuToggle();
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */ // handleMenuToggle left out of dependency array
    [menuIsOpen],
  );

  React.useEffect(() => {
    if (typeof searchString === 'string') {
      handleSearch();

      if (onSearch) {
        onSearch(searchString);
      }
    }
  }, [searchString, handleSearch, onSearch]);

  React.useEffect(() => {
    if (controlledValue || defaultValue) {
      setValue(formatValue(controlledValue ?? defaultValue ?? []));
    }
  }, [controlledValue, defaultValue, setValue, formatValue]);

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = inputValue || '';
    }
  }, [inputValue]);

  React.useEffect(() => {
    if (options && !searchString) {
      setFilteredOptions(showOnlyFilteredResults ? [] : options);
    }
  }, [options, searchString, showOnlyFilteredResults]);

  const context: ComboboxContextProps<Option, Group> = {
    filteredOptions,
    containerRef,
    inputRef,
    searchInputRef,
    valueRefs,
    handleOnClick,
    handleOnKeyDown,
    handleOnFocus,
    handleOnBlur,
    handleOnSearch,
    handleSearchOnKeyDown,
    value,
    selectOption,
    deselectOption,
    clearValue,
    hasValue: Boolean(value.length),
    isOptionSelected,
    focusInput,
    focusSearch,
    focusListbox,
    setHasFocusWithin,
    menuIsOpen: Boolean(menuIsOpen),
    setMenuIsOpen,
    inputHasFocus,
    listboxHasFocus,
    setListboxHasFocus,
    searchString,
    components: components as Components<Option, Group>,
    offScreenAnnouncement,
    comboboxProps,
    hasFocusWithin,
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  return <ComboboxContext.Provider value={{ ...context }}>{children}</ComboboxContext.Provider>;
}

export default ComboboxContextProvider;
