import * as React from 'react';
import { createSvgIcon, IconDirectionality } from '..';

export const TriangleDown = createSvgIcon(
  {
    [IconDirectionality.All]: (
      <>
        <path fill="currentColor" stroke="transparent" d="M12.5 5h-9L8 11l4.5-6z" />
      </>
    ),
  },
  'small',
);
