import * as React from 'react';
import { default as MuiSlider, SliderProps as MUISliderProps } from '@material-ui/core/Slider';

/**
 * Temporary type that overrides the onChange value to get around
 * https://github.com/mui-org/material-ui/issues/20191.
 *
 * TODO: Remove after update to major version 5.x
 */
export type SliderProps = Omit<MUISliderProps, 'onChange'> & {
  onChange?: ((event: React.ChangeEvent<{}>, value: number | number[]) => void) | undefined;
};

export const Slider = React.forwardRef((props: SliderProps, ref: React.Ref<HTMLSpanElement>) => {
  return <MuiSlider {...props} ref={ref} />;
});

export default Slider;
