import * as React from 'react';
import { objectToQueryString } from '../utils/objectToQueryString';

/**
 * Note: Initial release supports Cognito authentication through the bb-sign-in-cognito-ui application.
 * Authentication is currently based on the redirect auth flow which provides the auth tokens
 * through the query hash.
 *
 * Once expired (or the user signs out), tokens will need to be retrieved through the same redirect process.
 *
 * When "silent auth" is implemented, `error` and `loading` will be more useful from a consumer perspective.
 * Including initially for better alignment with the Auth0 based consumer API.
 */

export type AppState = Record<string, unknown>;

export interface SignInRedirectOptions {
  appState?: AppState;
}

export interface CognitoAuthParams {
  /**
   * Cognito provided access token.
   */
  accessToken?: string | null;
  /**
   * Saved state provided in signInRedirect, returned after the user is authenticated.
   */
  appState?: AppState;
  /**
   * Called after redirect to exchange authentication result.
   */
  handleRedirectCallback: () => void;
  /**
   * Any error during authentication.
   */
  error?: Error;
  /**
   * Cognito provided ID token.
   */
  idToken?: string | null;
  /**
   * True when the user is authenticated.
   */
  isAuthenticated: boolean;
  /**
   * If authentication is in a loading state.
   */
  loading?: boolean;
  /**
   * Cognito provided refresh token.
   */
  refreshToken?: string | null;
  /**
   * Function call to initialize sign-in via redirect to the bb-sign-in-cognito-ui application.
   */
  signInRedirect: (options?: SignInRedirectOptions) => void;
  /**
   * Function call to initialize sign-out via redirect to the bb-sign-in-cognito-ui application.
   */
  signOutRedirect: () => void;
}

export interface CognitoAuthProviderProps {
  /**
   * Identity provider ID.
   * If provided (and more than one cognito based IDP is available), IDP selection is skipped within the bb-sign-in-cognito-ui application.
   * If not provided (and more than one cognito based IDP is available), IDP selection will occur within the bb-sign-in-cognito-ui application.
   */
  idpId?: string;
  /**
   * URL of the consumer application where the user should be redirected once authenticated.
   */
  returnUrl: string;
  /**
   * URL of the consumer application where the user should be redirected once signed out.
   * Defaults to returnUrl if not provided.
   */
  signOutReturnUrl?: string;
  /**
   * URL of the bb-sign-in-cognito-ui application.
   */
  signInUrl: string;
  /**
   * ID of the tenant.
   */
  tenantId: string;
}

export const CognitoAuthContext = React.createContext<CognitoAuthParams | null>(null);

export const CognitoAuthProvider = (props: React.PropsWithChildren<CognitoAuthProviderProps>) => {
  const { children, idpId, returnUrl, signInUrl, signOutReturnUrl, tenantId } = props;
  const [accessToken, setAccessToken] = React.useState<string | null>();
  const [idToken, setIdToken] = React.useState<string | null>();
  const [refreshToken, setRefreshToken] = React.useState<string | null>();
  const [appState, setAppState] = React.useState<AppState>();
  const [loading, setLoading] = React.useState<boolean>();
  const [error, setError] = React.useState<Error>();
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(false);

  function handleRedirectCallback() {
    const hash = decodeURIComponent(window.location.hash);
    const hashParams = new URLSearchParams(hash.replace('#', ''));
    const appStateHash = hashParams.get('app_state');

    setLoading(true);

    const { accessToken, idToken, refreshToken, appState } = {
      accessToken: hashParams.get('access_token'),
      appState: appStateHash && JSON.parse(window.atob(appStateHash)),
      idToken: hashParams.get('id_token'),
      refreshToken: hashParams.get('refresh_token'),
    };

    if (!accessToken || !idToken || !refreshToken) {
      setError(new Error('Auth tokens not successfully acquired.'));
    }

    setAccessToken(accessToken);
    setAppState(appState);
    setIdToken(idToken);
    setRefreshToken(refreshToken);

    // Remove the query params from view.
    window.history.replaceState({}, document.title, window.location.pathname);

    return { accessToken, appState, idToken, refreshToken };
  }

  function handleSignInRedirect(options?: SignInRedirectOptions) {
    const encodedAppState = options?.appState && window.btoa(JSON.stringify(options?.appState));

    window.location.assign(
      `${signInUrl}/?${objectToQueryString({
        tenantId,
        idpId,
        returnUrl,
        appState: encodedAppState,
      })}`
    );
  }

  function handleSignOutRedirect() {
    window.location.assign(
      `${signInUrl}/sign-out?${objectToQueryString({
        tenantId,
        returnUrl: signOutReturnUrl || returnUrl,
      })}/sign-out`
    );
  }

  React.useEffect(() => {
    if (window.location.pathname.includes('/callback')) {
      handleRedirectCallback();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (accessToken && idToken && refreshToken) {
      setLoading(false);
      setIsAuthenticated(true);
    }
  }, [accessToken, idToken, refreshToken]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const context = {
    accessToken,
    appState,
    error,
    handleRedirectCallback,
    idToken,
    isAuthenticated,
    loading,
    refreshToken,
    signInRedirect: handleSignInRedirect,
    signOutRedirect: handleSignOutRedirect,
  };

  return <CognitoAuthContext.Provider value={context}>{children}</CognitoAuthContext.Provider>;
};

export default CognitoAuthProvider;
