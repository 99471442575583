import * as React from 'react';
import { createSvgIcon, IconDirectionality } from '..';

export const ChevronDown = createSvgIcon(
  {
    [IconDirectionality.All]: (
      <>
        <path
          d="M1 4L8 11L15 4"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
  },
  'small',
);
