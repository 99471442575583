/**
 * Utility: Takes an object and converts it to a formatted query string.
 *
 * @param obj { key1: "value1", key2: "value2", key3: undefined }
 * @returns 'key1=value1&key2=value2'
 */
export function objectToQueryString(obj: { [key: string]: string | undefined }) {
  const searchParams = new URLSearchParams();

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];

      if (value) {
        searchParams.set(key, value);
      }
    }
  }

  return decodeURIComponent(searchParams.toString());
}
