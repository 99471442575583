import { Overrides } from '@material-ui/core/styles/overrides';

export const ExpansionPanelDetailsOverrides = (): Overrides => ({
  MuiExpansionPanelDetails: {
    root: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
});
