import * as React from 'react';
import { makeStyles, createStyles } from '../../styles';
import { SearchBox } from '../../SearchBox';
import { useComboboxContext } from '../Combobox.context';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      pointerEvents: 'none',
    },
    label: {
      transform: 'translate(40px, 9px) scale(0.875)',
    },
    labelShrink: {
      opacity: 0,
    },
    input: {
      borderRadius: '1px 1px 0px 0px',
    },
  }),
);

export const Search = () => {
  const {
    searchInputRef,
    handleOnSearch,
    handleSearchOnKeyDown,
    setListboxHasFocus,
    searchString,
    comboboxProps,
  } = useComboboxContext();
  const { id, strings, autoComplete, SearchProps: searchProps } = comboboxProps;
  const { searchLabel } = strings;
  const classes = useStyles();

  return (
    <SearchBox
      inputRef={searchInputRef}
      inputId={`${id}-search-box`}
      label={searchLabel}
      onChange={handleOnSearch}
      onKeyDown={handleSearchOnKeyDown}
      onEscape={handleSearchOnKeyDown}
      InputProps={{
        classes: {
          root: classes.input,
        },
      }}
      InputLabelProps={{
        classes: {
          root: classes.label,
          shrink: classes.labelShrink,
        },
        shrink: Boolean(searchString?.length),
      }}
      TextFieldProps={{
        autoComplete,
        autoFocus: true,
        onFocus: () => setListboxHasFocus(false),
      }}
      hideIconOnFocus={false}
      value={searchString}
      {...searchProps}
    />
  );
};

export default Search;
