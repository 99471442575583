// This redirects to the route that a user was trying to access before they were
// asked to log in, which is stored in the `appState` param that the auth
// context automatically creates when loginWithRedirect() is called.
//
// This never renders any content.

import React from 'react';
import { useAuth0Context } from './Auth0Context';

export interface Auth0CallbackHandlerProps {
  onRedirect: (path: string) => void;
}

export const Auth0CallbackHandler: React.FunctionComponent<
Auth0CallbackHandlerProps
> = ({ onRedirect }) => {
  const { appState, loading } = useAuth0Context();
  const [redirected, setRedirected] = React.useState(false);

  React.useEffect(() => {
    if (redirected) {
      return;
    }

    if (!loading && appState && appState.returnPath) {
      onRedirect(appState.returnPath);
      setRedirected(true);
    }
  }, [appState, loading, onRedirect, redirected]);

  return null;
};
