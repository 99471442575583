import { Theme } from '@material-ui/core/styles/createTheme';
import { Overrides } from '@material-ui/core/styles/overrides';
import { rgba } from '../styles/utilities';

export const ListItemOverrides = (theme: Theme): Overrides => ({
  MuiListItem: {
    root: {
      paddingTop: '12px',
      paddingBottom: '12px',
      '&$selected, &$selected:hover, &$selected:focus': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
    dense: {
      paddingTop: '7px',
      paddingBottom: '7px',
    },
    gutters: {
      paddingLeft: '12px',
      paddingRight: '12px',
    },
    button: {
      '&:hover': {
        backgroundColor: rgba(theme.palette.primary.main, 0.06), // #f0f0f0 on light, #333 on dark

        // see <ListItemText />

        '& .primary-text': {
          textDecoration: 'underline',
        },
      },
      '&:focus': {
        backgroundColor: undefined,
      },
      '&$focusVisible': {
        backgroundColor: rgba(theme.palette.primary.main, 0.06), // #f0f0f0 on light, #333 on dark
        // inset focus outline
        '&::before': {
          top: 2,
          left: 2,
          right: 2,
          bottom: 2,
        },
      },
    },
  },
});
