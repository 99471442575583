import * as React from 'react';
import { createSvgIcon, IconDirectionality } from '..';

export const Complete = createSvgIcon(
  {
    [IconDirectionality.All]: (
      <>
        <rect
          width="14"
          height="14"
          x="1"
          y="1"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          rx="7"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M5 8l2 3 4-6"
        />
      </>
    ),
  },
  'small',
);
