import * as React from 'react';

import { makeStyles, createStyles, Theme } from '@bb-ui/react-library';
import { ExpansionPanel } from '@bb-ui/react-library/dist/components/ExpansionPanel';
import { ExpansionPanelDetails } from '@bb-ui/react-library/dist/components/ExpansionPanelDetails';
import { ExpansionPanelSummary } from '@bb-ui/react-library/dist/components/ExpansionPanelSummary';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { Link } from '@bb-ui/react-library/dist/components/Link';
import { FeatureFlagStatusBadge } from "../feature-flag-status-badge/feature-flag-status-badge";
import { FeatureFlagValueIndicator } from "../feature-flag-value-indicator/feature-flag-value-indicator";
import { FeatureFlagLockIndicator } from "../feature-flag-lock-indicator/feature-flag-lock-indicator";
import { FeatureFlagValueSwitch } from "../feature-flag-value-controllers/feature-flag-value-switch";
import { getBundle, ILocaleProps } from "../../lib/locales";

export const useStyles = makeStyles((theme: Theme) => createStyles({
  summaryRoot: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
  },
  summaryContent: {
    margin: 0,
  },
  summaryExpanded: {
    '&$summaryRoot': {
      minHeight: theme.spacing(6),
    },
    '&$summaryContent': {
      margin: 0,
    },
  },
  details: {
    flexDirection: 'column',
    borderBottom: `1px solid ${theme.palette.border.main}`,
  },
  link: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(3),
    display: 'flex',
  },
  description: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(3),
    display: 'flex',
  },
  overridden: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(3),
    display: 'flex',
    fontStyle: 'italic',
  },
}));

export interface IFeatureFlagExpansionPanelProps extends ILocaleProps {
  flagKey: string;
  flagLabel: string;
  flagValue: string;
  flagDescription: string;
  locked: boolean;
  overridden: boolean;
  updateValue: (key: string, value: string) => void;
  documentationUrl?: string;
  expanded?: boolean;
}

export const FeatureFlagExpansionPanel: React.FunctionComponent<IFeatureFlagExpansionPanelProps> = (props) => {
  const classes = useStyles(props);
  const { flagKey, flagLabel, flagValue, flagDescription, locked, overridden, documentationUrl, updateValue, locale, expanded } = props;
  const summary = `${flagKey}-summary`;
  const details = `${flagKey}-details`;
  const bundle = getBundle(locale);

  const [flag, setFlag] = React.useState(flagValue === 'true');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFlag(event.target.checked);
    updateValue(flagKey, String(event.target.checked));
  };

  return (
    <ExpansionPanel
      defaultExpanded={expanded}
      className="react-container"
    >
      <div>
        <ExpansionPanelSummary
          id={ summary }
          aria-controls={ details }
          classes={ { root: classes.summaryRoot, content: classes.summaryContent, expanded: classes.summaryExpanded } }
        >
          <Typography variant="h2" component="h3">{ flagLabel }</Typography>
          <FeatureFlagStatusBadge locale={ locale } />
          { locked &&
            <FeatureFlagLockIndicator locked={ locked } locale={ locale } /> }
          { !locked &&
            <FeatureFlagValueIndicator flagValue={ String(flag) } locale={ locale } /> }
        </ExpansionPanelSummary>
      </div>
      <ExpansionPanelDetails className={ classes.details }>
        <Typography variant="body1" className={ classes.description }>{ flagDescription }</Typography>
        { documentationUrl &&
          <Link href={ documentationUrl } className={ classes.link } target="_blank" rel="noopener noreferrer">{ bundle.learnMore }</Link> }
        <FeatureFlagValueSwitch
          flagKey={ flagKey }
          flagLabel={ flagLabel }
          flagValue={ flag }
          onChange={ handleChange }
          disabled={ locked }
          locale={ locale }
        />
        { overridden &&
          <Typography variant="caption" className={ classes.overridden }> { bundle.overridden } </Typography>}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default FeatureFlagExpansionPanel;
