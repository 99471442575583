import * as React from 'react';
import { default as MuiSvgIcon } from '@material-ui/core/SvgIcon';
import classnames from 'classnames';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createTheme';
import { SvgIconProps } from './SvgIcon.types';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    strokeIcon: {
      fill: 'none',
    },
    directionalIcon: {
      ...(theme.direction === 'rtl'
        ? {
            transform: 'scaleX(-1)',
          }
        : {}),
    },
  }),
);

export const SvgIcon = React.forwardRef((props: SvgIconProps, ref?: React.Ref<SVGSVGElement>) => {
  const {
    fontSize,
    titleAccess,
    viewBox,
    className,
    isColor,
    classes: classesProp,
    isDirectional,
    ...other
  } = props;

  const classes = useStyles(props);
  let viewBoxValue = '0 0 24 24';

  const classNames = classnames(
    isDirectional && classes.directionalIcon,
    !isColor && classes.strokeIcon,
    className,
  );

  if (fontSize === 'small') {
    viewBoxValue = '0 0 16 16';
  } else if (fontSize === 'large') {
    viewBoxValue = '0 0 38 38';
  }

  viewBoxValue = viewBox || viewBoxValue;

  return (
    <MuiSvgIcon
      className={classNames}
      viewBox={viewBoxValue}
      fontSize={fontSize}
      classes={classesProp}
      ref={ref}
      {...other}
      aria-hidden={titleAccess ? undefined : true}
      role={titleAccess ? 'img' : 'presentation'}
      aria-label={titleAccess}
    />
  );
});

export default SvgIcon;
