import * as React from 'react';
import classnames from 'classnames';
import { Props as SelectProps } from 'react-select';
import { Chip } from '../Chip';
import { MenuItem } from '../MenuItem';
import { Paper } from '../Paper';
import { TextField } from '../TextField';
import { Typography } from '../Typography';
import { IconButton } from '../IconButton';
import { SvgIcon } from '../SvgIcon';
import { Clear } from '../../internal/icons/small/Clear';
import { announceTagFocus, announceOptionFocus } from './PickerAccessibility';
import { OptionType } from './Picker.types';
import { Popper } from '../Popper';
import { Tooltip } from '../Tooltip';
import { splitOnFirstOccurrence } from '../../internal/utilities/findOccurrences';

interface IInputComponent {
  inputRef: React.RefObject<HTMLDivElement>;
}

export const inputComponent = ({ inputRef, ...props }: IInputComponent) => (
  <div ref={inputRef} {...props} />
);

export const Control = (props: SelectProps) => {
  const {
    classes,
    floatingLabel,
    inputId,
    labelId,
    isDisabled,
    label,
    textFieldProps,
    containerRef,
  } = props.selectProps;
  const { children, innerProps, isFocused, hasValue } = props;

  return (
    <TextField
      fullWidth
      disabled={isDisabled}
      label={label}
      floatingLabel={floatingLabel}
      InputProps={{
        inputComponent,
        inputProps: {
          children,
          className: classes.input,
          inputRef: containerRef,
          ...innerProps,
        },
      }}
      InputLabelProps={{
        htmlFor: inputId,
        id: labelId,
        shrink: floatingLabel && (isFocused || hasValue),
      }}
      {...textFieldProps}
    />
  );
};

export const HighlightString = (props: SelectProps) => {
  const { classes, inputValue } = props.selectProps;
  const { children, data } = props;

  if (!inputValue) {
    return children;
  }

  function highlight(text: string) {
    const [preMatch, match, postMatch] = splitOnFirstOccurrence(text, inputValue);

    return (
      <span key={`highlight-${data.value}`}>
        {preMatch}
        <span className={classes.menuItemHighlight}>{match}</span>
        {postMatch}
      </span>
    );
  }

  return React.Children.map(React.Children.toArray(children), (child) => {
    if (typeof child === 'string') {
      return highlight(child);
    }
    // account for one layer of nesting
    if (React.isValidElement(child) && child.props.children) {
      return React.Children.map(React.Children.toArray(child.props.children), (innerChild) => {
        if (typeof innerChild === 'string') {
          return highlight(innerChild);
        }
        return innerChild;
      });
    }
    return child;
  });
};

export const Option = (props: SelectProps) => {
  const { classes, setAriaLiveMessage, highlightFilteredMatches } = props.selectProps;
  const { children, innerRef, innerProps, isFocused, isSelected, options, data } = props;

  /*
   * Note about props.data['__isNew__'] (only applies to creatable option):
   * Due to this causing a rerender and react-select reverting the focused option to the previously focused
   * option as opposed to maintaining focus on the creatable option, we're bypassing setting the message to
   * prevent the rerender. This is a temporary solution so that we can maintains keyboard functionality for
   * the creatable option.
   *
   * This will be revisited when https://github.com/JedWatson/react-select/pull/3358 is approved and we can
   * remove our aria live region handling in favor of react-select's built in handling.
   */

  // eslint-disable-next-line no-underscore-dangle
  if (isFocused && !data.__isNew__) {
    setAriaLiveMessage(announceOptionFocus(props.selectProps, data.label, options as OptionType[]));
  }

  return (
    <MenuItem
      button
      role="option"
      selected={isSelected}
      aria-selected={isFocused}
      component="div"
      className={classnames({
        [classes.menuItemFocus]: isFocused,
        [classes.menuItemSelected]: isSelected,
      })}
      innerRef={innerRef}
      {...innerProps}
    >
      {highlightFilteredMatches ? <HighlightString {...props} /> : children}
    </MenuItem>
  );
};

export const Placeholder = (props: SelectProps) => {
  const { classes, floatingLabel } = props.selectProps;
  const { children, innerProps, isFocused } = props;

  return (
    <Typography
      color="textSecondary"
      className={classnames(
        {
          [classes.floatingLabel]: floatingLabel,
          [classes.inputFocused]: isFocused,
        },
        classes.placeholder,
      )}
      {...innerProps}
    >
      {children}
    </Typography>
  );
};

export const SingleValue = (props: SelectProps) => {
  const { classes } = props.selectProps;
  const { children, innerProps } = props;

  return (
    <Typography className={classes.singleValue} noWrap {...innerProps}>
      {children}
    </Typography>
  );
};

export const ValueContainer = (props: SelectProps) => {
  const { classes } = props.selectProps;
  const { children, isMulti } = props;
  return (
    <div className={classnames(classes.valueContainer, { [classes.valueContainerMulti]: isMulti })}>
      {children}
    </div>
  );
};

export const MultiValue = (props: SelectProps) => {
  const { classes, setAriaLiveMessage, value, removeTagAriaLabel, isDisabled } = props.selectProps;
  const { children, isFocused, removeProps } = props;

  if (isFocused) {
    setAriaLiveMessage(announceTagFocus(props.selectProps, children, value));
  }

  return (
    <Chip
      label={children}
      className={classnames(classes.chip, { [classes.chipFocused]: isFocused })}
      onDelete={!isDisabled ? removeProps.onClick : undefined}
      deleteButtonProps={{
        'aria-label': removeTagAriaLabel({ label: children }),
      }}
    />
  );
};

export const Menu = (props: SelectProps) => {
  const { classes, labelId, menuIsOpen, containerRef } = props.selectProps;
  const { children, innerProps } = props;
  const anchorEl = containerRef.current;

  return (
    <Popper
      className={classes.paper}
      open={menuIsOpen}
      anchorEl={anchorEl}
      container={containerRef.current}
      placement="bottom-start"
      role={undefined}
      popperOptions={{ scheduleUpdate: () => true }}
    >
      <Paper role="listbox" aria-labelledby={labelId} variant="dropdown" {...innerProps}>
        {children}
      </Paper>
    </Popper>
  );
};

export const NoOptionsMessage = (props: SelectProps) => {
  const { classes } = props.selectProps;
  const { children, innerProps } = props;

  return (
    <Typography color="textSecondary" className={classes.noOptionsMessage} {...innerProps}>
      {children}
    </Typography>
  );
};

export const DropdownIndicator = (props: SelectProps) => {
  const { classes } = props.selectProps;
  const { innerProps } = props;

  return (
    <SvgIcon className={classes.dropdownIndicator} isColor {...innerProps}>
      <path d="M7 10l5 5 5-5z" />
    </SvgIcon>
  );
};

export const ClearIndicator = (props: SelectProps) => {
  const { classes, selectRef, clearInputAriaLabel, clearInputTooltipLabel } = props.selectProps;
  const { innerProps, clearValue } = props;

  function handleOnClick() {
    clearValue();
    selectRef.current.focus();
  }

  const iconCloseButton = (
    <IconButton
      aria-label={clearInputAriaLabel}
      className={classes.clearIndicator}
      {...innerProps}
      aria-hidden={undefined}
      onClick={handleOnClick}
    >
      <Clear />
    </IconButton>
  );

  if (clearInputTooltipLabel) {
    return (
      <Tooltip
        PopperProps={{
          popperOptions: {
            positionFixed: true,
          },
        }}
        placement="top"
        title={clearInputTooltipLabel}
      >
        {iconCloseButton}
      </Tooltip>
    );
  }
  return <>{iconCloseButton}</>;
};

export const IndicatorSeparator = (props: SelectProps) => {
  const { classes } = props.selectProps;
  const { innerProps } = props;

  return <span className={classes.indicatorSeparator} {...innerProps} />;
};

export const IndicatorsContainer = (props: SelectProps) => {
  const { classes } = props.selectProps;
  const { children, innerProps } = props;

  return (
    <span className={classes.indicatorContainer} {...innerProps}>
      {children}
    </span>
  );
};
