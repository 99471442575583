import { TabProps } from '@material-ui/core/Tab';
import { TooltipProps } from '@material-ui/core/Tooltip';

export enum TabVariant {
  global = 'global',
  section = 'section',
}

export interface ITabProps extends TabProps {
  variant?: TabVariant;
}

export interface IIconTabProps extends ITabProps {
  /**  When the label prop for the tooltip has html or is not a string value,
   * it will require ariaLabel prop to be passed.  Otherwise, the label
   *  prop value will be used as the iconTab label.
   */
  ariaLabel?: string;
  tooltipPlacement?: TooltipProps['placement'];
}
