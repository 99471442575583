import React from 'react';
import { ownerDocument } from '@material-ui/core';
import { Key } from 'ts-key-enum';
import { ListboxKeyboardHandlers } from './Listbox.types';

/**
 * The keyboard focus handling provided by MUI's MenuList component does not include
 * built in support for nested lists (e.g. ul > li > ul > li).
 * Since we rely on the nested list html structure for grouped options, we need to add
 * the keyboard focus handling on our end.
 *
 * The is heavily modeled after MUI MenuList.
 * https://github.com/mui-org/material-ui/blob/v4.x/packages/material-ui/src/MenuList/MenuList.js
 */

// Utility function to return the element as type HTMLElement.
function returnFocusableOption(option: ChildNode | null | undefined) {
  // Type ChildNode could represent a non-focusable element (e.g. svg).
  return option ? (option as HTMLElement) : null;
}

// Utility function to check if the node exists and nodeName is UL.
function nodeIsGroup(node?: ChildNode | null | undefined) {
  return node?.nodeName === 'UL';
}

// Returns the first focusable option of the supplied group.
function firstGroupItem(group: ChildNode): HTMLElement | null {
  const firstOption = returnFocusableOption(group.firstChild?.firstChild);

  if (firstOption && !firstOption?.hasAttribute('tabindex')) {
    return returnFocusableOption(firstOption.nextElementSibling);
  }

  return returnFocusableOption(firstOption);
}

// Returns the last focusable option of the supplied group.
function lastGroupItem(group: ChildNode): HTMLElement | null {
  const lastOption = returnFocusableOption(group.lastChild?.lastChild);

  if (lastOption && !lastOption?.hasAttribute('tabindex')) {
    return returnFocusableOption(lastOption.previousElementSibling);
  }

  return returnFocusableOption(lastOption);
}

// Returns the first option of the supplied list.
function firstItem(list: HTMLUListElement) {
  const optionOrGroup = list.firstChild;

  // When the first child is a group, return the first focusable option.
  if (optionOrGroup && nodeIsGroup(optionOrGroup?.firstChild)) {
    return firstGroupItem(optionOrGroup);
  }

  return returnFocusableOption(optionOrGroup);
}

// Returns the last option of the supplied list.
function lastItem(list: HTMLUListElement) {
  const optionOrGroup = list.lastChild;

  // When the last child is a group, return the last focusable option.
  if (optionOrGroup && nodeIsGroup(optionOrGroup?.lastChild)) {
    return lastGroupItem(optionOrGroup);
  }

  return returnFocusableOption(optionOrGroup);
}

// Returns the next focusable option.
function nextItem(list: HTMLUListElement, item: Element, disableListWrap?: boolean) {
  if (item.nextElementSibling) {
    if (nodeIsGroup(item.nextElementSibling?.firstChild)) {
      return firstGroupItem(item.nextElementSibling);
    }

    return returnFocusableOption(item.nextElementSibling);
  }

  const currentGroup = item.parentElement?.parentElement;
  const next = currentGroup?.nextElementSibling;

  if (next?.firstChild) {
    return nodeIsGroup(next?.firstChild) ? firstGroupItem(next) : returnFocusableOption(next);
  }

  return disableListWrap ? null : firstItem(list);
}

// Returns the previous focusable option.
function previousItem(list: HTMLUListElement, item: Element, disableListWrap?: boolean) {
  if (item.previousElementSibling) {
    if (nodeIsGroup(item.previousElementSibling?.firstChild)) {
      return lastGroupItem(item.previousElementSibling);
    }

    if (item.previousElementSibling.hasAttribute('tabindex')) {
      return returnFocusableOption(item.previousElementSibling);
    }
  }

  const currentGroup = item.parentElement?.parentElement;
  const previous = currentGroup?.previousElementSibling;

  if (previous?.firstChild) {
    return nodeIsGroup(previous?.firstChild)
      ? lastGroupItem(previous)
      : returnFocusableOption(previous);
  }

  return disableListWrap ? null : lastItem(list);
}

export function useListboxHandlers<Option, DefaultOption>({
  hasFocus,
  isMulti,
  isOptionSelected,
  selectOption,
  deselectOption,
  onSpacebar,
  onEnter,
  onEscape,
  onTab,
  updateFocusedOption,
}: ListboxKeyboardHandlers<Option, DefaultOption>) {
  const listRef = React.useRef<HTMLUListElement>(null);
  const containsFocus = React.useRef<boolean>();

  const handleSelectOption = React.useCallback(
    (option: Option | DefaultOption, preventMenuClose?: boolean) => {
      if (isOptionSelected(option)) {
        deselectOption(option, preventMenuClose);
      } else {
        selectOption(option, preventMenuClose);
      }
    },
    [isOptionSelected, deselectOption, selectOption],
  );

  const handleOnMouseUp = React.useCallback(
    (option: Option | DefaultOption) => {
      handleSelectOption(option);
    },
    [handleSelectOption],
  );

  // list item keyboard event
  const handleOnKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLLIElement>, option: Option | DefaultOption) => {
      const list = listRef.current;

      if (!list) {
        return;
      }

      /**
       * https://github.com/mui-org/material-ui/blob/v4.x/packages/material-ui/src/MenuList/MenuList.js#L149
       * type Element - will always be defined since we are in a keydown handler
       * attached to an element. A keydown event is either dispatched to the activeElement
       * or document.body or document.documentElement. Only the first case will
       * trigger this specific handler.
       */
      const currentFocus = ownerDocument(list).activeElement as Element;

      switch (event.key) {
        // Spacebar
        case ' ':
          // Prevent menu close on select when isMulti.
          handleSelectOption(option, isMulti);
          onSpacebar?.(event, option);
          break;
        case Key.Enter:
          // select on enter when single select
          if (!isMulti) {
            handleSelectOption(option, !isMulti);
          }
          onEnter?.(event, option);
          break;
        case Key.ArrowUp: {
          const previousListItem = previousItem(list, currentFocus, false);
          previousListItem?.focus();
          updateFocusedOption?.(previousListItem as HTMLLIElement);
          event.preventDefault(); // prevent page scroll
          break;
        }
        case Key.ArrowDown: {
          const nextListItem = nextItem(list, currentFocus, false);
          nextListItem?.focus();
          updateFocusedOption?.(nextListItem as HTMLLIElement);
          event.preventDefault(); // prevent page scroll
          break;
        }
        case Key.Escape:
          onEscape?.(event, option);
          event.stopPropagation();
          break;
        case Key.Tab:
          onTab?.(event, option);
          break;
      }
    },
    [handleSelectOption, isMulti, onSpacebar, onEnter, onEscape, onTab, updateFocusedOption],
  );

  React.useEffect(() => {
    // Handle initial focus
    if (hasFocus && !containsFocus.current && listRef.current) {
      firstItem(listRef.current)?.focus();
      containsFocus.current = true;
    } else {
      containsFocus.current = false;
    }
  }, [hasFocus]);

  return { listRef, handleOnMouseUp, handleOnKeyDown };
}
