import { Badge } from '@bb-ui/react-library/dist/components/Badge';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { createStyles, makeStyles, Theme } from '@bb-ui/react-library';
import * as React from 'react';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  statusBadge: {
    marginLeft: theme.spacing(2),
    backgroundColor: (props: IFeatureStatusBadgeProps) => {
      if (props.featureStatus === 'true') {
        return theme.palette.success.main;
      }
      return theme.palette.background.b8;
    },
  },
  featureStatus: {
    marginLeft: 'auto',
    marginRight: theme.spacing(1),
    display: 'inline-flex',
  },
}));

export interface IFeatureStatusBadgeProps {
  featureStatus: string;
  label: string;
}

export const FeatureStatusBadge: React.FunctionComponent<IFeatureStatusBadgeProps> = (props: IFeatureStatusBadgeProps) => {
  const classes = useStyles(props);
  const { label } = props;
  return (
    <div id="feature-status-badge" className={ classes.featureStatus }>
      <Badge
        position="inline"
        variant="dot"
        classes={{ badge: classes.statusBadge }}
      />
      <Typography component="span" variant="h4">{ label }</Typography>
    </div>);
};

export default FeatureStatusBadge;
