import * as React from 'react';

import { makeStyles, createStyles, Theme } from '@bb-ui/react-library';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { Lock, Unlock } from "@bb-ui/icons/dist/medium";
import { getBundle, ILocaleProps } from "../../lib/locales";

export const useStyles = makeStyles((theme: Theme) => createStyles({
  lockIndicator: {
    marginLeft: 'auto',
    marginRight: theme.spacing(1),
    display: 'inline-flex',
  },
  lockIndicatorText: {
    marginRight: theme.spacing(1),
  },
}));

export interface IFeatureFlagValueIndicatorProps extends ILocaleProps {
  locked: boolean;
}

export const FeatureFlagLockIndicator: React.FunctionComponent<IFeatureFlagValueIndicatorProps> = (props) => {
  const classes = useStyles(props);
  const { locked, locale } = props;
  const bundle = getBundle(locale);

  let lockIndicatorText = bundle.unlocked;
  let lockIndicatorIcon = <Unlock />;
  if (locked) {
    lockIndicatorText = bundle.locked;
    lockIndicatorIcon = <Lock />;
  }
  return (
    <div id="lock-indicator" className={ classes.lockIndicator }>
      <Typography variant="h4" className={ classes.lockIndicatorText }>{ lockIndicatorText }</Typography>
      {lockIndicatorIcon}
    </div>);
};

export default FeatureFlagLockIndicator;
