import * as React from 'react';
import { default as MuiMenu, MenuProps } from '@material-ui/core/Menu';

export const defaultMenuPlacement: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  getContentAnchorEl: null,
};

export const Menu = React.forwardRef((props: MenuProps, ref: React.Ref<unknown>) => (
  <MuiMenu {...defaultMenuPlacement} ref={ref} {...props} />
));

export default Menu;
