import { JobServiceException } from "../JobServiceException";

export class ExecutionNotFoundException extends JobServiceException {
  public constructor(executionId: string, additionalData?: object) {
    super(
      404,
      "ExecutionNotFoundException",
      `Execution with id ${executionId} not found!`,
      { ...additionalData, executionId }
    );
  }
}
