import * as React from 'react';
import { createSvgIcon, IconDirectionality } from '..';

export const Close = createSvgIcon({
  [IconDirectionality.LeftToRight]: (
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M5 5l14 14M19 5L5 19"
    />
  ),
});
