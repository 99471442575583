// See SwitchThumb.tsx for additional styles. Because we are using a custom
// component for the icon, thumb-related selectors here have no effect.

import { alpha } from '@material-ui/core/styles/colorManipulator';
import { Theme } from '@material-ui/core/styles/createTheme';
import { Overrides } from '@material-ui/core/styles/overrides';

// Metrics we use below-- all are in pixels.

export const haloDiameter = 38; // size of focus/hover halo around thumb
export const thumbDiameter = 20;
export const trackHeight = 20;
export const trackWidth = 36;

export const SwitchOverrides = (theme: Theme): Overrides => ({
  MuiSwitch: {
    // Our component library doesn't have the concept of primary vs. secondary
    // switches, so by default all of our components are styled as secondary.
    // Color selectors below have to be exactly as-is (e.g. &$checked +
    // $track) in order to win specificity over MUI's defaults.

    colorSecondary: {
      color: theme.palette.background.b10,
      '&:hover': {
        backgroundColor: alpha(theme.palette.background.b10, 0.1),
      },
      '& + $track': {
        backgroundColor: theme.palette.background.b5,
        opacity: 1,
      },
      '&$checked': {
        '&:hover': {
          backgroundColor: alpha(theme.palette.success.main, 0.15),
        },
        '& + $track': {
          backgroundColor: theme.palette.success.main,
        },
      },
      '&$disabled': {
        '& + $track': {
          backgroundColor: theme.palette.background.b4,
          opacity: 1,
        },
        '&$checked': {
          '& + $track': {
            backgroundColor: theme.palette.background.b4,
          },
        },
      },
    },

    // Our layout is slightly different than MUI's default. The thumb aligns
    // with the edge of the track exactly, whereas MUI allows the thumb to
    // extend past the track a bit in either direction. Making the thumb
    // translucent temporarily helps check alignment.

    root: {
      height: `${haloDiameter}px`,
      padding: `0 ${(haloDiameter - thumbDiameter) / 2}px`,
      width: undefined,

      // These only apply to the track-- switchBase is positioned absolutely.

      alignItems: 'center',
      justifyContent: 'center',
    },

    // switchBase is the container for the thumb and the hover/focus halo
    // around it (set by background color above). MUI centers the thumb inside
    // it for us.

    switchBase: {
      borderRadius: '50%',
      height: `${haloDiameter}px`,
      width: `${haloDiameter}px`,
      '&$checked': {
        transform: `translateX(${trackWidth - thumbDiameter}px)`,
      },

      // What's going on with these borders and margins? First, we need to
      // inset the focus ring (generated by ::before) 1px so that it doesn't
      // exceed the bounds of the enclosing element and get cut off. Secondly,
      // we need to apply a highContrastOnly border on the switchBase *only* when
      // the component is hovered, so that high-contrast themes see a ring
      // around the element at the right time. However, adding this border
      // causes the focus ring to become misaligned, so we need to step the
      // focus ring back 1px to accommodate that.

      '&:hover': {
        border: `1px solid ${theme.palette.highContrastOnly.main}`,
      },
      '&::before': {
        margin: '1px',
        borderRadius: '50%',
      },
      '&:hover::before': {
        margin: 0,
      },
    },
    track: {
      // Needed for high contrast mode
      border: `1px solid ${theme.palette.highContrastOnly.main}`,
      borderRadius: `${trackHeight / 2}px`,
      height: `${trackHeight}px`,
      width: `${trackWidth}px`,
    },
  },
});
