import * as React from 'react';
import { RadioProps } from '@material-ui/core/Radio';
import { createStyles, makeStyles, Theme } from '../styles';

export type RadioIconProps = Pick<RadioProps, 'checked' | 'disabled'>;

const dotRadius = 3;
const radius = 8;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: RadioIconProps) => {
      // Unchecked enabled appearance by default.

      let backgroundColor = theme.palette.background.default;
      let borderColor = theme.palette.border.dark;
      let dotColor = backgroundColor;
      let axDotVisible = false;

      if (props.disabled) {
        backgroundColor = theme.palette.background.b3;
        borderColor = theme.palette.background.b4;
        dotColor = props.checked ? theme.palette.background.b6 : backgroundColor;

        if (props.checked) {
          axDotVisible = true;
        }
      } else if (props.checked) {
        backgroundColor = theme.palette.success.main;
        borderColor = backgroundColor;
        dotColor = theme.palette.success.contrastText!;
        axDotVisible = true;
      }

      return {
        backgroundColor,
        alignItems: 'center',
        border: `2px solid ${borderColor}`,
        borderRadius: radius,
        display: 'inline-flex',
        height: radius * 2,
        justifyContent: 'center',
        width: radius * 2,

        '&::before': {
          backgroundColor: dotColor,
          borderRadius: dotRadius,
          // Needed for high contrast mode
          border: axDotVisible
            ? `${dotRadius}px solid ${theme.palette.highContrastOnly.main}`
            : 'none',
          content: '""',
          display: 'block',
          height: dotRadius * 2,
          width: dotRadius * 2,
        },
      };
    },
  }),
);

export const RadioIcon: React.FunctionComponent<RadioIconProps> = (props) => {
  const classes = useStyles(props);

  return <span className={classes.root} aria-hidden="true" />;
};
