import * as React from 'react';
import { default as MuiToggleButton } from '@material-ui/lab/ToggleButton';
import { ToggleButtonProps } from './ToggleButton.types';
import { useStyles } from './ToogleButton.styles';

export const ToggleButton = React.forwardRef(
  (props: ToggleButtonProps, ref: React.Ref<HTMLButtonElement>) => {
    const { variant, ...rest } = props;
    const classes = useStyles(props);

    return (
      <MuiToggleButton
        ref={ref}
        {...rest}
        classes={{
          root: classes.root,
          selected: classes.selected,
        }}
      />
    );
  },
);

export default ToggleButton;
