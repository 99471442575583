import * as React from 'react';
import classnames from 'classnames';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { default as MuiAvatar } from '@material-ui/core/Avatar';
import { Theme } from '@material-ui/core/styles/createTheme';
import { AvatarProps } from './Avatar.types';
import { silhouette } from './AvatarAssets';
import { Typography } from '../Typography';

// Map size descriptions to actual pixels.

const sizes = {
  'extra-small': 24,
  small: 32,
  medium: 42,
  large: 100,
  'extra-large': 160,
};

// How big the initials inside the avatar are in comparison to its diameter, and
// the smallest initials size -- this comes into play in the extra-small size.
// The smallest size is specified in pixels.

const initialsRatio = 0.33;
const minInitialsSize = 12;

// Basic idea here is that the container specifies the overall size of the
// component, and everything inside it should position itself absolutely.

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: (props: AvatarProps) => ({
      display: 'inline-block',
      height: sizes[props.size] + theme.spacing(1),
      position: 'relative',
      width: sizes[props.size] + theme.spacing(1),
    }),
    containerSelected: {
      '&::before': {
        border: `2px solid ${theme.palette.brand.main}`,
        borderRadius: '50%',
        bottom: 0,
        content: '""',
        left: 0,
        position: 'absolute',
        right: 0,
        top: 0,
      },
    },
    containerUnavailable: {
      '&::after': {
        // This background styling is done to avoid issues with color fringing
        // on rotated elements on Chrome, and to ensure correct appearance in
        // high-contrast mode.
        backgroundColor: theme.palette.background.default,
        backgroundImage: `linear-gradient(${theme.palette.background.b5}, ${theme.palette.background.b5})`,
        borderBottom: `1px solid ${theme.palette.background.default}`,
        borderTop: `1px solid ${theme.palette.background.default}`,
        content: '""',
        height: '3px',
        left: 0,
        marginTop: '50%',
        position: 'absolute',
        top: 0,
        // translateY vertically centers the div.
        // rotate rotates it so that it's the correct orientation.
        // scaleX sets it to the length of the hypotenuse of the container -- we
        // don't have to worry about distortion becuase we're working with solid
        // colors.
        transform: `translateY(-50%) rotate(-45deg) scaleX(${Math.sqrt(2)})`,
        width: '100%',
        zIndex: 1,
      },
    },
    containerUnavailableThick: {
      '&::after': {
        borderBottom: `3px solid ${theme.palette.background.default}`,
        borderTop: `3px solid ${theme.palette.background.default}`,
        height: '9px',
      },
    },
  }),
);

export const useMuiStyles = makeStyles(() =>
  createStyles({
    // See also root styles in Avatar.overrides.ts.
    root: (props: AvatarProps) => ({
      fontSize: Math.max(Math.ceil(sizes[props.size] * initialsRatio), minInitialsSize),
    }),
  }),
);

export const Avatar = React.forwardRef((props: AvatarProps, ref: React.Ref<HTMLDivElement>) => {
  const classes = useStyles(props);
  const muiClasses = useMuiStyles(props);
  const { className, selected, size, unavailable, ...muiProps } = props;
  const { alt, src, srcSet, children } = props;
  let muiAvatar: React.ReactNode;

  if (src || srcSet || children) {
    muiAvatar = (
      <MuiAvatar classes={muiClasses} ref={ref} {...muiProps}>
        <span data-testid="initial-container" aria-hidden={children ? true : undefined}>
          {children}
        </span>
        {children && <Typography variant="srOnly">{alt}</Typography>}
      </MuiAvatar>
    );
  } else {
    // Fall back to the generic silhouette.

    muiAvatar = <MuiAvatar src={silhouette} classes={muiClasses} ref={ref} {...muiProps} />;
  }

  return (
    <div
      className={classnames(className, classes.container, {
        [classes.containerSelected]: selected,
        [classes.containerUnavailable]: unavailable,
        [classes.containerUnavailableThick]: unavailable && size === 'extra-large',
      })}
    >
      {muiAvatar}
    </div>
  );
});

export default Avatar;
