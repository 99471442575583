import * as React from 'react';
import classnames from 'classnames';
import { InputBaseComponentProps } from '@material-ui/core/InputBase';
import { makeStyles, createStyles } from '../../styles';
import { useComboboxContext } from '../Combobox.context';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      padding: '8px 12px',
    },
    isMulti: {
      flexWrap: 'wrap',
      padding: '2px 4px',
    },
  }),
);

export const InputWrap = (props: InputBaseComponentProps) => {
  const { components, value, comboboxProps } = useComboboxContext();
  const { SingleValue, MultiValue, Combobox, ValueWrap } = components;
  const { isMulti, id, ValueWrapProps, disabled } = comboboxProps;
  const classes = useStyles(props);

  return (
    <>
      {value.length > 0 && (
        <ValueWrap
          // Note: using a span here to avoid cascading `> div` styles from InputBaseOverrides.
          component="span"
          // Id required for input aria-describedby.
          id={`combobox-value-${id}`}
          {...ValueWrapProps}
          // Declare after spread to merge consumer styles with internal styles.
          className={classnames(
            classes.root,
            { [classes.isMulti]: isMulti },
            ValueWrapProps?.className,
          )}
        >
          {isMulti &&
            value.map((option) => (
              <MultiValue option={option} key={option.value} disabled={disabled} />
            ))}
          {!isMulti && <SingleValue option={value[0]} />}
        </ValueWrap>
      )}
      <Combobox {...props} />
    </>
  );
};

export default InputWrap;
