import * as React from 'react';
import {
  default as MuiFormHelperText,
  FormHelperTextProps,
} from '@material-ui/core/FormHelperText';

export const FormHelperText = React.forwardRef(
  (props: FormHelperTextProps, ref: React.Ref<HTMLParagraphElement>) => (
    <MuiFormHelperText ref={ref} {...props} />
  ),
);

export default FormHelperText;
