import * as React from 'react';
import { default as MuiTableRow, TableRowProps } from '@material-ui/core/TableRow';

export const TableRow = React.forwardRef(
  (props: TableRowProps, ref: React.Ref<HTMLTableRowElement>) => (
    <MuiTableRow ref={ref} {...props} />
  ),
);

export default TableRow;
