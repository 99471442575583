import * as React from 'react';
import { default as MuiMenuItem, MenuItemProps } from '@material-ui/core/MenuItem';

export const MenuItem = React.forwardRef((props: MenuItemProps, ref: React.Ref<HTMLLIElement>) => {
  const { button, ...other } = props;

  const onKeyDownHandler = (event: React.KeyboardEvent) => {
    if (event.key === ' ') {
      event.stopPropagation();
      const target = event.target as HTMLLIElement;
      target.click();
    }
  };

  // button as any - https://github.com/mui-org/material-ui/issues/14971
  return <MuiMenuItem button={button as any} onKeyDown={onKeyDownHandler} ref={ref} {...other} />;
});

export default MenuItem;
