import * as React from 'react';
import { default as MuiBreadcrumbs } from '@material-ui/core/Breadcrumbs';
import { ChevronRightSpecial } from '../../internal/icons/small/ChevronRightSpecial';
import { makeStyles, createStyles, Theme } from '../styles';
import { BreadcrumbsContextProvider } from './Breadcrumbs.context';
import {
  BreadcrumbsItemProps,
  BreadcrumbsProps,
  ButtonProps,
  MenuItemProps,
  MenuProps,
} from './Breadcrumbs.types';
import { isABreadcrumbItem } from './BreadcrumbsItem';
import { defaultComponents } from './components';
import BreadcrumbsMenu from './components/BreadcrumbsMenu';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      minHeight: '38px',
    },
    listItem: {
      // && adds more specificity so default button/anchor/typography styles are overridden.
      // We cannot directly override these because those components are provided by consumers.
      '&& a': {
        color: theme.palette.text.secondary,
        textDecoration: 'underline',
        fontSize: theme.typography.fontSizeMedium,
        fontFamily: 'inherit',
        '&[role="presentation"]': {
          color: theme.palette.text.secondary,
          cursor: 'default',
          textDecoration: 'none',
        },
        '&:hover': {
          color: theme.palette.text.primary,
        },
        '&:focus,:active': {
          color: theme.palette.text.secondary,
          '&:hover': {
            color: theme.palette.text.primary,
          },
        },
        '&:disabled': {
          outline: 'none',
        },
      },
      '&&:last-child': {
        textDecoration: 'none',
        '& :not(a, button), button:disabled': {
          color: theme.palette.text.primary,
          textDecoration: 'inherit',
        },
      },
    },
    separator: {
      color: theme.palette.text.hint,
    },
  }),
);

const Breadcrumbs = React.forwardRef(
  (props: BreadcrumbsProps & Partial<BreadcrumbsItemProps>, ref: React.Ref<HTMLElement>) => {
    const {
      ButtonProps,
      children,
      components,
      menuButtonLabel,
      href,
      isCurrentLinkActive = true,
      isCurrentPage,
      itemsBeforeCollapse = 1,
      itemsAfterCollapse = 1,
      label,
      menuId,
      MenuItemProps,
      MenuProps,
      navigationLabel,
      separator = <ChevronRightSpecial />,
      ...other
    } = props;

    const classes = useStyles();

    const childrenNodes = React.Children.toArray(children).filter(isABreadcrumbItem);

    const renderItemsBeforeAndAfter = (allItems: React.ReactElement<BreadcrumbsItemProps>[]) => {
      const renderItem = (child: React.ReactElement, idx: number) => (
        <div key={idx}>
          {React.cloneElement(child, {
            isCurrentLinkActive,
          })}
        </div>
      );

      if (itemsBeforeCollapse + itemsAfterCollapse >= allItems.length) {
        return allItems.map(renderItem);
      }

      const overflowMenuItems = allItems
        .slice(itemsBeforeCollapse, allItems.length - itemsAfterCollapse)
        .map((breadcrumbItem) => ({
          isCurrentPage: breadcrumbItem.props.isCurrentPage,
          label: breadcrumbItem.props.label,
          onClick: breadcrumbItem.props.onClick,
        }));

      return [
        ...allItems.slice(0, itemsBeforeCollapse),
        <BreadcrumbsMenu
          menuButtonLabel={menuButtonLabel}
          breadcrumbsMenuItems={overflowMenuItems}
          menuId={menuId}
        />,
        ...allItems.slice(allItems.length - itemsAfterCollapse, allItems.length),
      ].map(renderItem);
    };

    return (
      <MuiBreadcrumbs
        aria-label={navigationLabel}
        classes={{
          ol: classes.list,
          li: classes.listItem,
          separator: classes.separator,
        }}
        ref={ref}
        // This ensures we never use the default expand button from MUI.
        maxItems={Math.max(childrenNodes.length, itemsBeforeCollapse + itemsAfterCollapse)}
        separator={separator}
        {...other}
      >
        {renderItemsBeforeAndAfter(childrenNodes)}
      </MuiBreadcrumbs>
    );
  },
);

export const BreadcrumbsWithContext = React.forwardRef(
  (props: BreadcrumbsProps & Partial<BreadcrumbsItemProps>, ref: React.Ref<HTMLElement>) => {
    const { components, ...other } = props;

    return (
      <BreadcrumbsContextProvider components={defaultComponents(components)} {...other}>
        <Breadcrumbs ref={ref} {...other} />
      </BreadcrumbsContextProvider>
    );
  },
) as <
  TMenuProps extends MenuProps,
  TMenuItemProps extends MenuItemProps,
  TButtonProps extends ButtonProps,
>(
  props: BreadcrumbsProps<TMenuProps, TMenuItemProps, TButtonProps>,
  ref: React.Ref<HTMLElement>,
) => React.ReactElement;

export default BreadcrumbsWithContext;
