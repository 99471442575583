import * as React from 'react';
import { default as Combobox } from './Combobox';
import { ComboboxGroup, ComboboxOption } from './Combobox.types';
import { useAsync } from './useAsync';
import { AsyncComboboxProps } from './AsyncCombobox.types';

export const AsyncCombobox = <Option extends ComboboxOption, Group extends ComboboxGroup<Option>>(
  props: AsyncComboboxProps<Option, Group>,
  ref: React.Ref<HTMLDivElement>,
) => <Combobox ref={ref} {...useAsync(props)} />;

const AsyncComboboxRef = React.forwardRef(AsyncCombobox) as <
  Option extends ComboboxOption,
  Group extends ComboboxGroup<Option>,
>(
  props: AsyncComboboxProps<Option, Group> & { ref?: React.Ref<HTMLDivElement> },
) => React.ReactElement;

export default AsyncComboboxRef;
