// This is a generated file. Any changes made directly in this file will be overwritten.
import * as React from 'react';
import { createSvgIcon } from '..';

export const Upload = createSvgIcon(
  {
    LeftToRight: (
      <>
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M14 5h1v9H1V5h1M6 4l2-2 2 2M8 10V2"
        />
      </>
    ),
  },
  'small',
);
