import * as React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  default as MuiDialogActions,
  DialogActionsProps as MuiDialogActionsProps,
} from '@material-ui/core/DialogActions';

const useStyles = makeStyles(() =>
  createStyles({
    tertiaryContent: {
      marginRight: 'auto',
    },
  }),
);

export interface DialogActionsProps extends MuiDialogActionsProps {
  tertiaryContent?: JSX.Element;
}

export const DialogActions = React.forwardRef(
  (props: DialogActionsProps, ref: React.Ref<unknown>) => {
    const { tertiaryContent, children, ...other } = props;
    const classes = useStyles(props);

    return (
      <MuiDialogActions ref={ref} {...other}>
        {tertiaryContent && <div className={classes.tertiaryContent}>{tertiaryContent}</div>}
        {children}
      </MuiDialogActions>
    );
  },
);

export default DialogActions;
