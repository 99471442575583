import * as React from 'react';

import { makeStyles, createStyles, Theme } from '@bb-ui/react-library';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { CloseCircle, CheckCircle } from "@bb-ui/icons/dist/medium";
import { getBundle, ILocaleProps } from "../../lib/locales";

export const useStyles = makeStyles((theme: Theme) => createStyles({
  indicator: {
    marginLeft: 'auto',
    marginRight: theme.spacing(1),
    display: 'inline-flex',
  },
  indicatorText: {
    marginRight: theme.spacing(1),
  },
  check: {
    color: theme.palette.common.white,
    fill: theme.palette.success.main,
  },
}));

export interface IFeatureFlagValueIndicatorProps extends ILocaleProps {
  flagValue: string;
}

export const FeatureFlagValueIndicator: React.FunctionComponent<IFeatureFlagValueIndicatorProps> = (props) => {
  const classes = useStyles(props);
  const { flagValue, locale } = props;
  const bundle = getBundle(locale);

  if (flagValue === 'true') {
    return (
      <div className={ classes.indicator }>
        <Typography variant="h4" component="span" className={ classes.indicatorText }>{ bundle.on }</Typography>
        <CheckCircle className={ classes.check } />
      </div>
    );
  }

  if (flagValue === 'false') {
    return (
      <div className={ classes.indicator }>
        <Typography variant="h4" component="span" className={ classes.indicatorText }>{ bundle.off }</Typography>
        <CloseCircle />
      </div>
    );
  }

  // TODO: Flags can have custom values in List type, we will need to check with design what they want to show,
  //  and also will need to be grabbing the localized value. For now only boolean is supported in the list panel
  return (
    <div />
  );
};

export default FeatureFlagValueIndicator;
