import { ExecutionLogEntry } from "../execution/ExecutionLogEntry";

export type ExecutionHeartbeatMessage = {
  messageType: "ExecutionHeartbeat";
  tenantId: string;
  executionId: string;
  claimId: string;
  newHeartbeatSeconds?: number;
  logs?: ExecutionLogEntry[];
};

export function isExecutionHeartbeatMessage(
  message: any
): message is ExecutionHeartbeatMessage {
  return message.messageType === "ExecutionHeartbeat";
}
