import { JobServiceException } from "../JobServiceException";

export class InvalidJobInputException extends JobServiceException {
  public constructor(errors: object, additionalData?: object) {
    super(
      400,
      "InvalidJobInputException",
      "The provided job input is invalid!",
      { ...additionalData, errors }
    );
  }
}
