import * as React from 'react';
import { Upload } from '../../internal/icons/small/Upload';
import { OutlineButton } from '../Button';
import { FileUploaded } from './components/FileUploader.fileUploaded';
import { FileUploaderButtonProps } from './FileUploader.types';
import { useStyles } from './FileUploaderButton.styles';
import { isValidFileSize } from './utils/fileValidators';

export const FileUploaderButton = (props: FileUploaderButtonProps) => {
  const { accept, fileIcon, id, onChange, promptIcon, removeIcon, strings, maximumFileSize } =
    props;
  const { fileLabel, promptLabel, removeLabel, fileSizeTooLargeErrorTitle } = strings;
  const [file, setFile] = React.useState<File>();
  const classes = useStyles(props);
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { files } = event.target;

    if (files?.[0] && files[0] instanceof File) {
      setFile(files[0]);

      if (onChange) {
        onChange(files[0]);
      }
    }
  }

  function handleClick(event: React.MouseEvent) {
    fileInputRef.current?.dispatchEvent(new MouseEvent('click'));
    event.preventDefault();
  }

  return (
    <>
      <input
        accept={accept ? accept.join() : undefined}
        className={classes.input}
        onChange={handleChange}
        type="file"
        id={id}
        ref={fileInputRef}
      />
      {file ? (
        <FileUploaded
          file={file}
          fileIcon={fileIcon}
          fileLabel={fileLabel}
          fileSetter={(file) => setFile(file)}
          removeIcon={removeIcon}
          removeLabel={removeLabel}
          fileInputRef={fileInputRef}
          error={!isValidFileSize(file, maximumFileSize)}
          errorTitle={fileSizeTooLargeErrorTitle}
        />
      ) : (
        <OutlineButton fullWidth onClick={handleClick} startIcon={promptIcon ?? <Upload />}>
          {promptLabel}
        </OutlineButton>
      )}
    </>
  );
};
