import { JobServiceException } from "../JobServiceException";

export class TenantNotFoundException extends JobServiceException {
  public constructor(tenantId: string, additionalData?: object) {
    super(
      404,
      "TenantNotFoundException",
      `Tenant with id ${tenantId} not found!`,
      { ...additionalData, tenantId }
    );
  }
}
