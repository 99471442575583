import { ExecutionLogEntry } from "../execution/ExecutionLogEntry";

export interface ParentExecutionHeartbeatMessage {
  readonly messageType: "ParentExecutionHeartbeat";
  readonly tenantId: string;
  readonly executionId: string;
  readonly newHeartbeatSeconds?: number;
  readonly logs?: ExecutionLogEntry[];
}

export function isParentExecutionHeartbeatMessage(
  message: any
): message is ParentExecutionHeartbeatMessage {
  return message.messageType === "ParentExecutionHeartbeat";
}
