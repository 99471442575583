import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    hideOffScreen: {
      position: 'absolute' as 'absolute',
      overflow: 'hidden',
      width: '1px',
      height: '1px',
      margin: '-1px',
      padding: 0,
      border: 0,
      clip: 'rect(0 0 0 0)',
    },
  }),
);

export default useStyles;
