import { createStyles, makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      paddingLeft: '15px',
      paddingRight: '15px',
    },
  }),
);
