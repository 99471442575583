import * as React from 'react';
import { createSvgIcon, IconDirectionality } from '..';

export const Clear = createSvgIcon(
  {
    [IconDirectionality.LeftToRight]: (
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 6l4 4m0-4l-4 4m2 5v0c3.866 0 7-3.134 7-7v0c0-3.866-3.134-7-7-7v0C4.134 1 1 4.134 1 8v0c0 3.866 3.134 7 7 7z"
      />
    ),
  },
  'small',
);
