import classNames from 'classnames';
import React from 'react';
import { useStyles } from './Listbox.styles';
import { ListboxProps } from './Listbox.types';

export const Listbox = React.forwardRef<HTMLUListElement, ListboxProps>(
  (props, ref): JSX.Element => {
    const classes = useStyles();

    return (
      <ul
        ref={ref}
        role="listbox"
        {...props}
        // Declare after spread to merge consumer styles with internal styles.
        className={classNames(classes.list, classes.listbox, props.className)}
      >
        {props.children}
      </ul>
    );
  },
);

export default Listbox;
