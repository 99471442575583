import * as React from 'react';
import classnames from 'classnames';
import { useStyles } from './HideOffScreen.styles';

export interface HideOffScreenProps extends React.HTMLAttributes<HTMLDivElement> {
  component?: React.ElementType;
}

export const HideOffScreen: React.FunctionComponent<HideOffScreenProps> = (props) => {
  const { component: Component = 'div', className, children, ...other } = props;
  const classes = useStyles(props);

  return (
    <Component className={classnames(classes.hideOffScreen, className)} {...other}>
      {children}
    </Component>
  );
};

export default HideOffScreen;
