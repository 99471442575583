import { default as PickerDeprecated } from './Picker';
import { warnDeprecated } from '../../internal/utilities/warnDeprecated';

export const pickerDeprecationMessage = `
  Bb-UI: The \`Picker\` component is deprecated and will be removed in the next major release.
  Please use the \`Combobox\` component as a replacement.

  Combobox component: https://bb-ui.bb-fnds.com/dev/components/combobox.
  Migration info: https://bb-ui.bb-fnds.com/dev/components/picker.
`;
export const Picker = warnDeprecated(PickerDeprecated, pickerDeprecationMessage);

export {
  Control as PickerControl,
  Option as PickerOption,
  Placeholder as PickerPlaceholder,
  SingleValue as PickerSingleValue,
  ValueContainer as PickerValueContainer,
  MultiValue as PickerMultiValue,
  Menu as PickerMenu,
  NoOptionsMessage as PickerNoOptionsMessage,
  DropdownIndicator as PickerDropdownIndicator,
  ClearIndicator as PickerClearIndicator,
  IndicatorSeparator as PickerIndicatorSeparator,
  IndicatorsContainer as PickerIndicatorsContainer,
} from './PickerComponents';
export * from './Picker.types';
