import * as React from 'react';
import classnames from 'classnames';
import { Theme } from '@material-ui/core/styles/createTheme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { LinkTypeMap, default as MuiLink, LinkProps as MuiLinkProps } from '@material-ui/core/Link';

export type LinkProps<AnchorElement extends React.ElementType = LinkTypeMap['defaultComponent']> =
  MuiLinkProps<
    AnchorElement,
    {
      component?: React.ElementType | string;
      disabled?: boolean;
      error?: boolean;
    }
  >;

const useStyles = makeStyles((theme: Theme) => {
  const errorActive = theme.palette.type === 'light' ? '#992c20' : theme.palette.error.main;

  return createStyles({
    root: {},
    inherit: {
      color: 'inherit',
      '&:active, &:hover, &:active:hover': {
        color: 'inherit',
        opacity: 0.9,
      },
      '&:focus': {
        color: 'inherit',
      },
      '&[disabled]': {
        color: 'inherit',
        opacity: 0.8,
      },
    },
    secondary: {
      color: theme.palette.action.active,
      '&:active, &:hover, &:active:hover': {
        color: theme.palette.action.hover,
      },
      '&:focus': {
        color: theme.palette.action.active,
      },
      '&[disabled]': {
        color: theme.palette.text.disabled,
      },
    },
    error: {
      color: theme.palette.error.main,
      '&:active, &:hover, &:active:hover': {
        color: errorActive,
      },
      '&:focus': {
        color: theme.palette.error.main,
      },
      '&[disabled]': {
        color: theme.palette.error.main,
      },
    },
  });
});

export const Link = React.forwardRef(
  <AnchorElement extends React.ElementType>(
    props: LinkProps<AnchorElement>,
    ref?:
      | ((instance: HTMLSpanElement | null) => void)
      | React.RefObject<HTMLSpanElement>
      | null
      | undefined,
  ) => {
    const classes = useStyles(props);
    const { className, color, ...other } = props;

    return (
      <MuiLink
        underline="always"
        ref={ref}
        className={classnames(className, classes.root, {
          [classes.inherit]: color === 'inherit',
          [classes.secondary]: color === 'secondary',
          [classes.error]: color === 'error',
        })}
        {...other}
      />
    );
  },
);

export default Link;
