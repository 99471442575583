/**
 * This is a higher-order component used to provide a deprecation
 * warning in the console (except in production mode).
 */

import * as React from 'react';

export const warnDeprecated =
  <Props extends Record<string, unknown>>(
    Component: React.ComponentType<Props>,
    message: string,
  ): React.FC<Props> =>
  (props) => {
    if (process.env.NODE_ENV !== 'production') {
      console.warn(message);
    }

    return <Component {...(props as Props)} />;
  };
