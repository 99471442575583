import classnames from 'classnames';
import * as React from 'react';

import { Alert as MuiAlert } from '@material-ui/lab';
import { useTheme } from '@material-ui/core';
import { IconButton } from '../IconButton';
import { Close } from '../../internal/icons/medium';
import { BannerProps } from './Banner.types';
import { useStyles } from './Banner.styles';
import { BbThemeProvider } from '../BbThemeProvider';

export const Banner: React.FC<BannerProps> = (props) => {
  const { children, className, icon, severity, ...others } = props;
  const styles = useStyles(props);
  const isErrorOrWarning = severity === 'error' || severity === 'warning';
  const { direction, palette } = useTheme();
  const themeProviderProps = {
    theme: severity === 'warning' ? 'light' : palette.type,
    isRtl: direction === 'rtl',
  };

  const getAction = () => {
    if ('actions' in props) {
      return (
        <>
          {props.actions.map((action) =>
            React.cloneElement(action, {
              className: classnames(action.props && action.props.className, styles.action),
            }),
          )}
        </>
      );
    }
    if ('onClose' in props && 'closeText' in props) {
      return (
        <IconButton
          className={styles.dismissAction}
          aria-label={props.closeText}
          title={props.closeText}
          onClick={props.onClose}
        >
          <Close />
        </IconButton>
      );
    }
    return null;
  };

  const action = getAction();

  return (
    <MuiAlert
      {...others}
      className={classnames(className, styles.root)}
      /* Reset the role, so we can properly place it on the inner body */
      role="presentation"
      action={
        action ? <BbThemeProvider {...themeProviderProps}>{action}</BbThemeProvider> : undefined
      }
      /* Ensure that we always specify an icon, to override Material-UI's default icon mapping */
      icon={icon || false}
      severity={severity || 'info'}
    >
      <BbThemeProvider {...themeProviderProps}>
        <div
          className={styles.message}
          tabIndex={-1}
          role={isErrorOrWarning ? 'alert' : 'status'}
          aria-live={isErrorOrWarning ? 'assertive' : 'polite'}
        >
          {children}
        </div>
      </BbThemeProvider>
    </MuiAlert>
  );
};
