import { Theme } from '@material-ui/core/styles/createTheme';
import { Overrides } from '@material-ui/core/styles/overrides';
import { rgba, rtlNoFlip } from '../styles/utilities';

export const TooltipOverrides = (theme: Theme): Overrides => {
  const tooltipBackgroundColor =
    theme.palette.type === 'light' ? theme.palette.background.default : theme.palette.background.b3;
  const tooltipBorderColor =
    theme.palette.type === 'light' ? theme.palette.border.main : theme.palette.background.b4;
  const tooltipOffset = '16px';
  const arrowSize = '14px';
  const arrowBorderSize = '17px';
  const arrowOffset = '-8px';

  return {
    MuiTooltip: {
      popper: {
        opacity: undefined,
      },
      tooltip: {
        position: 'relative',
        maxWidth: '300px',
        padding: '7px 12px',
        fontSize: theme.typography.fontSizeDefault,
        backgroundColor: tooltipBackgroundColor,
        border: '1px solid',
        borderColor: tooltipBorderColor,
        boxShadow:
          theme.palette.type === 'light'
            ? `${rgba(theme.palette.background.b9, 0.075)} 0px 0px 0px 4px`
            : undefined,
        color: theme.palette.text.primary,
      },
      popperArrow: {
        '&[x-placement*="top"] $arrow': {
          width: undefined,
          height: undefined,
          marginBottom: arrowOffset,
          transform: rtlNoFlip('rotate(45deg)'),
          '&::before': {
            borderColor: undefined,
            borderWidth: undefined,
          },
        },
        '&[x-placement*="bottom"] $arrow': {
          width: undefined,
          height: undefined,
          marginTop: arrowOffset,
          transform: rtlNoFlip('rotate(-135deg)'),
          '&::before': {
            borderColor: undefined,
            borderWidth: undefined,
          },
        },
        '&[x-placement*="left"] $arrow': {
          width: undefined,
          height: undefined,
          marginTop: '10px',
          marginBottom: '11px',
          marginRight: arrowOffset,
          transform: rtlNoFlip('rotate(-45deg)'),
          '&::before': {
            borderColor: undefined,
            borderWidth: undefined,
          },
        },
        '&[x-placement*="right"] $arrow': {
          width: undefined,
          height: undefined,
          marginTop: '10px',
          marginBottom: '11px',
          marginLeft: arrowOffset,
          transform: rtlNoFlip('rotate(135deg)'),
          '&::before': {
            borderColor: undefined,
            borderWidth: undefined,
          },
        },
      },
      arrow: {
        color: undefined,
        overflow: undefined,
        width: arrowSize,
        height: arrowSize,
        '&::before, &::after': {
          content: '""',
          position: 'absolute',
        },
        '&::before': {
          borderStyle: undefined,
          backgroundColor: undefined,
          transform: undefined,
          width: arrowBorderSize,
          height: arrowBorderSize,
          background:
            theme.palette.type === 'light'
              ? `linear-gradient(135deg,
              ${rgba(theme.palette.background.b9, 0)} 0%,
              ${rgba(theme.palette.background.b9, 0)} 58%,
              ${rgba(theme.palette.background.b9, 0.075)} 58%,
              ${rgba(theme.palette.background.b9, 0.075)} 100%)`
              : undefined,
        },
        '&::after': {
          width: '100%',
          height: '100%',
          background: `linear-gradient(135deg,
            ${rgba(tooltipBackgroundColor, 0)} 0%,
            ${rgba(tooltipBackgroundColor, 0)} 48%,
            ${rgba(tooltipBackgroundColor, 1)} 48%,
            ${rgba(tooltipBackgroundColor, 1)} 100%)`,
          backgroundColor: rgba(tooltipBackgroundColor, 0.01), // needed for high contrast mode
          borderRight: rtlNoFlip(`1px solid ${tooltipBorderColor}`),
          borderBottom: rtlNoFlip(`1px solid ${tooltipBorderColor}`),
        },
      },
      tooltipPlacementLeft: {
        margin: `0 ${tooltipOffset}`,
        [theme.breakpoints.up('sm')]: {
          margin: `0 ${tooltipOffset}`,
        },
      },
      tooltipPlacementRight: {
        margin: `0 ${tooltipOffset}`,
        [theme.breakpoints.up('sm')]: {
          margin: `0 ${tooltipOffset}`,
        },
      },
      tooltipPlacementTop: {
        margin: `${tooltipOffset} 0`,
        [theme.breakpoints.up('sm')]: {
          margin: `${tooltipOffset} 0`,
        },
      },
      tooltipPlacementBottom: {
        margin: `${tooltipOffset} 0`,
        [theme.breakpoints.up('sm')]: {
          margin: `${tooltipOffset} 0`,
        },
      },
    },
  };
};
