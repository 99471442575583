import { Theme } from '@material-ui/core/styles/createTheme';
import { Overrides } from '@material-ui/core/styles/overrides';
import { rgba } from '../styles/utilities';

export const InputLabelOverrides = (theme: Theme): Overrides => {
  const disabledBackgroundColor =
    theme.palette.type === 'light' ? theme.palette.background.b3 : theme.palette.background.b2;
  const placeholderHidden = {
    opacity: 0,
  };
  const placeholderVisible = {
    opacity: 0.6,
  };

  return {
    MuiInputLabel: {
      formControl: {
        position: undefined,
        top: undefined,
        left: undefined,
        transform: undefined,
      },
      outlined: {
        top: '-1px',
        left: 0,
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: '16px',
        transform: 'translate(12px, 8px) scale(.875)',
        width: 'fit-content',
        paddingRight: '10px',
        '&$shrink': {
          transform: 'translate(12px, -10px) scale(.75)',
          '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            left: '-4px',
            right: '-6px',
            bottom: 0,
            borderRadius: '2px',
            zIndex: -1,
            paddingRight: '0px',
            background: `linear-gradient(to bottom,
              ${rgba(theme.palette.background.default, 0)} 0%,
              ${rgba(theme.palette.background.default, 0)} 49%,
              ${rgba(theme.palette.background.default, 1)} 50%,
              ${rgba(theme.palette.background.default, 1)} 100%)`,
          },
          '&$disabled::before': {
            background: `linear-gradient(to bottom,
              ${rgba(theme.palette.background.default, 0)} 0%,
              ${rgba(theme.palette.background.default, 0)} 49%,
              ${rgba(disabledBackgroundColor, 1)} 50%,
              ${rgba(disabledBackgroundColor, 1)} 100%)`,
          },
        },
        '& + div > input': {
          '&::-webkit-input-placeholder': placeholderHidden,
          '&::-moz-placeholder': placeholderHidden, // Firefox 19+
          '&::-ms-input-placeholder': placeholderHidden, // Edge
          '&:focus::-webkit-input-placeholder': placeholderVisible,
          '&:focus::-moz-placeholder': placeholderVisible, // Firefox 19+
          '&:focus::-ms-input-placeholder': placeholderVisible, // Edge
        },
      },
      shrink: {
        transform: undefined,
      },
      asterisk: {
        float: 'left',
        paddingLeft: 0,
        paddingRight: 4,
      },
    },
  };
};
