import * as React from 'react';
import { OffScreenAnnouncementProps } from './OffScreenAnnouncement.types';
import { HideOffScreen } from '../HideOffScreen';

export const OffScreenAnnouncement: React.FunctionComponent<OffScreenAnnouncementProps> = (
  props,
) => {
  const { message, type, changeTrigger, ...other } = props;
  const [announcedMessage, updateMessage] = React.useState<string | undefined>(undefined);
  const updateTimeout = React.useRef<ReturnType<typeof setTimeout>>();

  React.useEffect(
    () => {
      const updateFromChangeTrigger = changeTrigger !== undefined && message === announcedMessage;
      const updateFromMessage = message !== announcedMessage;

      if (message && !announcedMessage) {
        updateMessage(message);
      } else if (updateFromChangeTrigger || updateFromMessage) {
        updateMessage(undefined);
        updateTimeout.current = setTimeout(() => {
          updateMessage(message);
        });
      }

      return () => {
        if (updateTimeout.current) {
          clearTimeout(updateTimeout.current);
        }
      };
    },
    // FIXME in PLAT-1415
    // Adding announcedMessage as a dep causes an infinite render loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [changeTrigger, message],
  );

  return (
    <HideOffScreen aria-live={type} role={type === 'polite' ? 'status' : 'alert'} {...other}>
      {announcedMessage && announcedMessage}
    </HideOffScreen>
  );
};

OffScreenAnnouncement.defaultProps = {
  type: 'polite',
};

export default OffScreenAnnouncement;
