import * as React from 'react';
import {
  default as MuiExpansionPanelDetails,
  ExpansionPanelDetailsProps,
} from '@material-ui/core/ExpansionPanelDetails';

export const ExpansionPanelDetails: React.FunctionComponent<ExpansionPanelDetailsProps> = (
  props,
) => {
  const { children, ...otherProps } = props;

  return <MuiExpansionPanelDetails {...otherProps}>{children}</MuiExpansionPanelDetails>;
};

export default ExpansionPanelDetails;
