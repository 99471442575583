import { JobServiceException } from "../JobServiceException";

export class ExecutionAlreadyClaimedException extends JobServiceException {
  public constructor(executionId: string, additionalData?: object) {
    super(
      409,
      "ExecutionAlreadyClaimedException",
      `Execution with id ${executionId} already claimed!`,
      { ...additionalData, executionId }
    );
  }
}
