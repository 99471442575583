import { BreakpointsOptions } from '@material-ui/core/styles/createBreakpoints';

const breakpoints: BreakpointsOptions = {
  keys: ['xs', 'sm', 'md', 'lg', 'xl'],
  values: {
    xs: 0,
    sm: 640 / 16,
    md: 1024 / 16,
    lg: 1440 / 16,
    xl: 1920 / 16,
  },
  unit: 'em',
  step: 6.25, // 1/16 * 100
};

export default breakpoints;
