import * as React from 'react';
import { CognitoAuthContext } from './CognitoAuthProvider';

export function useCognitoAuth() {
  const cognitoContext = React.useContext(CognitoAuthContext);

  if (!cognitoContext) {
    throw new Error('useCognitoAuth must be used within the CognitoAuthContext.Provider');
  }

  return cognitoContext;
}

export default useCognitoAuth;
