import { ActionMeta, ValueType, OptionsType } from 'react-select/src/types';
import { PickerProps, OptionType, RemoveAction, SelectAction } from './Picker.types';

export function announceTagRemove({ ariaLiveMessages }: PickerProps, label: string) {
  return label ? ariaLiveMessages!.tagRemove({ label }) : '';
}

export function announceTagCreate({ ariaLiveMessages }: PickerProps, option: string) {
  return ariaLiveMessages!.tagCreate({ label: option });
}

export function announceOptionSelect({ ariaLiveMessages }: PickerProps, label: string) {
  return ariaLiveMessages!.optionSelect({ label });
}

export function announceTagFocus(
  { ariaLiveMessages }: PickerProps,
  tagLabel: string,
  tags: OptionType[],
) {
  const index = tags.findIndex((tag: OptionType) => tag.label === tagLabel);
  const instructions = index + 1 === tags.length ? ariaLiveMessages!.tagInstructions : '';

  return `${ariaLiveMessages!.tagFocus({
    label: tagLabel,
    current: index + 1,
    total: tags.length,
  })} ${instructions}`;
}

export function announceInputFocus(props: PickerProps) {
  const { label, isSearchable, isMulti, ariaLiveMessages } = props;

  return `
    ${ariaLiveMessages!.inputFocus({ label })}
    ${isSearchable === false ? '' : ariaLiveMessages!.inputFocusIsSearchable}
    ${isMulti === false ? '' : ariaLiveMessages!.inputFocusIsMulti}
  `;
}

export function announceMenuInstructions({ ariaLiveMessages }: PickerProps) {
  return ariaLiveMessages!.menuInstructions;
}

export function announceOptionFocus(
  { ariaLiveMessages }: PickerProps,
  optionLabel: string,
  options: OptionType[],
) {
  if (!options) {
    return '';
  }
  const index = options.findIndex((option: OptionType) => option.label === optionLabel);

  return ariaLiveMessages!.optionFocus({
    label: optionLabel,
    current: index + 1,
    total: options.length,
  });
}

export function announceSearchResults(
  { ariaLiveMessages, isCreatable }: PickerProps,
  value: string,
  resultsCount: number,
  isValidNewOption: boolean,
) {
  if (value && isCreatable && isValidNewOption) {
    return ariaLiveMessages!.searchResultsIsCreatable({ value, count: resultsCount });
  }
  return value
    ? ariaLiveMessages!.searchResults({ value, count: resultsCount })
    : ariaLiveMessages!.optionsAvailable({ count: resultsCount });
}

export function getOnChangeAriaLiveMessage(
  props: PickerProps,
  value: ValueType<OptionType, boolean>,
  action: ActionMeta<OptionType>,
) {
  let ariaMessage = '';
  let label: string;
  switch (action.action) {
    case 'deselect-option':
    case 'pop-value':
    case 'remove-value':
      // When isMulti is true and nothing is selected, hitting 'backspace' sends a 'pop-value' change with an empty array as the value.
      // In this case, we don't actually want the aria-live message updated so return null.
      if (props.isMulti && value && 'length' in value && value.length === 0) {
        return null;
      }
      label = props.isMulti
        ? getRemovedOptionLabel(action as RemoveAction)
        : getOptionLabel(value as OptionType);
      ariaMessage = announceTagRemove(props, label);
      break;
    case 'select-option':
      label = props.isMulti
        ? getSelectedOptionLabel(action as SelectAction)
        : getOptionLabel(value as OptionType);
      ariaMessage = announceOptionSelect(props, label);
      break;
    case 'create-option':
      label = props.isMulti
        ? getCreatedOptionLabel(value as OptionsType<OptionType>, props.createOptionPosition)
        : getOptionLabel(value as OptionType);
      ariaMessage = announceTagCreate(props, label);
      break;
  }
  if (props.isMulti) {
    ariaMessage = `${ariaMessage} ${announceInputFocus(props)}`;
  }
  return ariaMessage;
}

function getOptionLabel(option: OptionType) {
  return option && option.label;
}

function getSelectedOptionLabel(action: SelectAction) {
  return action && action.option && action.option.label;
}

function getRemovedOptionLabel(action: RemoveAction) {
  return action && action.removedValue && action.removedValue.label;
}

function getCreatedOptionLabel(
  value: OptionsType<OptionType>,
  createOptionPosition?: 'first' | 'last',
) {
  if (value.length === 0) {
    return '';
  }
  return createOptionPosition === 'first' ? value[0].label : value[value.length - 1].label;
}
