import * as React from 'react';
import { default as Combobox } from './Combobox';
import { useCreatable } from './useCreatable';
import {
  CreatableGroup,
  CreatableOption,
  CreatableComboboxProps,
  CreatableStrings,
} from './CreatableCombobox.types';
import { CreatableMenuItem } from './CreatableCombobox';
import { useAsync } from './useAsync';
import { AsyncComboboxProps } from './AsyncCombobox.types';
import { ComboboxMultiProps, ComboboxSingleProps } from './Combobox.types';

type CreatableProps<Option extends CreatableOption, Group extends CreatableGroup<Option>> = Omit<
  CreatableComboboxProps<Option, Group>,
  'strings'
>;

type AsyncProps<Option extends CreatableOption, Group extends CreatableGroup<Option>> = Pick<
  AsyncComboboxProps<Option, Group>,
  'defaultOptions' | 'cacheOptions' | 'loadOptions'
>;

export interface AsyncCreatableStrings<Option extends CreatableOption>
  extends CreatableStrings<Option> {
  loadingResults: string;
}

export interface AsyncCreatableComboboxCommonProps<
  Option extends CreatableOption,
  Group extends CreatableGroup<Option>,
> extends AsyncProps<Option, Group>,
    CreatableProps<Option, Group> {
  strings: AsyncCreatableStrings<Option>;
}

type AsyncCreatableComboboxTypes<Option extends CreatableOption> =
  | ComboboxSingleProps<Option>
  | ComboboxMultiProps<Option>;

export type AsyncCreatableComboboxProps<
  Option extends CreatableOption,
  Group extends CreatableGroup<Option>,
> = AsyncCreatableComboboxCommonProps<Option, Group> & AsyncCreatableComboboxTypes<Option>;

export const AsyncCreatableCombobox = <
  Option extends CreatableOption,
  Group extends CreatableGroup<Option>,
>(
  props: AsyncCreatableComboboxProps<Option, Group>,
  ref: React.Ref<HTMLDivElement>,
) => {
  const { components, ...consumerProps } = props;
  const { createOptionLabel } = consumerProps.strings;
  /**
   * The order is important here.
   * We start by providing the consumer props to the useAsync hook.
   * useAsync will begin the fetch if detaultOptions is true.
   * While fetching options:
   *  - The loading status is updated in the props and passed from useAsync to useCreatable.
   *  - useCreatable has no options at this time and passes the props to Combobox.
   * Once options are fetched:
   *  - The options and loading status are updated in the props and passed to useCreatable.
   *  - useCreatable updates the options prop if a creatable option is needed.
   *  - The props are provided to Combobox.
   */
  // eslint-disable-next-line react/no-unstable-nested-components
  const AsyncCreatable = (props: AsyncCreatableComboboxProps<Option, Group>) => (
    <Combobox {...useCreatable(useAsync(props) as CreatableComboboxProps<Option, Group>)} />
  );

  return (
    <AsyncCreatable
      ref={ref}
      components={{
        ...components,
        // eslint-disable-next-line react/no-unstable-nested-components
        MenuItem: (props) => (
          <CreatableMenuItem
            createOptionLabel={createOptionLabel}
            components={components}
            {...props}
          />
        ),
      }}
      {...consumerProps}
    />
  );
};

const AsyncCreatableComboboxRef = React.forwardRef(AsyncCreatableCombobox) as <
  Option extends CreatableOption,
  Group extends CreatableGroup<Option>,
>(
  props: AsyncCreatableComboboxProps<Option, Group> & { ref?: React.Ref<HTMLDivElement> },
) => React.ReactElement;

export default AsyncCreatableComboboxRef;
