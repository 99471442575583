import { ServiceConfig } from "./ServiceConfig";
import { IForgeStack } from "./IForgeStack";

export abstract class KmsConfig extends ServiceConfig {
  public abstract readonly aliasNameSuffix: string;

  public getAliasName(stage: string, deployment?: string): string {
    const components = [
      `alias/${KmsConfig.SERVICE_NAME}`,
      this.getStage(stage, deployment),
    ];
    if (deployment) {
      components.push(deployment);
    }
    components.push(this.aliasNameSuffix);
    return components.join("-");
  }

  public getAliasArn(
    stack: IForgeStack,
    localJobServiceDeploymentId?: string
  ): string {
    const jobSvcAccount = this.getAccount(stack);
    const deployment =
      stack.stage === "local" ? localJobServiceDeploymentId : undefined;
    return `arn:${stack.partition}:kms:${
      stack.region
    }:${jobSvcAccount}:${this.getAliasName(stack.stage, deployment)}`;
  }
}
