import { JobServiceException } from "../JobServiceException";

const name = "UnsupportedTenantTypeException";

export class UnsupportedTenantTypeException extends JobServiceException {
  public constructor(tenantType?: string, additionalInfo?: object) {
    super(400, name, `Tenant type ${tenantType} is not supported!`, {
      ...additionalInfo,
      tenantType: tenantType ?? "<undefined>",
    });
  }
}
