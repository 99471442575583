import { useEffect, useState, useCallback } from 'react';
import { IFeatureFlagApiProps, FeatureFlagVisibility, SucceededRequest, FailedRequest } from '../common/common.types';
import { fetchPagedResults, patchFlagDefinitionData } from '../common/http';
import {
  UseFeatureFlagDefinitionResults,
  FeatureFlagDefinitionData, FeatureFlagReleaseVersion,
} from './use-feature-flag-definitions.types';

/**
 * React hook to fetch all feature flag definitions
 */
export function useFeatureFlagDefinitions(props: IFeatureFlagApiProps): UseFeatureFlagDefinitionResults {
  const { apiBaseUrl, accessToken } = props;
  const [data, setResults] = useState([] as FeatureFlagDefinitionData[]);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [succeededRequests, setSucceededRequests] = useState<SucceededRequest[]>([]);
  const [failedRequests, setFailedRequests] = useState<FailedRequest[]>([]);
  const [loadingRequests, setLoadingRequests] = useState<number>(0);

  const getFeatureFlagDefinitions = useCallback(async () => {
    const apiPath = `${apiBaseUrl ?? ''}/config/privilege/api/v1/featureFlags/definitions`;
    try {
      setLoading(true);
      const results = await fetchPagedResults<FeatureFlagDefinitionData>(apiPath, accessToken);
      if (results.length) {
        setResults(results);
      }
    } catch (err) {
      setError(err as any);
    } finally {
      setLoading(false);
    }
  }, [accessToken, apiBaseUrl]);

  useEffect(() => {
    getFeatureFlagDefinitions();
  }, [accessToken, apiBaseUrl, getFeatureFlagDefinitions]);

  const updateFeatureFlagDefinition = useCallback((flagKey: string, defaultValue?: string, clearFlagValues?: boolean, visibility?: FeatureFlagVisibility,
    releaseVersion?: FeatureFlagReleaseVersion | null) => {
    const queryParam = clearFlagValues ? '?operation=deleteAllValues' : '';
    const apiPath = `${apiBaseUrl ?? ''}/config/privilege/api/v1/featureFlags/definitions/${flagKey}${queryParam}`;
    setLoadingRequests(reqs => reqs + 1);
    patchFlagDefinitionData(apiPath, accessToken, defaultValue, visibility, releaseVersion)
      .then((response) => {
        setSucceededRequests(reqs => [
          ...reqs,
          { response },
        ]);
      })
      .catch((error) => {
        setFailedRequests(reqs => [
          ...reqs,
          { response: null, error },
        ]);
      })
      .finally(() => {
        setLoadingRequests(reqs => reqs - 1);
      });
  }, [accessToken, apiBaseUrl]);

  const clearSucceededRequests = useCallback(
    () => setSucceededRequests([]),
    [],
  );

  const clearFailedRequests = useCallback(
    () => setFailedRequests([]),
    [],
  );

  return {
    flagDefinitions: data,
    error,
    loading,
    updateFeatureFlagDefinition,
    succeededRequests,
    clearSucceededRequests,
    failedRequests,
    clearFailedRequests,
    loadingRequests,
  };
}
