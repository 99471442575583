import * as React from 'react';
import { default as MuiDialog } from '@material-ui/core/Dialog';
import { DialogProps } from './Dialog.types';
import { DialogTitle } from '../DialogTitle';
import { useStyles } from './Dialog.styles';

const DialogFocus = (props: DialogProps) => {
  const { children, hasTitle, open } = props;

  // Simple ref to search for children by classname
  const ref = React.useRef<HTMLDivElement>(null);

  // We want to force focus to either title or the dialog's contents. Material-UI does not seem to do that out of the box
  React.useLayoutEffect(() => {
    const elementToFocus = hasTitle ? 'bb-h1-element' : 'bb-dialog-content';
    let elements = ref.current?.getElementsByClassName(elementToFocus);

    // Fall back to focusing content if no title elements are found
    if (hasTitle && !elements?.length) {
      elements = ref.current?.getElementsByClassName('bb-dialog-content');
    }

    if (open && elements?.length) {
      (elements[0] as HTMLDivElement).focus();
    }
  }, [hasTitle, open]);

  return <div ref={ref}>{children}</div>;
};

export const Dialog = React.forwardRef((props: DialogProps, ref: React.Ref<unknown>) => {
  const baseClasses = useStyles(props);
  const { children, hasTitle, isAlert, open, classes, ...other } = props;
  const renderChildren = () =>
    React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        const elementChild: React.ReactElement<any> = child;
        if (elementChild.type === DialogTitle) {
          return React.cloneElement(elementChild, {
            isAlert,
          });
        }
        return elementChild;
      }
      return child;
    });

  return (
    <>
      <MuiDialog
        maxWidth="md"
        scroll="body"
        PaperProps={{
          elevation: 0,
          role: isAlert ? 'alertdialog' : 'dialog',
        }}
        TransitionProps={
          {
            role: undefined,
          } as any
        }
        open={open}
        ref={ref}
        classes={baseClasses}
        {...other}
      >
        <DialogFocus open={open} hasTitle={hasTitle}>
          {renderChildren()}
        </DialogFocus>
      </MuiDialog>
    </>
  );
});

DialogFocus.defaultProps = {
  hasTitle: true,
};

export default Dialog;
