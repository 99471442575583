import * as React from 'react';
import { default as MuiDrawer } from '@material-ui/core/Drawer';
import { DrawerProps } from './Drawer.types';

export const Drawer = React.forwardRef((props: DrawerProps, ref?: React.Ref<unknown>) => {
  const { drawerLabel, isContentRegion } = props;

  return (
    <MuiDrawer
      PaperProps={{
        role: isContentRegion ? 'region' : 'navigation',
        'aria-label': drawerLabel,
      }}
      ref={ref}
      {...props}
    />
  );
});

Drawer.defaultProps = {
  isContentRegion: false,
};

export default Drawer;
