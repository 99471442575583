import * as React from 'react';
import { default as MuiTabs } from '@material-ui/core/Tabs';
import { ITabsProps } from './Tabs.types';

export const Tabs: React.FunctionComponent<ITabsProps> = (props: ITabsProps) => {
  const { textColor, action, ...other } = props;

  // This is a mitigation for a MUI bug in 4.10 which causes the tab indicator to be rendered with a 0px width under
  // certain circumstances. The underlying cause is MUI calculating the tab width before properly rendering out the tab.
  // When upgrading to MUI v5, this logic can be removed.
  // Note that use the tabs.action ref to trigger an updateScoreIndicator manually does not seem sufficient.
  // See https://github.com/mui/material-ui/issues/9337#issuecomment-413789329
  // and https://github.com/mui/material-ui/pull/27791
  React.useEffect(() => {
    window.dispatchEvent(new CustomEvent('resize'));
  }, []);

  return <MuiTabs textColor={textColor || 'primary'} {...other} />;
};

export default Tabs;
