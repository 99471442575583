import * as React from 'react';
import {
  default as MuiCardActionArea,
  CardActionAreaProps,
} from '@material-ui/core/CardActionArea';

export const CardActionArea = React.forwardRef(
  (props: CardActionAreaProps, ref: React.Ref<HTMLButtonElement>) => (
    <MuiCardActionArea ref={ref} {...props} />
  ),
);

export default CardActionArea;
