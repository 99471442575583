import * as React from 'react';
import { createSvgIcon, IconDirectionality } from '..';

export const CircleFilled = createSvgIcon(
  {
    [IconDirectionality.LeftToRight]: (
      <>
        <rect
          width="14"
          height="14"
          x="1"
          y="1"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          rx="7"
        />
        <rect
          width="7"
          height="7"
          x="4.5"
          y="4.5"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="7"
          rx="3.5"
        />
      </>
    ),
  },
  'small',
);
