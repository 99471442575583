import * as React from 'react';
import { default as MuiIconButton, IconButtonProps } from '@material-ui/core/IconButton';

export const IconButton = React.forwardRef(
  (props: IconButtonProps, ref: React.Ref<HTMLButtonElement>) => (
    <MuiIconButton ref={ref} {...props} />
  ),
);

export default IconButton;
