import { Theme } from '@material-ui/core/styles/createTheme';
import { Overrides } from '@material-ui/core/styles/overrides';

export const MenuOverrides = (theme: Theme): Overrides => ({
  MuiMenu: {
    paper: {
      margin: '4px 0',
    },
  },
  MuiMenuItem: {
    root: {
      fontSize: theme.typography.fontSizeDefault,
      minHeight: '37px',
      lineHeight: '21px',
      paddingBottom: '8px',
      paddingTop: '8px',
      whiteSpace: undefined,
    },
    gutters: {
      paddingLeft: '12px',
      paddingRight: '12px',
    },
  },
});
