import { ServiceConfig } from "./ServiceConfig";
import { IForgeStack } from "./IForgeStack";

export abstract class TopicConfig extends ServiceConfig {
  public abstract readonly topicNameSuffix: string;

  public getTopicName(stage: string, deployment?: string): string {
    const components = [
      TopicConfig.SERVICE_NAME,
      this.getStage(stage, deployment),
    ];
    if (deployment) {
      components.push(deployment);
    }
    components.push(this.topicNameSuffix);
    return components.join("-");
  }

  public getTopicArn(
    stack: IForgeStack,
    localJobServiceDeploymentId?: string
  ): string {
    const jobSvcAccount = this.getAccount(stack);
    const deployment =
      stack.stage === "local" ? localJobServiceDeploymentId : undefined;
    return `arn:${stack.partition}:sns:${
      stack.region
    }:${jobSvcAccount}:${this.getTopicName(stack.stage, deployment)}`;
  }

  public get envVarName() {
    return `JOB_SVC_TOPIC_ARN_${this.topicNameSuffix.toUpperCase()}`;
  }

  public get topicArnFromEnv(): string {
    if (!process.env[this.envVarName]) {
      throw new Error(`Environment variable ${this.envVarName} not set`);
    }
    return process.env[this.envVarName]!;
  }
}
