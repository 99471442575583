import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@bb-ui/react-library';
import { FormControlLabel } from '@bb-ui/react-library/dist/components/FormControlLabel';
import { Switch } from '@bb-ui/react-library/dist/components/Switch';
import { getBundle, ILocaleProps } from '../../lib/locales';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  accessibilityLabel: {
    position: 'absolute',
    width: '1px',
    height: '1px',
    margin: '-1px',
    padding: 0,
    overflow: 'hidden',
    clip: 'rect(0, 0, 0, 0)',
    border: 0,
  },
  switch: {
    display: 'flex',
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
}));

export interface IFeatureFlagValueSwitchProps extends ILocaleProps {
  flagKey: string;
  flagLabel: string;
  flagValue: boolean;
  onChange: (event: any) => void;
  disabled: boolean;
}

export const FeatureFlagValueSwitch: React.FunctionComponent<IFeatureFlagValueSwitchProps> = (props) => {
  const classes = useStyles(props);
  const { flagKey, flagLabel, flagValue, onChange, disabled, locale } = props;
  const bundle = getBundle(locale);
  const id = `${flagKey}_html_id`;

  const label = React.useMemo(() => <><span>{ flagValue ? bundle.on : bundle.off }</span><span className={ classes.accessibilityLabel }>{ flagLabel }</span></>,
    [classes.accessibilityLabel, bundle.on, bundle.off, flagLabel, flagValue]);

  return (
    <FormControlLabel
      control={ <Switch checked={ flagValue } onChange={ onChange } name="flag" id={ id } disabled={ disabled } /> }
      label={ label }
      htmlFor={ id }
      className={ classes.switch }
    />
  );
};

export default FeatureFlagValueSwitch;
