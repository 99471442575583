import React from 'react';
import { InlineDropdownProps } from './ListboxDropdown.InlineDropdown.types';
import { Box } from '../../Box';

export const InlineDropdown: React.FC<InlineDropdownProps> = (props) => {
  const { isOpen, BoxProps, children } = props;

  if (!isOpen) {
    return null;
  }

  return <Box {...BoxProps}>{children}</Box>;
};
