// TODO: This is a temporary localization solution until we are able to leverage the foundations localization service.
//  See LRN-176628.
import bundles, { ILocaleBundle } from './index';

export interface ILocaleProps {
  locale?: string;
}

export function getBundle(localeString: string | undefined): ILocaleBundle {
  if (localeString) {
    const locale = localeString.toLowerCase().replace('-', '_');
    if (bundles[locale]) {
      return bundles[locale];
    }
  }
  return bundles.en_us;
}
