import {
  bbDefaultPalette,
  bbBrightsPalette,
  textOnLight,
  textOnDark,
  background as backgroundLight,
  backgroundDark,
} from '@bb-ui/styling/dist';
import { Palette, default as createPalette } from '@material-ui/core/styles/createPalette';

/**
 * This color value is used in certain cases for high contrast mode.
 * The low opacity is not visible outside of high contrast mode but is visible within high contrast mode.
 */
const highContrastOnly = {
  main: 'rgba(0,0,0, 0.001)',
};

const light = {
  type: 'light',
  brand: {
    main: bbDefaultPalette.purpleDark,
    contrastText: textOnDark.primary,
    light: bbDefaultPalette.purpleBackground,
    dark: bbDefaultPalette.purpleBackgroundDark,
  },
  brandAlt: {
    main: bbDefaultPalette.purple,
    contrastText: textOnLight.primary,
  },
  primary: {
    main: bbDefaultPalette.onyx,
    contrastText: textOnDark.primary,
    light: '#404040',
    dark: '',
  },
  secondary: {
    main: bbDefaultPalette.pebble,
    contrastText: textOnLight.primary,
    light: bbDefaultPalette.silver,
    dark: '',
  },
  error: {
    main: bbDefaultPalette.messagingRedDark,
    contrastText: textOnDark.primary,
    light: bbDefaultPalette.messagingRedBackground,
    dark: bbDefaultPalette.messagingRedBackgroundDark,
  },
  success: {
    main: bbDefaultPalette.messagingGreenDark,
    contrastText: textOnDark.primary,
    light: bbDefaultPalette.messagingGreenBackground,
  },
  info: {
    main: bbDefaultPalette.messagingBlueDark,
    contrastText: textOnDark.primary,
    light: bbDefaultPalette.messagingBlueBackground,
  },
  warning: {
    main: bbDefaultPalette.messagingYellowDark,
    contrastText: textOnLight.primary,
    light: bbDefaultPalette.messagingYellowBackground,
  },
  caution: {
    main: bbDefaultPalette.burntOrange,
    light: bbBrightsPalette.redOrange,
  },
  action: {
    // The color of an active action like an icon button.
    active: bbDefaultPalette.onyx,
    hover: bbDefaultPalette.black,
    hoverOpacity: 1,
    selected: bbDefaultPalette.black,
    disabled: bbDefaultPalette.ash,
    disabledBackground: bbDefaultPalette.silver,
  },
  text: textOnLight,
  focus: {
    main: bbDefaultPalette.focusBlueDark,
    light: bbDefaultPalette.focusBlue,
  },
  link: {
    active: bbDefaultPalette.linkBlueDark,
    hover: bbDefaultPalette.linkBlueDarkHover,
    selected: bbDefaultPalette.linkBlueDark,
    disabled: bbDefaultPalette.charcoal,
    hoverOpacity: 1,
    disabledBackground: '',
  },
  indicatorPrimary: {
    main: bbDefaultPalette.indicatorBlue,
    contrastText: textOnDark.primary,
    dark: bbDefaultPalette.focusBlueDark,
  },
  indicatorSecondary: {
    main: bbDefaultPalette.indicatorRed,
    contrastText: textOnDark.primary,
  },
  border: {
    main: bbDefaultPalette.pebble,
    dark: bbDefaultPalette.granite,
  },
  background: backgroundLight,
  highContrastOnly,
};

const dark = {
  type: 'dark',
  brand: {
    main: bbDefaultPalette.purple,
    contrastText: textOnLight.primary,
    light: bbDefaultPalette.purpleBackground,
    dark: bbDefaultPalette.purpleBackgroundDark,
  },
  brandAlt: {
    main: bbDefaultPalette.purpleDark,
    contrastText: bbDefaultPalette.white,
  },
  primary: {
    main: bbDefaultPalette.white,
    contrastText: textOnLight.primary,
    light: bbDefaultPalette.fog,
    dark: '',
  },
  secondary: {
    main: bbDefaultPalette.pebble,
    contrastText: textOnLight.primary,
    light: '',
    dark: '',
  },
  error: {
    main: bbDefaultPalette.messagingRed,
    contrastText: textOnLight.primary,
    light: bbDefaultPalette.messagingRedDark,
    dark: bbDefaultPalette.messagingRedBackgroundDark,
  },
  success: {
    main: bbDefaultPalette.messagingGreen,
    contrastText: textOnLight.primary,
    light: bbDefaultPalette.messagingGreenDark,
  },
  info: {
    main: bbDefaultPalette.messagingBlueBackgroundDark,
    contrastText: textOnLight.primary,
    light: bbDefaultPalette.messagingBlueDark,
  },
  warning: {
    main: bbDefaultPalette.messagingYellowBackgroundDark,
    contrastText: textOnLight.primary,
    light: bbDefaultPalette.messagingYellowDark,
  },
  caution: {
    main: bbBrightsPalette.redOrange,
    light: bbDefaultPalette.burntOrange,
  },
  action: {
    active: bbDefaultPalette.pebble,
    hover: bbDefaultPalette.white,
    hoverOpacity: 1,
    selected: bbDefaultPalette.white,
    disabled: bbDefaultPalette.ash,
    disabledBackground: bbDefaultPalette.silver,
  },
  text: textOnDark,
  focus: {
    main: bbDefaultPalette.focusBlue,
    light: bbDefaultPalette.focusBlueDark,
  },
  link: {
    active: bbDefaultPalette.linkBlue,
    hover: bbDefaultPalette.linkBlueHover,
    selected: bbDefaultPalette.linkBlue,
    disabled: bbDefaultPalette.ash,
    hoverOpacity: 1,
    disabledBackground: '',
  },
  indicatorPrimary: {
    main: bbDefaultPalette.indicatorBlue,
    contrastText: textOnDark.primary,
    dark: bbDefaultPalette.linkBlue,
  },
  indicatorSecondary: {
    main: bbDefaultPalette.indicatorRed,
    contrastText: textOnDark.primary,
  },
  border: {
    main: bbDefaultPalette.ash,
    dark: bbDefaultPalette.white,
  },
  background: backgroundDark,
  highContrastOnly,
};

export const palette = createPalette(light as Palette);
export const paletteDark = createPalette(dark as Palette);
export default palette;
