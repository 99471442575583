import * as React from 'react';
import { createSvgIcon, IconDirectionality } from '..';

export const Groups = createSvgIcon({
  [IconDirectionality.LeftToRight]: (
    <>
      <path
        fill="currentColor"
        stroke="transparent"
        d="M.0308 22.7538c-.136.5353.1878 1.0795.723 1.2154.5353.136 1.0795-.1878 1.2154-.723l-1.9384-.4924zm18 .4924c.1359.5352.6801.859 1.2154.723.5352-.1359.859-.6801.723-1.2154l-1.9384.4924zm-16.0616 0C2.858 19.747 5.5218 17 10 17v-2c-5.5218 0-8.8887 3.4991-9.9692 7.7538l1.9384.4924zM10 17c4.4782 0 7.1421 2.747 8.0308 6.2462l1.9384-.4924C18.8887 18.4991 15.5218 15 10 15v2z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10 16c2.7614 0 5-2.2386 5-5s-2.2386-5-5-5-5 2.2386-5 5 2.2386 5 5 5zM18.5 8C20.433 8 22 6.433 22 4.5S20.433 1 18.5 1 15 2.567 15 4.5 16.567 8 18.5 8zM23 12c-.2345-1.9862-2.5-4-4.5-4"
      />
    </>
  ),
});
