import * as React from 'react';
import { default as MuiOutlinedInput } from '@material-ui/core/OutlinedInput';
import { IOutlinedInputProps } from './OutlinedInput.types';

export const OutlinedInput = React.forwardRef(
  (props: IOutlinedInputProps, ref: React.Ref<unknown>) => (
    <MuiOutlinedInput ref={ref} {...props} />
  ),
);

export default OutlinedInput;
