import { Theme } from '@material-ui/core/styles/createTheme';
import { Overrides } from '@material-ui/core/styles/overrides';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import { bbDefaultPalette } from '@bb-ui/styling';

const barHeight = '6px';
const barRadius = '6px';
const barCenter = '-11px';
const thumbSize = '28px';
const thumbCenter = '-14px';
const thumbMargin = '16px';
const disabledThumbTrackColor = (theme: Theme) =>
  theme.palette.type === 'light' ? bbDefaultPalette.ash : bbDefaultPalette.silver;
const boxShadow = (theme: Theme) =>
  theme.palette.type === 'light'
    ? `${alpha(theme.palette.background.b9, 0.075)} 0px 0px 0px 4px`
    : undefined;

export const SliderOverrides = (theme: Theme): Overrides => ({
  MuiSlider: {
    root: {
      width: `calc(100% - (${thumbMargin} * 2))`,
      height: barHeight,
      margin: `0 ${thumbMargin}`,
      '&$disabled $track': {
        color: disabledThumbTrackColor(theme),
      },
      '&$disabled $rail': {
        border: `1px solid ${theme.palette.border.main}`,
        color: theme.palette.background.b3,
      },
      '&$vertical': {
        height: `calc(100% - (${thumbMargin} * 2))`,
        width: barHeight,
        margin: `${thumbMargin} 0`,
      },
      '&$vertical $track': {
        width: barHeight,
        borderRadius: barRadius,
      },
      '&$vertical $rail': {
        width: barHeight,
        borderRadius: barRadius,
      },
      '&$vertical $thumb': {
        marginTop: thumbCenter,
        marginLeft: barCenter,
        marginBottom: thumbCenter,
      },
    },
    mark: {
      color: theme.palette.text.hint,
      height: barHeight,
    },
    track: {
      color: theme.palette.brand.main,
      height: barHeight,
      borderRadius: barRadius,
      boxSizing: 'border-box',
      // Needed for high contrast mode
      border: `3px solid ${theme.palette.highContrastOnly.main}`,
    },
    rail: {
      color: theme.palette.background.default,
      opacity: 1,
      height: barHeight,
      borderRadius: barRadius,
      boxSizing: 'border-box',
      border: `1px solid ${theme.palette.border.dark}`,
    },
    thumb: {
      width: thumbSize,
      height: thumbSize,
      marginTop: barCenter,
      marginLeft: thumbCenter,
      color: theme.palette.brandAlt.main,
      boxSizing: 'border-box',
      // Needed for high contrast mode
      border: `14px solid ${theme.palette.highContrastOnly.main}`,
      '&:focus, &:hover, &$active': {
        boxShadow: 'none',
      },
      '&$focusVisible, &:hover, &$active': {
        color: theme.palette.brand.main,
      },
      '&$focusVisible::before': {
        content: '""',
        position: 'absolute',
        top: '-20px',
        left: '-20px',
        right: '-20px',
        bottom: '-20px',
        borderRadius: 'inherit',
        boxShadow: `0 0 0 1px ${theme.palette.focus.light}, 0 0 0 2px ${theme.palette.focus.main}, 0 0 0 3px ${bbDefaultPalette.white}`,
        // Needed for high contrast mode
        border: `2px solid ${theme.palette.highContrastOnly.main}`,
      },
      '&$disabled': {
        width: thumbSize,
        height: thumbSize,
        marginTop: barCenter,
        marginLeft: thumbCenter,
        color: theme.palette.background.b5,
      },
    },
    valueLabel: {
      position: 'relative',
      top: '-38px',
      left: '0px',
      border: `1px solid ${theme.palette.border.main}`,
      backgroundColor: theme.palette.background.default,
      borderRadius: '2px',
      boxShadow: boxShadow(theme),
      '& > span': {
        backgroundColor: theme.palette.background.default,
        transform: 'none',
        borderRadius: 'unset',
        height: 'auto',
        width: 'auto',
        minWidth: '40px',
        padding: '10px',
      },
      '& > span > span': {
        transform: 'none',
        color: theme.palette.text.primary,
        fontSize: theme.typography.fontSizeDefault,
      },
      '&::before': {
        content: '""',
        zIndex: '-1',
        marginLeft: '-8px',
        height: '16px',
        width: '16px',
        transform: 'rotate(45deg)',
        boxSizing: '',
        border: `1px solid ${theme.palette.border.main}`,
        borderTop: 'none',
        borderLeft: 'none',
        backgroundColor: theme.palette.background.default,
        bottom: '-8px',
        left: '50%',
        position: 'absolute',
        boxShadow: boxShadow(theme),
      },
      active: {},
      focusVisible: {},
      disabled: {},
      vertical: {},
    },
  },
});
