import deburr from 'lodash.deburr';

/**
 * Splits a string based on a search string's first occurrence inside it, so
 * that it can be highlighted in a search. If the text is not found, then this
 * returns the original text as the first item and empty strings in the rest.
 * @param text text to search inside
 * @param search text to search for
 */

export function splitOnFirstOccurrence(text: string, search: string): [string, string, string] {
  if (search === '') {
    return [text, '', ''];
  }

  const matches = new RegExp(`^(.*?)(${deburr(search)})`, 'i').exec(deburr(text));

  if (!matches) {
    return [text, '', ''];
  }

  // We need to maintain diacritics in the original string.

  return [
    text.substr(0, matches[1].length),
    text.substr(matches[1].length, matches[2].length),
    text.substr(matches[1].length + matches[2].length),
  ];
}
