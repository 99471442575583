import * as React from 'react';

import { PickerProps } from '../Picker/Picker.types';
import { Picker } from '../Picker';

export const TagPicker: React.FunctionComponent<PickerProps> = (props) => {
  const { theme, ...other } = props;

  return <Picker tabSelectsValue={false} {...other} />;
};

TagPicker.defaultProps = {
  isMulti: true,
  isSearchable: true,
  placeholder: '',
};

export default TagPicker;
