import React from 'react';
import { Paper } from '../../Paper';
import { Popper } from '../../Popper';
import { useStyles } from './ListboxDropdown.FloatingDropdown.styles';
import { FloatingDropdownProps } from './ListboxDropdown.FloatingDropdown.types';

export const FloatingDropdown: React.FC<FloatingDropdownProps> = (props) => {
  const { anchorEl, isOpen, PopperProps, children } = props;

  const classes = useStyles();

  if (anchorEl === null) {
    return null;
  }

  return (
    <Popper
      role="presentation"
      disablePortal
      modifiers={{
        flip: {
          enabled: true,
        },
        preventOverflow: {
          enabled: false,
          boundariesElement: 'view',
        },
        hide: {
          enabled: false,
        },
      }}
      anchorEl={anchorEl}
      open={isOpen}
      style={{ width: anchorEl.clientWidth }}
      {...PopperProps}
    >
      <Paper variant="dropdown" className={classes.paper}>
        {children}
      </Paper>
    </Popper>
  );
};
