import React from 'react';
import Select from 'react-select/src/Select';
import { Theme } from '@material-ui/core/styles/createTheme';
import {
  ActionMeta,
  createFilter,
  GroupedOptionsType,
  GroupType,
  InputActionMeta,
  OptionsType,
  ValueType,
} from 'react-select';
import Creatable, { defaultProps as builtins } from 'react-select/creatable';
import { Option as FilterOption } from 'react-select/src/filters';
import { SelectComponents } from 'react-select/src/components';
import classnames from 'classnames';
import { ClickAwayListener } from '../ClickAwayListener';
import {
  announceInputFocus,
  announceMenuInstructions,
  announceSearchResults,
  getOnChangeAriaLiveMessage,
} from './PickerAccessibility';
import { OptionType, PickerProps } from './Picker.types';
import { OffScreenAnnouncement } from '../OffScreenAnnouncement';

import {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  DropdownIndicator,
  ClearIndicator,
  IndicatorsContainer,
  IndicatorSeparator,
} from './PickerComponents';

const defaultInternalComponents = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  DropdownIndicator,
  ClearIndicator,
  IndicatorsContainer,
  IndicatorSeparator,
};

// clear base styles
const selectStyles = {
  container: () => ({}),
  input: () => ({}),
  menu: () => ({}),
};

interface BasePickerProps extends PickerProps {
  ChosenSelectComponent: React.FunctionComponent<PickerProps>;
}

function isGroupOptionType(
  options: GroupedOptionsType<OptionType> | OptionsType<OptionType>,
): options is GroupedOptionsType<OptionType> {
  return options.length > 0 && !!(options[0] as GroupType<OptionType>).options;
}

export const BasePicker: React.FunctionComponent<BasePickerProps> = (props) => {
  const {
    isCreatable,
    classes,
    disabled,
    theme,
    inputId,
    onKeyDown,
    onChange,
    onInputChange,
    filterOption,
    onFocus,
    onBlur,
    onMenuOpen,
    onMenuClose,
    isValidNewOption,
    components,
    showOnlyFilteredResults,
    showOnlyFilteredMessage,
    noOptionsMessage,
    ChosenSelectComponent,
    ...other
  } = props;
  const containerEl: React.MutableRefObject<HTMLDivElement | null> = React.useRef(null);
  const selectEl: React.MutableRefObject<Select<OptionType> | Creatable<OptionType> | null> =
    React.useRef(null);
  const [ariaInputValue, setAriaInputValue] = React.useState('');
  const [isFocusVisible, setFocusVisible] = React.useState(false);
  const [ariaLiveMessage, setAriaLiveMessage] = React.useState('');
  const [filteredOptions, setFilteredOptions] = React.useState(0);
  const [ariaIsValidNewOption, setAriaIsValidNewOption] = React.useState(false);
  const filteredCount = React.useRef(filteredOptions);
  const muiTheme = theme as Partial<Theme>;
  const isMounted = React.useRef(true);

  React.useEffect(() => {
    isMounted.current = true;

    if (filteredOptions === filteredCount.current) {
      setTimeout(() => {
        if (ariaInputValue && isMounted.current) {
          setAriaLiveMessage(`
            ${announceSearchResults(
              props,
              ariaInputValue,
              filteredCount.current,
              ariaIsValidNewOption,
            )}
            ${
              !props.isCreatable && filteredCount.current > 1 ? announceMenuInstructions(props) : ''
            }
          `);
        }
      });
    }

    return () => {
      isMounted.current = false;
    };
  }, [ariaInputValue, ariaIsValidNewOption, filteredOptions, props]);

  function extractAllOptions(options: GroupedOptionsType<OptionType> | OptionsType<OptionType>) {
    if (isGroupOptionType(options)) {
      return options.reduce((acc, group) => acc.concat(group.options), [] as OptionType[]);
    }
    return options;
  }

  function handleIsValidNewOption(
    newOption: string,
    value: ValueType<OptionType, boolean>,
    options: GroupedOptionsType<OptionType> | OptionsType<OptionType>,
  ) {
    let isValid = false;
    const allOptions = extractAllOptions(options);
    if (isValidNewOption) {
      isValid = isValidNewOption(newOption, value, allOptions);
    } else {
      // If the user did not provide their own `isValidNewOption` callback, use the one from react-select.
      if (builtins.isValidNewOption) {
        isValid = builtins.isValidNewOption(newOption, value, allOptions);
      } else {
        throw new Error(
          '"isValidNewOption" was not provided as a prop and does not exist in react-select builtins (was it removed in a new version?)',
        );
      }
    }
    setAriaIsValidNewOption(isValid);
    return isValid;
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLElement>) {
    if (!isFocusVisible) {
      setFocusVisible(true);
    }
    if (onKeyDown) {
      // Declaring as any to fix a type conflict -- react-transition-group in
      // the Picker chain is using a mismatched version of @types/react.
      onKeyDown(event as any);
    }
  }

  function handleOnChange(value: ValueType<OptionType, boolean>, action: ActionMeta<OptionType>) {
    const newAriaLiveMessage = getOnChangeAriaLiveMessage(props, value, action);
    if (newAriaLiveMessage !== null) {
      setAriaLiveMessage(newAriaLiveMessage);
    }
    if (onChange) {
      onChange(value, action);
    }
  }

  function handleOnInputChange(value: string, action: InputActionMeta) {
    if (action.action === 'input-change') {
      setAriaInputValue(value);
      resetFilterCount();
    }
    if (onInputChange) {
      onInputChange(value, action);
    }
  }

  function handleOnFocus(event: React.FocusEvent<HTMLElement>) {
    setAriaLiveMessage(announceInputFocus(props));
    if (onFocus) {
      onFocus(event);
    }
  }

  function handleOnBlur(event: React.FocusEvent<HTMLElement>) {
    setAriaLiveMessage('');
    if (onBlur) {
      onBlur(event);
    }
  }

  function handleOnMenuOpen() {
    setAriaLiveMessage(announceMenuInstructions(props));
    if (onMenuOpen) {
      onMenuOpen();
    }
  }

  function handleOnMenuClose() {
    setFocusVisible(false);
    setAriaLiveMessage(announceInputFocus(props));
    if (onMenuClose) {
      onMenuClose();
    }
  }

  function filterOptions(candidate: FilterOption, input: string) {
    const filter = filterOption || createFilter({});

    if (input) {
      const filtered = filter(candidate, input);

      if (filtered && !props.isCreatable) {
        filteredCount.current += 1;
        setTimeout(() => {
          if (!isMounted.current) return;
          setFilteredOptions(filteredCount.current);
        });
      }

      return filtered;
    }
    return !showOnlyFilteredResults;
  }

  function resetFilterCount() {
    filteredCount.current = 0;
    setFilteredOptions(0);
  }

  function getNoOptionsMessage(input: { inputValue: string }): string | null {
    const message =
      showOnlyFilteredResults && !input.inputValue ? showOnlyFilteredMessage : noOptionsMessage;
    return (message && message(input)) || null;
  }

  const mergedComponents = components
    ? { ...defaultInternalComponents, ...components }
    : defaultInternalComponents;
  return (
    <>
      <ClickAwayListener onClickAway={() => setFocusVisible(false)}>
        <ChosenSelectComponent
          labelId={`${inputId}-label`}
          filterOption={filterOptions}
          isCreatable={isCreatable}
          classes={classes}
          className={classnames(classes.root, { [classes.focusVisible]: isFocusVisible })}
          components={mergedComponents as Partial<SelectComponents<OptionType, boolean>>}
          styles={selectStyles}
          inputId={inputId}
          ref={selectEl}
          containerRef={containerEl}
          selectRef={selectEl}
          isDisabled={disabled}
          isRtl={muiTheme.direction === 'rtl'}
          escapeClearsValue
          setAriaLiveMessage={setAriaLiveMessage}
          onKeyDown={handleKeyDown}
          onChange={handleOnChange}
          onInputChange={handleOnInputChange}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          onMenuOpen={handleOnMenuOpen}
          onMenuClose={handleOnMenuClose}
          isValidNewOption={handleIsValidNewOption}
          noOptionsMessage={getNoOptionsMessage}
          {...other}
        />
      </ClickAwayListener>
      <OffScreenAnnouncement id={`${inputId}-live-region`} message={ariaLiveMessage} />
    </>
  );
};

export const basePickerDefaultProps: Partial<BasePickerProps> = {
  isSearchable: true,
  screenReaderStatus: ({ count }) => `${count} result${count !== 1 ? 's' : ''} available.`,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  noOptionsMessage: ({ inputValue }) => 'No tags found.',
  showOnlyFilteredMessage: () => 'Search for available options.',
  placeholder: '',
  clearInputAriaLabel: 'Remove selected options.',
  removeTagAriaLabel: ({ label }) => `Deselect option ${label}.`,
  ariaLiveMessages: {
    inputFocus: ({ label }) => `${label} is focused, press Down to open the menu.`,
    inputFocusIsSearchable: 'Type to refine list.',
    inputFocusIsMulti: 'Press Left to focus selected values.',
    tagInstructions:
      'Use left and right to toggle between focused values, press Backspace to remove the currently focused value.',
    tagFocus: ({ label, current, total }) => `Value ${label} focused, ${current} of ${total}.`,
    tagRemove: ({ label }) => `Option ${label}, deselected.`,
    tagCreate: ({ label }) => `Option ${label}, created.`,
    menuInstructions:
      'Use Up and Down to navigate options, press Enter to select the currently focused option, press Escape to exit the menu.',
    optionFocus: ({ label, current, total }) => `Option ${label} focused, ${current} of ${total}.`,
    optionSelect: ({ label }) => `Option ${label}, selected.`,
    optionsAvailable: ({ count }) => `${count} result${count !== 1 ? 's' : ''} available.`,
    searchResults: ({ count, value }) =>
      `${count} result${count !== 1 ? 's' : ''} available for search term ${value}.`,
    searchResultsIsCreatable: ({ count, value }) =>
      `${count} result${count !== 1 ? 's' : ''} available for search term ${value}. ${
        count === 1 ? 'Press Enter to create a new option.' : ''
      }`,
  },
  highlightFilteredMatches: true,
};
