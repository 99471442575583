import { createTheme as createMuiTheme } from '@material-ui/core/styles';
import { ThemeOptions, Theme } from '@material-ui/core/styles/createTheme';
import { default as breakpoints } from './breakpoints';
import { palette, paletteDark } from './palette';
import { shape } from './shape';
import { default as typography } from './typography';
import { getOverrides } from './overrides';

export function createTheme(options?: ThemeOptions): Theme {
  const type = options && options.palette && options.palette.type;
  const direction = options && options.direction;
  const zIndex = options?.zIndex;

  const partialTheme = createMuiTheme({
    breakpoints,
    typography,
    shape,
    direction: direction === 'rtl' ? 'rtl' : 'ltr',
    palette: type === 'dark' ? paletteDark : palette,
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
    },
    zIndex,
  });

  return createMuiTheme({
    ...partialTheme,
    overrides: getOverrides(partialTheme),
  });
}

export const bbTheme: Theme = createTheme();
export const bbThemeDark: Theme = createTheme({ palette: { type: 'dark' } });

export default bbTheme;
