import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@bb-ui/react-library';
import { Badge } from '@bb-ui/react-library/dist/components/Badge';
import { getBundle, ILocaleProps } from "../../lib/locales";

export const useStyles = makeStyles((theme: Theme) => createStyles({
  statusBadge: {
    marginLeft: theme.spacing(2),
  },
  customBadge: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
    position: 'static',
    transform: 'none',
    marginRight: theme.spacing(0.25),
    backgroundColor: theme.palette.indicatorPrimary.main,
  },
}));

export interface IFeatureFlagStatusBadgeProps extends ILocaleProps {}

export const FeatureFlagStatusBadge: React.FunctionComponent<IFeatureFlagStatusBadgeProps> = (props: IFeatureFlagStatusBadgeProps) => {
  const classes = useStyles(props);
  const { locale } = props;
  const bundle = getBundle(locale);

  // TODO: Eventually these values will be conditionally assigned with other statuses available, for now
  //  everything is just new
  return (
    <Badge
      badgeContent={ bundle.new }
      position="inline"
      classes={{ badge: classes.customBadge }}
      className={ classes.statusBadge }
    />
  );
};

export default FeatureFlagStatusBadge;
