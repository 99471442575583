import * as React from 'react';
import { createSvgIcon, IconDirectionality } from '..';

export const Attention = createSvgIcon(
  {
    [IconDirectionality.LeftToRight]: (
      <>
        <rect
          width="14"
          height="14"
          x="1"
          y="1"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          rx="7"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M8 4v5"
        />
        <circle cx="8" cy="12" r=".5" stroke="currentColor" />
      </>
    ),
  },
  'small',
);
