import { TableProps } from '@material-ui/core/Table';
import React from 'react';

export type OnSortChangedParams = {
  sortColumnId?: string;
  sortDirection?: SortDirection;
  columnIdClicked: string;
};
export type SortChangedOverride = {
  columnId?: string;
  sortDirection?: SortDirection;
};

export interface ISortableTableContext {
  currentSortColumn?: string;
  currentSortDirection?: SortDirection;
  onColumnClicked: (id: string) => void;
}

export const SortableTableContext = React.createContext<ISortableTableContext>({
  currentSortColumn: '',
  onColumnClicked: () => {
    /* no-op */
  },
});

export type AriaSortBy = 'ascending' | 'descending';
export type SortDirection = 'asc' | 'desc';
export const ariaDirections: { [sortDir in SortDirection]: AriaSortBy } = {
  asc: 'ascending',
  desc: 'descending',
};

export type OnSortChangedCallback = (
  params: OnSortChangedParams,
) => boolean | { columnId: string; sortDirection: SortDirection };

export interface SortableTableProps {
  id: string;
  tableProps?: TableProps;

  disableUnsortedState?: boolean;
  initialSortColumnId?: string;
  initialSortDirection?: SortDirection;

  getAriaSortMessage: (columnId: string, sortDirection?: SortDirection) => string;
  onSortChanged?: OnSortChangedCallback;
}
