import * as React from 'react';
import classnames from 'classnames';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createTheme';
import { default as MuiTypography, TypographyProps } from '@material-ui/core/Typography';

export type ThemeStyle =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'display1'
  | 'display2'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | 'caption'
  | 'button'
  | 'overline';

export interface ITypographyProps extends Omit<TypographyProps, 'variant'> {
  variant?: ThemeStyle | 'inherit' | 'srOnly';
  component?: React.ElementType;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    display1: theme.typography.display1,
    display2: theme.typography.display2,
  }),
);

export const Typography = React.forwardRef((props: ITypographyProps, ref: React.Ref<any>) => {
  const { className, variant, ...other } = props;
  const classes = useStyles(props);
  let muiVariant;
  let classNames = className;

  if (variant === 'display1' || variant === 'display2') {
    classNames = classnames(className, classes[variant]);
  } else {
    muiVariant = variant;
  }

  return (
    <MuiTypography
      ref={ref}
      variant={muiVariant as TypographyProps['variant']}
      className={classNames}
      {...other}
    />
  );
});

export default Typography;
