import * as React from 'react';
import { createSvgIcon } from '..';

export const ChevronRightSpecial = createSvgIcon(
  {
    LeftToRight: (
      <>
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M6 12l4-4-4-4"
        />
      </>
    ),
  },
  'small',
);
