import * as React from 'react';
import {
  default as MuiListItem,
  ListItemProps as MuiListItemProps,
} from '@material-ui/core/ListItem';

export interface ListItemProps extends MuiListItemProps {
  /**
   * to ensure proper HTML, adding removeLiWrap
   * which removes the parent li for button lists
   * if an li has been manually added
   */
  removeLiWrap?: boolean;
}

export const ListItem = React.forwardRef((props: ListItemProps, ref: React.Ref<HTMLLIElement>) => {
  const { button, removeLiWrap, ...other } = props;
  const Wrap = button && !removeLiWrap ? 'li' : React.Fragment;

  // button as any - https://github.com/mui-org/material-ui/issues/14971
  return (
    <Wrap>
      <MuiListItem ref={ref} button={button as any} {...other} />
    </Wrap>
  );
});

export default ListItem;
