import * as React from 'react';
import clsx from 'clsx';
import { Menu as MenuSmallIcon } from '../../../internal/icons/small/Menu';
import { makeStyles, createStyles, Theme } from '../../styles';
import { useBreadcrumbsContext } from '../Breadcrumbs.context';
import { BreadcrumbsItemProps, BreadcrumbsMenuProps } from '../Breadcrumbs.types';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      minWidth: 'max-content',
      minHeight: 'max-content',
      padding: '4px',
      borderRadius: '2px',
      border: '1px solid transparent',
      '&:hover': {
        border: `1px solid ${theme.palette.background.b8}`,
        backgroundColor: theme.palette.background.b3,
        '& svg': {
          color: theme.palette.background.b9,
        },
      },
      '&[aria-expanded="true"]': {
        border: `1px solid ${theme.palette.brand.main}`,
        backgroundColor:
          theme.palette.type === 'light' ? theme.palette.brand.light : theme.palette.brandAlt.main,
        '& svg': {
          color:
            theme.palette.type === 'light' ? theme.palette.brand.main : theme.palette.text.primary,
        },
      },
    },
  }),
);

export const BreadcrumbsMenu = (props: BreadcrumbsMenuProps) => {
  const { menuId, menuButtonLabel: expandText, breadcrumbsMenuItems } = props;

  const classes = useStyles();

  const { components, MenuProps, MenuItemProps, ButtonProps } = useBreadcrumbsContext();
  const { Menu, MenuItem, Button } = components;

  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const [isMenuOpen, toggleIsMenuOpen] = React.useReducer((isMenuOpen) => !isMenuOpen, false);

  if (breadcrumbsMenuItems.length === 0) {
    return null;
  }

  const handleOnClick = (item: BreadcrumbsItemProps) => {
    toggleIsMenuOpen();
    item.onClick?.();
  };

  return (
    <>
      <Button
        id={`button-${menuId}`}
        onClick={toggleIsMenuOpen}
        {...ButtonProps}
        className={clsx(classes.button, ButtonProps?.className)}
        aria-label={expandText}
        aria-controls={isMenuOpen ? menuId : undefined}
        aria-expanded={isMenuOpen}
        aria-haspopup="menu"
        ref={buttonRef}
      >
        <MenuSmallIcon />
      </Button>
      <Menu
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        onClose={toggleIsMenuOpen}
        {...MenuProps}
        MenuListProps={{
          id: menuId,
          'aria-labelledby': `button-${menuId}`,
        }}
        anchorEl={buttonRef.current}
        open={isMenuOpen}
      >
        {breadcrumbsMenuItems.map((item, idx) => (
          <MenuItem
            button
            {...MenuItemProps}
            value={item.label}
            key={idx}
            onClick={() => handleOnClick(item)}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default BreadcrumbsMenu;
