import { JobServiceException } from "../JobServiceException";

export class InvalidProviderException extends JobServiceException {
  constructor(providerId: string, additionalInfo?: object) {
    super(
      400,
      "InvalidProviderException",
      `Invalid provider: ${providerId}`,
      additionalInfo
    );
  }
}
