// This is a generated file. Any changes made directly in this file will be overwritten.
import * as React from 'react';
import { createSvgIcon } from '..';

export const Attention = createSvgIcon(
  {
    LeftToRight: (
      <>
        <circle cx="12" cy="12" r="11" stroke="currentColor" strokeWidth="2" />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M12.75 18.5a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M12 15l-.5-10h1L12 15z"
        />
      </>
    ),
  },
  'default',
);
