import { Theme } from '@material-ui/core/styles/createTheme';
import { Overrides } from '@material-ui/core/styles/overrides';

export const SnackbarOverrides = (theme: Theme): Overrides => ({
  MuiSnackbar: {
    root: {
      margin: '20px 16px',
      [theme.breakpoints.up('md')]: {
        margin: '30px',
      },
      minWidth: '288px',
      maxWidth: '768px',
    },
  },
  MuiSnackbarContent: {
    root: {
      [theme.breakpoints.up('sm')]: {
        width: 'max-content',
      },
      padding: '12px 16px',
      backgroundColor: theme.palette.background.b3,
    },
    message: {
      fontSize: theme.typography.fontSizeDefault,
      flex: 1,
      color: theme.palette.primary.contrastText,
      paddingRight: '8px',
      alignSelf: 'flex-start',
      '& #client-snackbar': {
        flexWrap: 'wrap',
      },
    },
    action: {
      alignSelf: 'flex-start',
      paddingLeft: '8px',
    },
  },
});
