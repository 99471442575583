import * as React from 'react';
import { CheckboxGroupProps, FieldsetProps, LegendProps } from './CheckboxGroup.types';
import { Typography } from '../Typography';
import { HideOffScreen } from '../HideOffScreen';
import { FormControl } from '../FormControl';
import { FormLabel } from '../FormLabel';

export const CheckboxGroup = React.forwardRef(
  (props: CheckboxGroupProps, ref: React.Ref<HTMLDivElement>) => {
    const { children, isRequired, legendText, legendVariant, requiredLabel, className } = props;
    const Fieldset: React.ForwardRefExoticComponent<FieldsetProps> = FormControl;
    const Legend: React.ForwardRefExoticComponent<LegendProps> = FormLabel;

    if (!legendText) {
      throw new Error('legendText must be provided');
    }

    if (isRequired && !requiredLabel) {
      throw new Error('requiredLabel must be provided when setting isRequired="true"');
    }

    return (
      <Fieldset className={className} component="fieldset">
        <Legend component="legend" required={isRequired}>
          <Typography variant={legendVariant} component="span">
            {legendText}
            {requiredLabel && <HideOffScreen>{requiredLabel}</HideOffScreen>}
          </Typography>
        </Legend>
        <FormControl ref={ref}>{children}</FormControl>
      </Fieldset>
    );
  },
);

CheckboxGroup.defaultProps = {
  isRequired: false,
};

export default CheckboxGroup;
