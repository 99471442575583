import { createStyles, makeStyles, Theme } from '../styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subheader: {
      padding: theme.spacing(1.5),
    },
    item: {
      padding: theme.spacing(1.5),
      display: 'flex',
      justifyContent: 'center',
    },
    listboxOption: {
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    link: {
      color: theme.palette.link.active,
      textDecoration: 'underline',
      '&hover': {
        color: theme.palette.link.hover,
      },
      '&focus': {
        color: theme.palette.link.focus,
      },
    },
  }),
);
