export const bbDefaultPalette = {
  white: '#ffffff',
  feather: '#f8f8f8',
  fog: '#f0f0f0',
  silver: '#e5e5e5',
  pebble: '#cdcdcd',
  ash: '#8c8c8c',
  granite: '#767676',
  charcoal: '#666666',
  onyx: '#262626',
  black: '#000000',
  purple: '#c56fd5',
  purpleDark: '#a234b5',
  purpleBackground: '#ecd0f1',
  purpleBackgroundDark: '#63206f',
  burntOrange: '#c75000',
  messagingGreen: '#39e379',
  messagingGreenDark: '#007d2c',
  messagingGreenBackground: '#e5f2e9',
  messagingRed: '#ff4a36',
  messagingRedDark: '#c23e37',
  messagingRedBackground: '#ffdad6',
  messagingRedBackgroundDark: '#661d15',
  messagingBlueDark: '#185677',
  messagingBlueBackground: '#D7F7FF',
  messagingBlueBackgroundDark: '#2C9EDE',
  messagingYellowDark: '#CCB400',
  messagingYellowBackground: '#FFF499',
  messagingYellowBackgroundDark: '#FFE300',
  focusBlue: '#2f8df7',
  focusBlueDark: '#006dc7',
  linkBlue: '#2c9ede',
  linkBlueHover: '#5fd1ff',
  linkBlueDark: '#2073a1',
  linkBlueDarkHover: '#004270',
  indicatorBlue: '#046df6',
  indicatorRed: '#eb1700',
};

export const bbIconPalette = {
  primaryFill: '#8fe7fa',
  errorStroke: '#fd1900',
  errorFill: bbDefaultPalette.messagingRedBackground,
  disabledStroke: bbDefaultPalette.ash,
  disabledFill: bbDefaultPalette.silver,
};

export const bbBrightsPalette = {
  redOrange: '#ff6600',
  yellow: '#ffe300',
  lime: '#80f46b',
  teal: '#85f3da',
  blueGreen: '#14c6cc',
  brightBlue: '#26d7fd',
  blue: '#157afb',
  darkBlue: '#2013a1',
  magenta: '#cc14ae',
  pink: '#ff5991',
};

export const bbGradePalette = {
  green: '#39e379',
  greenHover: '#20CA60',

  yellowGreen: '#cdee4b',
  yellowGreenHover: '#B4D532',

  yellow: '#ffe300',
  yellowHover: '#E6CA00',

  red: '#ff4a36',
  redHover: '#E6311D',

  brightOrange: '#ff9600',
  brightOrangeHover: '#E67D00',
  darkOrange: '#AD6500',
  darkOrangeHover: '#A96400',

  disabledHover: '#565656',
};

export const textOnLight = {
  primary: bbDefaultPalette.onyx,
  secondary: bbDefaultPalette.charcoal,
  disabled: bbDefaultPalette.charcoal,
  hint: bbDefaultPalette.granite,
};

export const textOnDark = {
  primary: bbDefaultPalette.white,
  secondary: bbDefaultPalette.pebble,
  disabled: bbDefaultPalette.ash,
  hint: bbDefaultPalette.ash,
};

export const background = {
  default: bbDefaultPalette.white,
  paper: bbDefaultPalette.feather,
  b1: bbDefaultPalette.white,
  b2: bbDefaultPalette.feather,
  b3: bbDefaultPalette.fog,
  b4: bbDefaultPalette.silver,
  b5: bbDefaultPalette.pebble,
  b6: bbDefaultPalette.ash,
  b7: bbDefaultPalette.granite,
  b8: bbDefaultPalette.charcoal,
  b9: bbDefaultPalette.onyx,
  b10: bbDefaultPalette.black,
};

export const backgroundDark = {
  default: bbDefaultPalette.onyx,
  paper: bbDefaultPalette.charcoal,
  b1: bbDefaultPalette.onyx,
  b2: bbDefaultPalette.charcoal,
  b3: bbDefaultPalette.black,
  b4: bbDefaultPalette.granite,
  b5: bbDefaultPalette.ash,
  b6: bbDefaultPalette.pebble,
  b7: bbDefaultPalette.silver,
  b8: bbDefaultPalette.fog,
  b9: bbDefaultPalette.feather,
  b10: bbDefaultPalette.white,
};
