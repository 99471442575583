import * as React from 'react';
import classnames from 'classnames';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createTheme';
import { ListItem, ListItemProps } from '../ListItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '46px',
      color: theme.palette.text.secondary,
      '& svg': {
        color: theme.palette.text.secondary,
      },
      // Needed for high contrast mode
      borderLeft: `4px solid ${theme.palette.highContrastOnly.main}`,
      '.bb-ui-navigation-list-horizontal &': {
        padding: '0',
        borderLeft: 'none',
        // Needed for high contrast mode
        borderBottom: `4px solid ${theme.palette.highContrastOnly.main}`,
      },
      '&:hover, &:focus, &:active': {
        backgroundColor: 'unset',
        color: theme.palette.text.primary,
        borderColor: theme.palette.border.main,
        '& svg': {
          color: theme.palette.text.primary,
        },
      },
    },
    active: {
      color: theme.palette.text.primary,
      borderColor: theme.palette.brand.main,
      '.bb-ui-navigation-list-horizontal &': {
        borderColor: theme.palette.brand.main,
      },
      '& svg': {
        color: theme.palette.text.primary,
      },
    },
  }),
);

export interface NavigationListItemProps extends ListItemProps {
  active: boolean;
  href: string;
  component?: React.ElementType;
}

export const NavigationListItem = React.forwardRef(
  (props: NavigationListItemProps, ref: React.Ref<HTMLLIElement>) => {
    const classes = useStyles(props);
    const { active, className, ...other } = props;
    return (
      <ListItem
        ref={ref}
        component="a"
        button
        classes={{ root: classes.root }}
        className={classnames(className, {
          [classes.active]: active,
        })}
        aria-current={active ? 'page' : undefined}
        {...other}
      />
    );
  },
);

export default NavigationListItem;
