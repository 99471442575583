import { Theme } from '@material-ui/core/styles/createTheme';
import { Overrides } from '@material-ui/core/styles/overrides';
import { AlertClassKey } from '@material-ui/lab';

// TODO: when we are on TypeScript 3.8+, we can use `import type '@material-ui/lab/themeAugmentation';` instead of this.
declare module '@material-ui/core/styles/overrides' {
  interface ComponentNameToClassKey {
    MuiAlert: AlertClassKey;
  }
}

export const AlertOverrides = (theme: Theme): Overrides => ({
  MuiAlert: {
    root: {
      // Reset styles from material-ui that we don't want
      fontSize: 14,
      borderRadius: 0,
      backgroundColor: theme.palette.background.default,

      [theme.breakpoints.up('sm')]: {
        padding: '12px 16px',
        flexWrap: 'nowrap',
        alignItems: 'center',
      },

      [theme.breakpoints.up('md')]: {
        padding: '12px 32px',
      },
      alignItems: 'flex-start',
      flexWrap: 'wrap',
      padding: '12px 12px',
    },
    icon: {
      padding: 0,
      marginRight: 8,
      opacity: 1,
      alignSelf: 'flex-start',
      [theme.breakpoints.up('sm')]: {
        alignSelf: 'center',
      },
    },
    action: {
      // Reset styles from material-ui
      marginRight: 0,
      display: 'flex',
      flexWrap: 'wrap',
      // This gets overridden by the `dismissAction` class, so the dismiss action will be aligned to the top
      alignItems: 'center',
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 8,
      },
      paddingLeft: 0,
    },
    message: {
      padding: 0,
      // Having a 50% flex basis allows the buttons to wrap when in small breakpoints
      flex: '1 1 50%',
    },
    standardInfo: {
      backgroundColor: theme.palette.info.light,
      color: theme.palette.text.primary,
      '& $icon': {
        color: theme.palette.text.primary,
      },
    },
    standardError: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.text.primary,
      '& $icon': {
        color: theme.palette.text.primary,
      },
    },
    standardSuccess: {
      backgroundColor: theme.palette.success.light,
      color: theme.palette.text.primary,
      '& $icon': {
        color: theme.palette.text.primary,
      },
    },
    standardWarning: {
      backgroundColor: theme.palette.warning.light,
      color:
        theme.palette.type === 'light' ? theme.palette.text.primary : theme.palette.background.b1,
      '& $icon': {
        color:
          theme.palette.type === 'light'
            ? theme.palette.primary.main
            : theme.palette.primary.contrastText,
      },
    },
  },
});
