import * as React from 'react';
import {
  default as MuiInputAdornment,
  InputAdornmentProps,
} from '@material-ui/core/InputAdornment';

export const InputAdornment = React.forwardRef(
  (props: InputAdornmentProps, ref: React.Ref<HTMLDivElement>) => (
    <MuiInputAdornment ref={ref} {...props} />
  ),
);

export default InputAdornment;
