import { Theme } from '@material-ui/core/styles/createTheme';
import { Overrides } from '@material-ui/core/styles/overrides';

export const StepConnectorOverrides = (theme: Theme): Overrides => ({
  MuiStepConnector: {
    vertical: {
      marginLeft: '7px',
      padding: '0 0 5px 0',
    },
    lineVertical: {
      borderLeftWidth: '2px',
      borderColor: theme.palette.secondary.main,
      minHeight: '16px',
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    active: {
      '& $lineVertical': {
        borderColor: theme.palette.brand.main,
      },
    },
    completed: {
      '& $lineVertical': {
        borderColor: theme.palette.brand.main,
      },
    },
  },
});
