import { Typography, TypographyStyle } from '@material-ui/core/styles/createTypography';
import {
  pxToRem,
  fontFamily,
  fontFamilySerif,
  typeStyles,
  fontSizes,
  fontWeights,
} from '@bb-ui/styling/dist';

const entries = Object.entries(typeStyles);
const muiTypeStyles: Partial<Typography> = {};

for (const [type, style] of entries) {
  const muiStyle: Partial<TypographyStyle> = style;

  if (type === 'button' || type === 'overline') {
    muiStyle.textTransform = undefined;
  }

  (muiTypeStyles as any)[type] = muiStyle;
}

export const typography: Partial<Typography> = {
  ...fontSizes,
  ...fontWeights,
  pxToRem,
  fontFamily,
  fontFamilySerif,
  useNextVariants: true,
  fontSize: 14, // provide as a number for MUI
  h1: muiTypeStyles.headingXLarge,
  h2: muiTypeStyles.headingLarge,
  h3: muiTypeStyles.headingMedium,
  h4: muiTypeStyles.headingDefault,
  h5: muiTypeStyles.headingSmall,
  h6: muiTypeStyles.headingXSmall,
  body1: muiTypeStyles.bodyDefault,
  body2: muiTypeStyles.bodySmall,
  subtitle1: muiTypeStyles.subtitle1,
  subtitle2: muiTypeStyles.subtitle2,
  button: muiTypeStyles.button,
  caption: muiTypeStyles.caption,
  overline: muiTypeStyles.overline,
  display1: muiTypeStyles.display1,
  display2: muiTypeStyles.display2,
};

export default typography;
