export interface IJobServiceException {
  readonly statusCode: number;
  readonly name: string;
  readonly description: string;
  readonly additionalInfo?: object;
}

export abstract class JobServiceException implements IJobServiceException {
  /**
   * This is the message that will be returned to the client.
   * It must be a JSON string of this object (omitting this field).
   * @type {string}
   */
  public readonly message: string;

  protected constructor(
    public readonly statusCode: number,
    public readonly name: string,
    public readonly description: string,
    public readonly additionalInfo?: object
  ) {
    this.message = JSON.stringify({
      statusCode,
      name,
      description,
      additionalInfo,
    });
  }
}
