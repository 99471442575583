import * as React from 'react';
import { default as MuiCardHeader, CardHeaderProps } from '@material-ui/core/CardHeader';

export const CardHeader = React.forwardRef(
  (props: CardHeaderProps, ref: React.Ref<HTMLDivElement>) => (
    <MuiCardHeader ref={ref} {...props} />
  ),
);

export default CardHeader;
