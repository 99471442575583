import * as React from 'react';
import type {
  ComboboxGroup,
  ComboboxOption,
  ComboboxProps,
  OptionsOrGroups,
} from './Combobox.types';
import type { CreatableOption, CreatableGroup } from './CreatableCombobox.types';
import type { AsyncComboboxProps } from './AsyncCombobox.types';

export type UseAsyncProps<
  Option extends ComboboxOption | CreatableOption,
  Group extends ComboboxGroup<Option> | CreatableGroup<Option>,
> = AsyncComboboxProps<Option, Group>;

export function useAsync<
  Option extends ComboboxOption | CreatableOption,
  Group extends ComboboxGroup<Option>,
>(props: UseAsyncProps<Option, Group>): ComboboxProps<Option, Group> {
  const {
    onSearch,
    cacheOptions = false,
    defaultOptions: propsDefaultOptions = false,
    loadOptions: propsLoadOptions,
    inputValue: propsInputValue,
    ...comboboxProps
  } = props;
  const [searchString, setSearchString] = React.useState(propsInputValue);
  const [loadedSearchString, setLoadedSearchString] = React.useState('');
  const [defaultOptions, setDefaultOptions] = React.useState<OptionsOrGroups<Option, Group> | null>(
    Array.isArray(propsDefaultOptions) ? propsDefaultOptions : null,
  );
  const [cachedOptions, setCachedOptions] =
    React.useState<Record<string, OptionsOrGroups<Option, Group>>>();
  const [asyncOptions, setAsyncOptions] = React.useState<OptionsOrGroups<Option, Group> | null>();
  const [isLoading, setIsLoading] = React.useState(propsDefaultOptions === true);
  const pendingRequest = React.useRef<unknown>(undefined);
  const prevSearchString = React.useRef(searchString);
  const [prevCacheOptions, setPrevCacheOptions] = React.useState(undefined);
  const mounted = React.useRef(false);

  const loadOptions = React.useCallback(
    async (inputValue: string, callback: (options?: OptionsOrGroups<Option, Group>) => void) => {
      if (!propsLoadOptions) {
        return callback();
      }

      const loader = propsLoadOptions(inputValue, callback);

      if (loader && typeof loader.then === 'function') {
        const options = await loader;
        callback(options);
      }
    },
    [propsLoadOptions],
  );

  const handleOnSearch = React.useCallback(
    (searchValue: string) => {
      if (searchValue === prevSearchString.current) {
        return;
      }

      prevSearchString.current = searchValue;

      if (!searchValue || searchValue === '') {
        setSearchString('');
        setLoadedSearchString('');
        setAsyncOptions([]);
        setIsLoading(false);
        return;
      }

      if (cacheOptions && cachedOptions?.[searchValue]) {
        setSearchString(searchValue);
        setLoadedSearchString(searchValue);
        setAsyncOptions(cachedOptions[searchValue]);
        setIsLoading(false);
      } else {
        const request = (pendingRequest.current = {});

        setSearchString(searchValue);
        setIsLoading(true);
        loadOptions(searchValue, (options) => {
          if (!mounted.current || request !== pendingRequest.current) {
            return;
          }
          pendingRequest.current = undefined;
          setLoadedSearchString(searchValue);
          setAsyncOptions(options ?? []);
          setCachedOptions(options ? { ...cachedOptions, [searchValue]: options } : cachedOptions);
          setIsLoading(false);
        });
      }

      if (onSearch) {
        onSearch(searchValue);
      }
    },
    [onSearch, setAsyncOptions, loadOptions, cacheOptions, cachedOptions],
  );

  React.useEffect(() => {
    if (cacheOptions !== prevCacheOptions) {
      setPrevCacheOptions(cacheOptions);
    }
  }, [cacheOptions, prevCacheOptions]);

  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  React.useEffect(
    () => {
      if (propsDefaultOptions === true) {
        loadOptions(propsInputValue ?? '', (options) => {
          if (!mounted.current) {
            return;
          }

          setDefaultOptions(options ?? []);
          setIsLoading(!!pendingRequest.current);
        });
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [],
  );

  const options = searchString && loadedSearchString ? asyncOptions : defaultOptions;

  return {
    isLoading,
    onSearch: handleOnSearch,
    options: options ?? [],
    inputValue: propsInputValue,
    ...comboboxProps,
  };
}

export default useAsync;
