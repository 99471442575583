import * as React from 'react';
import { SnackbarKey, useSnackbar } from 'notistack';
import { SnackbarCloseProps, SnackbarProps } from './Snackbar.types';
import { Close } from '../../internal/icons/small';
import { useTheme } from '../styles';
import { useStyles } from './SnackbarProvider.styles';
import { IconButton } from '../IconButton';
import { Link } from '../Link';
import { BbThemeProvider } from '../BbThemeProvider';
import { Typography } from '../Typography';

export interface SnackbarActionWithLinkProps extends SnackbarCloseProps {
  /**
   * String of the action link
   */
  actionMessage: string;
  /**
   * Callback function for the action link
   */
  onActionClick: () => void;
}
export interface SnackbarMessageWithIconProps extends SnackbarProps {
  /**
   * Icon component
   */
  icon: React.ReactElement;
}

export type SnackbarMessageProps = SnackbarProps | SnackbarMessageWithIconProps;
export type SnackbarActionProps = SnackbarCloseProps | SnackbarActionWithLinkProps;
export type SnackbarContentProps = SnackbarMessageProps & SnackbarActionProps;

export const SnackbarMessage: React.FC<SnackbarContentProps> = (props: SnackbarContentProps) => {
  const { snackbarMessage, isErrorOrWarning } = props;
  const { actionLink, linkMessage, content, messageIcon } = useStyles(props);
  const theme = useTheme();

  let message;
  if ('icon' in props) {
    const { icon } = props;
    message = (
      <div className={content}>
        {React.cloneElement(icon, { className: messageIcon })}
        <Typography component="span" variant="inherit" role={isErrorOrWarning ? 'alert' : 'status'}>
          {snackbarMessage}
        </Typography>
      </div>
    );
  } else {
    message = (
      <Typography component="span" variant="inherit" role={isErrorOrWarning ? 'alert' : 'status'}>
        {snackbarMessage}
      </Typography>
    );
  }

  if ('onActionClick' in props) {
    const { actionMessage, onActionClick } = props;
    return (
      <>
        {message}
        {actionMessage && (
          <BbThemeProvider
            theme={theme.palette.type === 'light' ? 'dark' : 'light'}
            isRtl={theme.direction === 'rtl'}
          >
            <Link className={actionLink} component="button" onClick={onActionClick}>
              <span className={linkMessage}>{actionMessage}</span>
            </Link>
          </BbThemeProvider>
        )}
      </>
    );
  }
  return message;
};

export const SnackbarAction: React.FC<SnackbarCloseProps> = (props: SnackbarCloseProps) => {
  const { closeButtonAriaLabel } = props;
  const { closeSnackbar } = useSnackbar();
  const theme = useTheme();

  return (
    <BbThemeProvider
      theme={theme.palette.type === 'light' ? 'dark' : 'light'}
      isRtl={theme.direction === 'rtl'}
    >
      <IconButton onClick={() => closeSnackbar()} aria-label={closeButtonAriaLabel}>
        <Close />
      </IconButton>
    </BbThemeProvider>
  );
};

export function useEnqueueBbSnackbar(
  messageProps: SnackbarMessageProps,
  actionProps: SnackbarActionProps,
  key?: SnackbarKey,
): () => void {
  const { enqueueSnackbar } = useSnackbar();
  const props = { ...actionProps, ...messageProps } as SnackbarContentProps;
  const { isPersistent = true } = messageProps;

  return () => {
    enqueueSnackbar(<SnackbarMessage {...props} />, {
      autoHideDuration: !isPersistent ? 15000 : null,
      persist: isPersistent,
      action: <SnackbarAction {...actionProps} />,
      key,
    });
  };
}
