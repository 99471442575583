import { Box } from '../Box';
import { MenuItem } from '../MenuItem';
import { OffScreenAnnouncement } from '../OffScreenAnnouncement';
import { Combobox } from './components/Combobox.Combobox';
import { SingleValue } from './components/Combobox.SingleValue';
import { MultiValue } from './components/Combobox.MultiValue';
import { InputWrap } from './components/Combobox.InputWrap';
import { Listbox } from './components/Combobox.Listbox';
import { MenuItem as ComboboxMenuItem } from './components/Combobox.MenuItem';
import { MenuListSubheader } from './components/Combobox.MenuListSubheader';
import { Search } from './components/Combobox.Search';
import type { Components, ComboboxOption, ComboboxGroup } from './Combobox.types';

export const components = {
  Combobox,
  InputWrap,
  Listbox,
  MenuListSubheader,
  LoadingResultsMenuItem: MenuItem,
  MenuItem: ComboboxMenuItem,
  MultiValue,
  NoResultsMenuItem: MenuItem,
  OffScreenAnnouncement,
  Search,
  SingleValue,
  ValueWrap: Box,
};

export const defaultComponents = <
  Option extends ComboboxOption,
  Group extends ComboboxGroup<Option>,
>(
  overrideComponents?: Partial<Components<Option, Group>>,
) => ({
  ...components,
  ...overrideComponents,
});
