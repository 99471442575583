/**
 * Domain enum
 *
 * Used to group jobs together in the UI
 */
export enum Domain {
  PROVISIONER = "Learn SaaS Provisioner",
  INSTANCES = "Learn Instances",
  CANARY = "Canary Testing",
  UPGRADE = "Upgrade Learn Instance",
  AIRE = "AIRE Service",
  CLONE = "Clone Learn Instance",
  MIGRATION = "Learn Migration Service",
  DB_MIGRATION = "Learn Db Migration Service",
  NFS_MIGRATION = "Learn Nfs Migration Service",
  DNS = "Learn DNS Service",
  CONTROLLER = "Learn Core Controller",
  CUSTOMER_DATA = "Migrate Customer Data",
  DECOMMISSION = "Decommission Learn Service",
  OTL = "Learn Core OTL",
  RESTART = "Rolling Restart service",
  CUSTOM_DOMAINS = "Learn Custom Domains",
  RELEASE_STAGE = "Release Stage Service",
  TENANT_STATE = "Tenant State Service",
  SLA_REPORT = "Generate SLA Report",
  ULTRA_UI = "Learn Core Ultra UI",
}
