// Accessibility
export const offScreenClassName = 'off-screen';

export const offScreenContent = {
  position: 'absolute' as 'absolute', // casting as 'absolute' required to avoid consumer error
  width: '1px',
  height: '1px',
  margin: '-1px',
  padding: 0,
  border: 0,
  clip: 'rect(0 0 0 0)',
};
