import * as React from 'react';
import { default as MuiFormControl, FormControlProps } from '@material-ui/core/FormControl';

export const FormControl = React.forwardRef(
  (props: FormControlProps, ref: React.Ref<HTMLDivElement>) => (
    <MuiFormControl ref={ref} {...props} />
  ),
);

export default FormControl;
