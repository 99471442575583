import { alpha } from '@material-ui/core/styles/colorManipulator';
import { Theme } from '@material-ui/core/styles/createTheme';
import { Overrides } from '@material-ui/core/styles/overrides';

export const RadioOverrides = (theme: Theme): Overrides => {
  const focusOffset = '6px !important';

  return {
    MuiRadio: {
      // We need to use this key as opposed to root to avoid specificity issues.
      colorSecondary: {
        // Focus ring appearance.

        '&::before': {
          bottom: focusOffset,
          left: focusOffset,
          right: focusOffset,
          top: focusOffset,
        },

        // Override MUI icon button min dimensions.
        minWidth: '32px',
        minHeight: '32px',

        // Set hover halo.
        width: '32px',
        height: '32px',
        padding: 0,
        borderRadius: '16px',

        '&:hover': {
          backgroundColor: alpha(theme.palette.primary.main, 0.1),
        },

        '&$checked': {
          '&:hover': {
            backgroundColor: alpha(theme.palette.success.main, 0.3),
          },
        },
      },
      root: {
        // Some MUI styles are being overridden when bb-ui is used with other monorepos
        // (e.g. bb-foundation-extensions). We want to conserve this values for consistency.
        '& input[type="radio"]': {
          width: '100%',
          height: '100%',
        },
      },
    },
  };
};
