import ar_sa from './locales/ar-sa.json';
import cs_cz from './locales/cs-cz.json';
import cy_gb from './locales/cy-gb.json';
import da_dk from './locales/da-dk.json';
import de_de from './locales/de-de.json';
import en_au from './locales/en-au.json';
import en_gb from './locales/en-gb.json';
import en_us_k12 from './locales/en-us-k12.json';
import en_us_pro from './locales/en-us-pro.json';
import en_us from './locales/en-us.json';
import es_es from './locales/es-es.json';
import fr_ca from './locales/fr-ca.json';
import fr_fr from './locales/fr-fr.json';
import he_il from './locales/he-il.json';
import it_it from './locales/it-it.json';
import ja_jp from './locales/ja-jp.json';
import ko_kr from './locales/ko-kr.json';
import ms_my from './locales/ms-my.json';
import nb_no from './locales/nb-no.json';
import nl_nl from './locales/nl-nl.json';
import nn_no from './locales/nn-no.json';
import pl_pl from './locales/pl-pl.json';
import pt_br from './locales/pt-br.json';
import pt_pt from './locales/pt-pt.json';
import ru_ru from './locales/ru-ru.json';
import sv_se from './locales/sv-se.json';
import th_th from './locales/th-th.json';
import tr_tr from './locales/tr-tr.json';
import zh_cn from './locales/zh-cn.json';
import zh_tw from './locales/zh-tw.json';

export interface ILocaleBundle {
  off: string;
  on: string;
  no: string;
  yes: string;
  learnMore: string;
  internalUrl: string;
  new: string;
  locked: string;
  unlocked: string;
  overridden: string;
  flagKey: string;
  clientDescription: string;
  internalDescription: string;
  scopeTypes: string;
  products: string;
  externalDocUrl: string;
  internalDocUrl: string;
  seeMore: string;
  seeLess: string;
  save: string;
  cancel: string;
  edit: string;
  none: string;
  canEdit: string;
  globalStatus: string;
  globalPermissions: string;
  releaseVersion: string;
  flagDefinitionInfoScopeDescription: string;
  featureFlagDefEditDialogueTitle: string;
  featureFlagDefEditDialogueAriaLabel: string;
  featureFlagDefEditDialogueTitleAriaLabel: string;
  featureFlagDefEditDialogueDefaultValueWarning: string;
  flagDefinitionEditDialogueOverrideLabel: string;
  flagDefinitionGlobalStatusDescription: string;
  flagDefinitionClientPermissionsDescription: string;
  flagDefinitionReleaseVersionDescription: string;
  flagDefinitionEditDialogueClientPermNote: string;
  flagDefinitionEditDialogueReleaseVersionNote: string;
  flagDefinitionEditDialogueProductType: string;
  flagDefinitionEditDialogueProductTypeNone: string;
  flagDefinitionEditDialogueProductTypeLearn: string;
  flagDefinitionEditDialogueProductTypeStudent: string;
  flagDefinitionEditDialogueProductTypePortal: string;
  flagDefinitionEditDialogueProductVersion: string;
  flagDefinitionEditDialogueProductVersionInvalid: string;
  flagDefinitionEditDialogueProductVersionRequired: string;
  flagDefinitionDefaultValueAriaLabel: string;
  flagDefinitionOverrideAriaLabel: string;
  flagDefinitionClientPermissionsAriaLabel: string;
}

export interface ILocaleBundles {
  [key: string]: any;
}

/* eslint-disable max-len */
const bundles = { ar_sa, cs_cz, cy_gb, da_dk, de_de, en_au, en_gb, en_us_k12, en_us_pro, en_us, es_es, fr_ca, fr_fr, he_il, it_it, ja_jp, ko_kr, ms_my, nb_no, nl_nl, nn_no, pl_pl, pt_br, pt_pt, ru_ru, sv_se, th_th, tr_tr, zh_cn, zh_tw } as ILocaleBundles;

export default bundles;
