import * as React from 'react';
import { createSvgIcon, IconDirectionality } from '..';

export const Check = createSvgIcon(
  {
    [IconDirectionality.All]: (
      <>
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M1 8l4.6667 7L15 1"
        />
      </>
    ),
  },
  'small',
);
